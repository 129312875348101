import { useEffect, useMemo, useRef, useState } from "react"
import { getCallRails, getCallSummary, getDailyClicks, getDashboardData, getEmailCampaigns, getReviewGrowth, getReviewRatingDashboard, getSentiments } from "../../services/newdashboard";
import { useAppSelector } from "../../store/hook";
import moment from "moment";
import { formatCalcNumber, formatter, numFormatter } from "../../helpers/currency";
import { getAdsData, getAnalyticsData } from "../../services/google";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from 'react-to-print';
import { Margin, Resolution, usePDF } from 'react-to-pdf';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  Filler
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useNavigate, useSearchParams } from "react-router-dom";
import { groupBy, sumBy } from "lodash";
import { fetchUserData, fetchUserProfile } from "../../services/user";
import { useDispatch } from "react-redux";
import { addUserData, addUserProfile } from "../../store/user/userSlice";
import ReactDatePicker from "react-datepicker";
import configs from "../../configs/apiConfigs";
import html2PDF from 'jspdf-html2canvas';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  Filler
);
export const NewDashboard = () => {
  const [searchParams] = useSearchParams();
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [dashboardData, setDashboardData] = useState<any>({});
  const [selectedClinic, setSelectedClinic] = useState(searchParams.get('clinic') ? searchParams.get('clinic') : sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic') !== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const clinicData = clinics?.find((item: any) => item.uniqueId === selectedClinic);
  const month = useMemo(() => {
    let retLabel = 0;
    switch (searchParams.get('filter')) {
      case 'thismonths':
        retLabel = 0;
        break;
      case '1months':
        retLabel = 1;
        break;
      case '3months':
        retLabel = 3;
        break;
      case '6months':
        retLabel = 6;
        break;
      case '9months':
        retLabel = 9;
        break;
      case '12months':
        retLabel = 12;
        break;
    }
    return retLabel;
  }, [searchParams]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: month ? moment().subtract(month, 'months').startOf("month").format("YYYY-MM-DD") : moment().startOf("month").format("YYYY-MM-DD"),
    endDate: month ? moment().subtract(1, 'month').endOf("month").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
  });
  const [selectedQuater, setSelectedQuater] = useState(searchParams.get('filter') ? (searchParams.get('filter') ?? 'thismonths') : 'thismonths');
  const [sessionsChart, setSessionsChart] = useState({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false //this will remove only the label
          },
          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        }
      }
    },
    data: {} as any
  });
  const [usersChart, setUsersChart] = useState({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false //this will remove only the label
          },
          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        }
      }
    },
    data: {} as any
  });
  const [conversionsChart, setConversionsChart] = useState({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false //this will remove only the label
          },
          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        }
      }
    },
    data: {} as any
  });
  const [reviewGrowthChart, setReviewGrowthChart] = useState({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false //this will remove only the label
          },
          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        }
      }
    },
    data: {} as any
  });
  const [businessProfileChart, setBuinessProfileChart] = useState<any>({});
  const [businessProfileChartSummary, setBuinessProfileChartSummary] = useState<any>({
    calls: 0,
    clicks: 0,
    directions: 0
  });
  const [totalConversion, setTotalConversions] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [purchaseRevenue, setPurchaseRevenue] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [reviewRatingData, setReviewRatingData] = useState<any>({});
  const [sentimentsData, setSentimentsData] = useState<any>();
  const [emailCampaignData, setEmailCampaignData] = useState<any>();
  const [campaignClicksLineData, setCampaignClicksLineData] = useState<any>({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false //this will remove only the label
          },
          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        }
      }
    },
    data: {} as any
  });
  const [campaignClicksBarData, setCampaignClicksBarData] = useState<any>({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false //this will remove only the label
          },
          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        }
      }
    },
    data: {
      labels: [],
      datasets: []
    } as any
  });
  const [googleAdsTileData, setGoogleAdsTileData] = useState<any>({});
  const [callRailChart, setCallRailChart] = useState<any>({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
        title: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false //this will remove only the label
          },
          // to remove the x-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          // to remove the y-axis grid
          grid: {
            drawBorder: false,
            display: false,
          },
        }
      }
    },
    data: {} as any
  });
  const [callRailsCount, setCallsCount] = useState(0);
  const [callSummaryData, setCallSummaryData] = useState({} as any);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toPDF, targetRef } = usePDF({ filename: `report_${moment().format("DD-MM-YYYY")}.pdf` });
  const divRefs = useRef<any[]>([]);

  const handleAddRef = (ref: any) => {
    if (ref && !divRefs.current.includes(ref)) {
      divRefs.current.push(ref);
    }
  }

  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }
  useEffect(() => {
    if (selectedClinic !== undefined) {
      getData();
      _getAnalyticsData();
      _getAdsData();
    }
  }, [selectedClinic, dateFilter, clinicData]);

  const getData = () => {
    const isMonthly = !(selectedQuater === 'thismonths' || selectedQuater === '1months' || selectedQuater == '3months');
    getDashboardData(selectedClinic, {
      "startDate": dateFilter?.startDate,
      "endDate": dateFilter?.endDate
    }).then((success) => {
      setDashboardData(success.data)
    }).catch((error) => {
      console.log(error);
    });
    getReviewRatingDashboard(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`).then((success) => {
      setReviewRatingData(success);
    }).catch((error) => {
      console.log(error);
    });
    getReviewGrowth(selectedClinic, `?startDate=${moment().subtract(12, 'months').startOf("month").format("YYYY-MM-DD")}&endDate=${moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")}&type=${isMonthly ? 'monthly' : ''}`).then((success) => {
      const labels: any[] = [];
      const datasets: any[] = [];
      delete success?.status;
      Object.keys(success).map((key) => {
        labels.push(`${success[key].month}/${success[key].year}`);
        datasets.push(success[key].total);
      })
      setReviewGrowthChart({
        options: reviewGrowthChart?.options,
        data: {
          labels: labels,
          datasets: [{
            label: '',
            data: datasets,
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
            backgroundColor: 'rgb(75, 192, 192)',
          }]
        }
      })
      // setReviewRatingData(success);
    }).catch((error) => {
      console.log(error);
    });
    getDailyClicks(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&type=${isMonthly ? 'monthly' : ''}`).then((success) => {
      const WEBSITE_CLICKS: any[] = [];
      const BUSINESS_DIRECTION_REQUESTS: any[] = [];
      const CALL_CLICKS: any[] = [];
      const xAxisValues: any = [];
      let calls = 0;
      let clicks = 0;
      let directions = 0;
      success?.dailyMetrics?.forEach((item: any) => {
        xAxisValues.push(`${item.year}-${item.month}${!isMonthly ? `-${item.day}` : ''}`);
        clicks += Number(item.WEBSITE_CLICKS); 
        WEBSITE_CLICKS.push({
          value: Number(item.WEBSITE_CLICKS),
          date: `${item.year}-${item.month}${!isMonthly ? `-${item.day}` : ''}`
        });
        directions += Number(item.BUSINESS_DIRECTION_REQUESTS);
        BUSINESS_DIRECTION_REQUESTS.push({
          value: Number(item.BUSINESS_DIRECTION_REQUESTS),
          date: `${item.year}-${item.month}${!isMonthly ? `-${item.day}` : ''}`
        });
        calls += Number(item.CALL_CLICKS);
        CALL_CLICKS.push({
          value: Number(item.CALL_CLICKS),
          date: `${item.year}-${item.month}${!isMonthly ? `-${item.day}` : ''}`
        });
      });
      setBuinessProfileChartSummary({
        clicks,
        directions,
        calls
      });
      setBuinessProfileChart({
        labels: xAxisValues,
        datasets: [
          {
            fill: true,
            label: 'Clicks',
            data: WEBSITE_CLICKS.map((item) => item.value),
            borderColor: 'rgb(178, 222, 39)',
            backgroundColor: 'rgba(178, 222, 39, 0.5)',
          },
          {
            fill: true,
            label: 'Directions',
            data: BUSINESS_DIRECTION_REQUESTS.map((item) => item.value),
            borderColor: 'rgb(13, 180, 185)',
            backgroundColor: 'rgba(13, 180, 185, 0.5)',
          },
          {
            fill: true,
            label: 'Calls',
            data: CALL_CLICKS.map((item) => item.value),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      })

    }).catch((error) => {
      console.log(error);
    });
    getSentiments(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&type=${isMonthly ? 'monthly' : ''}`).then((success) => {
      setSentimentsData(success);
    }).catch((error) => {
      console.log(error);
    });
    getEmailCampaigns(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&type=${isMonthly ? 'monthly' : ''}`).then((success) => {
      const labels = ['Email', 'Leads', 'Text'];
      const datasets: any = [];
      success?.campaign?.forEach((item: any) => {
        datasets.push({
          labels: item?.title,
          data: [Number(item?.totalEmails), Number(item?.totalLeads), Number(item?.totalText)],
          backgroundColor: ["#b2d5f3", "#ffd9d6", "#eaeeff"]
        })
      });
      setEmailCampaignData({
        labels: labels,
        datasets: datasets
      })
    }).catch((error) => {
      console.log(error);
    });
    getCallRails(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&type=${isMonthly ? 'monthly' : ''}`).then((success) => {
      const labels: any[] = [];
      const datasets: any[] = [];
      delete success?.status;
      const mapping: any = {
        'gmb': 'GMB',
        'google-ads': 'Google Ads',
        'seo': 'SEO'
      };
      success?.data.map((item: any) => {
        labels.push(item?.source ?? 'Xcare');
        datasets.push(item?.count);
      });
      const backgroundColor = ["#8dc44f", "#1ca45c", "#ffc718", "#ff9e0f", "#da483b", "#4486f4"];
      setCallRailChart({
        options: callRailChart?.options,
        data: {
          labels: [''],
          datasets: labels?.map((item, index) => {
            return {
                label: mapping?.[item] ?? item,
                data: [datasets[index]],
                backgroundColor: backgroundColor[index],
            }
          })
        }
      })
    }).catch((error) => {
      console.log(error);
    })
    getCallRails(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&type=count`).then((success) => {
      setCallsCount(success?.data?.count ?? 0);
    }).catch((error) => { });
    getCallSummary(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`).then((success) => {
      setCallSummaryData(success.data);
    }).catch((error) => { 
    });
  }
  const _getAnalyticsData = () => {
    const isMonthly = !(selectedQuater === 'thismonths' || selectedQuater === '1months' || selectedQuater == '3months');
    getAnalyticsData(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&type=${isMonthly ? 'monthly' : ''}`).then((success) => {
      if (success?.status?.error === false) {
        const labels: any[] = [];
        const datasets: any[] = [];
        success?.allSessions?.forEach((item: any) => {
          if (isMonthly) {
            labels.push(moment(`${item.month}-${item.year}`, 'MM-YYYY').format('MMM, YYYY'));
          } else {
            labels.push(item?.date);
          }
          datasets.push(item?.sessions);
        });
        setSessionsChart({
          options: sessionsChart?.options,
          data: {
            labels: labels,
            datasets: [{
              data: datasets,
              fill: true,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }]
          }
        })

        /* User labels & datasets */
        const userLabels: any[] = [];
        const userDatasets: any[] = [];
        success?.allActiveUsers?.forEach((item: any) => {
          if (isMonthly) {
            userLabels.push(moment(`${item.month}-${item.year}`, 'MM-YYYY').format('MMM, YYYY'));
          } else {
            userLabels.push(item?.date);
          }
          userDatasets.push(item?.activeUsers);
        });

        setUsersChart({
          options: usersChart?.options,
          data: {
            labels: userLabels,
            datasets: [{
              label: '',
              data: userDatasets,
              fill: true,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1,
              backgroundColor: 'rgb(75, 192, 192, 0.2)',
            }]
          }
        })

        /* Conversions labels & datasets */
        const conversionsLabels: any[] = [];
        const conversionsDatasets: any[] = [];
        success?.allConversions?.forEach((item: any) => {
          if (isMonthly) {
            conversionsLabels.push(moment(`${item.month}-${item.year}`, 'MM-YYYY').format('MMM, YYYY'));
          } else {
            conversionsLabels.push(item?.date);
          }
          conversionsDatasets.push(item?.conversions);
        });

        setConversionsChart({
          options: conversionsChart?.options,
          data: {
            labels: conversionsLabels,
            datasets: [{
              label: '',
              data: conversionsDatasets,
              fill: true,
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: 'rgb(75, 192, 192, 0.2)',
              tension: 0.1
            }]
          }
        })

        setTotalSessions(success?.totalCount?.sessions);
        setTotalConversions(success?.totalCount?.conversions);
        setTotalViews(success?.totalCount?.newUsers);
        setPurchaseRevenue(success?.totalCount?.bounceRate);
        setTotalEvents(success?.totalCount?.eventCount);
        setTotalUsers(success?.totalCount?.totalUsers);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const _getAdsData = () => {
    const isMonthly = !(selectedQuater === 'thismonths' || selectedQuater === '1months' || selectedQuater == '3months');
    getAdsData(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}&type=${isMonthly ? 'monthly' : ''}`).then((success) => {
      if (success?.status?.error === false) {
        const labels: any = [];
        const datasets: any = [];
        success?.allclicks?.forEach((item: any) => {
          if (isMonthly) {
            labels.push(moment(`${item.month}-${item.year}`, 'MM-YYYY').format('MMM, YYYY'));
          } else {
            labels.push(item?.date);
          }
          datasets.push(item?.clicks);
        });
        setCampaignClicksLineData({
          options: campaignClicksLineData?.options,
          data: {
            labels: labels,
            datasets: [{
              label: '',
              data: datasets,
              fill: true,
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: 'rgb(75, 192, 192, 0.2)',
              tension: 0.1
            }]
          }
        });


        const barLabels: any = [];
        const barDatasets: any = [{
          label: [],
          data: [],
          backgroundColor: ["#8dc44f", "#1ca45c", "#ffc718", "#ff9e0f", "#da483b", "#4486f4"],
        }];
        const groupedData = groupBy(success?.allCampaign, 'name');
        Object.keys(groupedData).map((item) => {
          barLabels.push(item);
          groupedData[item] = groupedData[item].map((item) => {
            item.clicks = Number(item.clicks);
            return item;
          });
          barDatasets[0].data.push(sumBy(groupedData[item], 'clicks'));
        });

        setCampaignClicksBarData({
          ...campaignClicksBarData,
          data: {
            labels: barLabels,
            datasets: barDatasets,
          }
        });

        setGoogleAdsTileData({
          clicks: success?.totalCount?.clicks ?? 0,
          conversions: success?.totalCount?.conversions ?? 0,
          ctr: success?.totalCount?.ctr ?? 0,
          costMicros: success?.totalCount?.cost ?? 0,
          averageCpc: success?.totalCount?.averageCpc ?? 0,
          impressions: success?.totalCount?.impressions ?? 0
        })
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const filterLabel = useMemo(() => {
    let retLabel = '';
    switch (selectedQuater) {
      case 'thismonths':
        retLabel = 'This month';
        break;
      case '1months':
        retLabel = 'Last month';
        break;
      case '3months':
        retLabel = 'Last 3 months';
        break;
      case '6months':
        retLabel = 'Last 6 months';
        break;
      case '9months':
        retLabel = 'Last 9 months';
        break;
      case '12months':
        retLabel = 'Last 12 months';
        break;
    }
    return retLabel;
  }, [selectedQuater]);
  const totalMarketingSpent = Number(dashboardData?.marketingSpend ?? 0);
  const totalLeads = ((!isNaN(Number(dashboardData?.convertedLeads)) ? Number(dashboardData?.convertedLeads) : 0));
  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  const revenuePercentCondition = (Number(dashboardData?.totalRevenueGoals ?? 0) < (Number(dashboardData?.totalProjectionRevenue) + Number(dashboardData?.totalActualRevenue)));
  const marketingSpentPercentCondition = (Number(totalMarketingSpent) > Number(dashboardData?.totalBudget));
  const totalProfitPercentCondition = ((Number(dashboardData?.totalRevenueGoals) - Number(dashboardData?.totalBudget)) < (Number(dashboardData?.totalProjectionRevenue + dashboardData?.totalActualRevenue) - Number(totalMarketingSpent)));
  const roiPercentCondition = ((Number(dashboardData?.totalRevenueGoals) / Number(dashboardData?.totalBudget)) * 100) < ((Number(dashboardData?.totalProjectionRevenue + dashboardData?.totalActualRevenue) / Number(totalMarketingSpent)) * 100);
  const totalLeadsPercentCondition = dashboardData?.totalLeadGoals < totalLeads;
  const totalAcquisitionsPercentCondition = dashboardData?.totalAcquisitionsGoals < dashboardData?.convertedAcquisitions;
  const costPerLeadPercentCondition = (dashboardData?.totalBudget / dashboardData?.totalLeadGoals) < formatCalcNumber(Number(totalMarketingSpent), Number(totalLeads));
  const costPerAcquisitionsPercentCondition = (dashboardData?.totalBudget / dashboardData?.totalAcquisitionsGoals) < formatCalcNumber(totalMarketingSpent, dashboardData?.convertedAcquisitions);

  // const componentRef: any = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: 'Visitor Pass',
  //   onAfterPrint: () => console.log('Printed PDF successfully!'),
  // });

  const topContent = (
    <section className="matrix px-30 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Revenue</div>
                <div className="cardtop_number">
                  <div className="row">
                    <div className="col d-flex flex-column">
                      <div className="title">Actual</div>
                      <div className="value">{Number(dashboardData?.totalActualRevenue) !== 0 && !isNaN(dashboardData?.totalActualRevenue) ? formatter.format(dashboardData?.totalActualRevenue) : '-'}</div>
                    </div>
                    <div className="col d-flex flex-column">
                      <div className="title">Projected</div>
                      <div className="value">{Number(dashboardData?.totalProjectionRevenue) !== 0 && !isNaN(dashboardData?.totalProjectionRevenue) ? formatter.format(dashboardData?.totalProjectionRevenue) : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cardbottom">
                <div className="card_left">
                  <div className="card_left-title"> Goal </div>
                  <div className="card_left-number"> {Number(dashboardData?.totalRevenueGoals) !== 0 && !isNaN(dashboardData?.totalRevenueGoals) ? formatter.format(dashboardData?.totalRevenueGoals) : '-'}</div>
                </div>
                <div className="card_right">
                  <div className="card_right-title"> +/- </div>
                  <div className={"card_right-number " + (revenuePercentCondition ? 'text-success' : 'text-danger')}> {Number(dashboardData?.totalProjectionRevenue) !== 0 && Number(dashboardData?.totalRevenueGoals) !== 0 && !isNaN(dashboardData?.totalProjectionRevenue) && !isNaN(dashboardData?.totalRevenueGoals) ? `${revenuePercentCondition ? '+' : ''}${formatter.format((dashboardData?.totalProjectionRevenue + dashboardData?.totalActualRevenue) - dashboardData?.totalRevenueGoals)}` : '-'} </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Total Marketing spend</div>
                <div className="cardtop_number">{Number(totalMarketingSpent) !== 0 && !isNaN(totalMarketingSpent) ? formatter.format(totalMarketingSpent) : '-'}</div>
              </div>

              <div className="cardbottom">
                <div className="card_left">
                  <div className="card_left-title"> Budget </div>
                  <div className="card_left-number"> {Number(dashboardData?.totalBudget) !== 0 && !isNaN(dashboardData?.totalBudget) ? formatter.format(dashboardData?.totalBudget) : '-'} </div>
                </div>
                <div className="card_right">
                  <div className="card_right-title"> +/- </div>
                  <div className={"card_right-number " + (marketingSpentPercentCondition ? 'text-danger' : 'text-success')}>
                    {
                      Number(totalMarketingSpent) !== 0 && Number(dashboardData?.totalBudget) !== 0 &&
                        !isNaN(totalMarketingSpent) && !isNaN(dashboardData?.totalBudget) ?
                        `${marketingSpentPercentCondition ? '-' : '+'}${formatter.format(Number(Number(totalMarketingSpent) - Number(dashboardData?.totalBudget)))}` : '-'
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Total profit</div>
                <div className="cardtop_number">{ Number(dashboardData?.totalActualRevenue) !== 0 && Number(dashboardData?.totalProjectionRevenue) !== 0 && Number(totalMarketingSpent) && !isNaN(dashboardData?.totalProjectionRevenue) &&
                  !isNaN(totalMarketingSpent) ? formatter.format((dashboardData?.totalActualRevenue + dashboardData?.totalProjectionRevenue) - Number(totalMarketingSpent)) : '-'}</div>
              </div>

              <div className="cardbottom">
                <div className="card_left">
                  <div className="card_left-title"> Goal </div>
                  <div className="card_left-number"> {Number(dashboardData?.totalRevenueGoals) !== 0 && Number(dashboardData?.totalBudget) !== 0 &&
                    !isNaN(dashboardData?.totalBudget) && !isNaN(dashboardData?.totalRevenueGoals) ? formatter.format(dashboardData?.totalRevenueGoals - dashboardData?.totalBudget) : '-'} </div>
                </div>
                <div className="card_right">
                  <div className="card_right-title"> +/- </div>
                  <div className={"card_right-number " + (totalProfitPercentCondition ? 'text-success' : 'text-danger')}>
                    {
                      Number(dashboardData?.totalProjectionRevenue) !== 0 && Number(dashboardData?.totalRevenueGoals) !== 0 &&
                        !isNaN(dashboardData?.totalProjectionRevenue) && !isNaN(totalMarketingSpent) &&
                        !isNaN(dashboardData?.totalRevenueGoals) ?
                        `${totalProfitPercentCondition ? '+' : ''}${formatter.format(Number((dashboardData?.totalProjectionRevenue + dashboardData?.totalActualRevenue) - totalMarketingSpent) - Number(dashboardData?.totalRevenueGoals - dashboardData?.totalBudget))}` : '-'
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Roi</div>
                <div className="cardtop_number">{Number(dashboardData?.totalProjectionRevenue) !== 0 && Number(totalMarketingSpent) ? `${(((Number(dashboardData?.totalProjectionRevenue) + Number(dashboardData?.totalActualRevenue)) / Number(totalMarketingSpent)) * 100).toFixed(2)}%` : '-'}</div>
              </div>

              <div className="cardbottom">
                    <div className="card_left">
                    <div className="card_left-title"> Goal </div>
                    <div className="card_left-number"> {!isNaN(((Number(dashboardData?.totalRevenueGoals) / Number(dashboardData?.totalBudget)) * 100)) ? `${((Number(dashboardData?.totalRevenueGoals) / Number(dashboardData?.totalBudget)) * 100).toFixed(2)}%` : '-'}</div>
                    </div>
                    <div className="card_right">
                    <div className="card_right-title"> +/- </div>
                    <div className={"card_right-number " + (roiPercentCondition ? 'text-success' : 'text-danger')}> {
                        Number(dashboardData?.totalRevenueGoals) !== 0 && Number(dashboardData?.totalBudget) !== 0 &&
                      Number(dashboardData?.totalProjectionRevenue) !== 0 && Number(totalMarketingSpent) !== 0 &&
                        !isNaN(dashboardData?.totalRevenueGoals) &&
                      !isNaN(dashboardData?.totalBudget) &&
                      !isNaN(dashboardData?.totalProjectionRevenue) && !isNaN(totalMarketingSpent) ?
                        `${roiPercentCondition ? '+' : '-'}${((((Number(dashboardData?.totalRevenueGoals) / Number(dashboardData?.totalBudget))) -
                        ((Number(dashboardData?.totalProjectionRevenue + dashboardData?.totalActualRevenue) /
                          Number(totalMarketingSpent)))) * 100).toFixed(2)}%` : '-'
                    }</div>
                    </div>
                </div>

            </div>
          </div>

          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Leads</div>
                <div className="cardtop_number">{!isNaN(totalLeads) ? numFormatter.format(totalLeads) : '-'}</div>
              </div>

              <div className="cardbottom">
                <div className="card_left">
                  <div className="card_left-title"> Goal </div>
                  <div className="card_left-number"> {!isNaN(dashboardData?.totalLeadGoals) ? dashboardData?.totalLeadGoals : '-'} </div>
                </div>
                <div className="card_right">
                  <div className="card_right-title"> +/- </div>
                  <div className={"card_right-number " + (totalLeadsPercentCondition ? 'text-success' : 'text-danger')}>
                    {
                      isFinite(dashboardData?.totalLeadGoals) && isFinite(totalLeads) ? `${totalLeadsPercentCondition ? '+' : '-'}${(totalLeads - dashboardData?.totalLeadGoals)}` : '-'
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Acquisitions</div>
                <div className="cardtop_number">{!isNaN(dashboardData?.convertedAcquisitions) ? numFormatter.format(dashboardData?.convertedAcquisitions) : '-'}</div>
              </div>

              <div className="cardbottom">
                <div className="card_left">
                  <div className="card_left-title"> Goal </div>
                  <div className="card_left-number"> {!isNaN(dashboardData?.totalAcquisitionsGoals) ? dashboardData?.totalAcquisitionsGoals : '-'} </div>
                </div>
                <div className="card_right">
                  <div className="card_right-title"> +/- </div>
                  <div className={"card_right-number " + (totalAcquisitionsPercentCondition ? 'text-success' : 'text-danger')}>
                    {
                      isFinite(dashboardData?.convertedAcquisitions) && isFinite(dashboardData?.totalAcquisitionsGoals) ?
                        `${totalAcquisitionsPercentCondition ? '+' : ''}${((dashboardData?.convertedAcquisitions - dashboardData?.totalAcquisitionsGoals))}` : '-'
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Cost per lead</div>
                <div className="cardtop_number">{formatter.format(formatCalcNumber(Number(totalMarketingSpent), Number(totalLeads)))}</div>
              </div>

              <div className="cardbottom">
                <div className="card_left">
                  <div className="card_left-title"> Goal </div>
                  <div className="card_left-number"> {!isNaN(dashboardData?.totalBudget / dashboardData?.totalLeadGoals) ? formatter.format(dashboardData?.totalBudget / dashboardData?.totalLeadGoals) : '-'} </div>
                </div>
                <div className="card_right">
                  <div className="card_right-title"> +/- </div>
                  <div className={"card_right-number " + (costPerLeadPercentCondition ? 'text-danger' : 'text-success')}>
                    {
                      `${costPerLeadPercentCondition ? '' : '+'}${formatter.format(
                        formatCalcNumber(Number(dashboardData?.totalBudget), Number(dashboardData?.totalLeadGoals)) -
                        formatCalcNumber(Number(totalMarketingSpent), Number(totalLeads))
                      )
                      }`
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-3">
            <div className="card result-card">

              <div className="cardtop">
                <div className="cardtop_title">Cost per Acquisitions</div>
                <div className="cardtop_number">{formatter.format(formatCalcNumber(totalMarketingSpent, dashboardData?.convertedAcquisitions))}</div>
              </div>

              <div className="cardbottom">
                <div className="card_left">
                  <div className="card_left-title"> Goal </div>
                  <div className="card_left-number"> {!isNaN(dashboardData?.totalBudget / dashboardData?.totalAcquisitionsGoals) ? formatter.format(dashboardData?.totalBudget / dashboardData?.totalAcquisitionsGoals) : '-'} </div>
                </div>
                <div className="card_right">
                  <div className="card_right-title"> +/- </div>
                  <div className={"card_right-number " + (costPerAcquisitionsPercentCondition ? 'text-danger' : 'text-success')}>
                    {
                      `${costPerAcquisitionsPercentCondition ? '' : '+'}${formatter.format(
                        formatCalcNumber(Number(dashboardData?.totalBudget), Number(dashboardData?.totalAcquisitionsGoals)) -
                        formatCalcNumber(Number(totalMarketingSpent), Number(dashboardData?.convertedAcquisitions))
                      )
                      }`
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const googleCharts = (
    <div className="row">
      <div className="col-6 mb-5">
        <div className="card google-analytics-graph">

          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="m-0">Google Analytics</h6>
            <div>
              <button onClick={() => {
                navigate('/ads?clinic=' + selectedClinic + '&filter=' + selectedQuater);
              }} type="button" className="btn btn-link pe-0">View full report</button>
            </div>
          </div>

          <div className="card-body">

            <div className="row mb-3">
              <div className="col-12 mb-3">
                <div className="graph-header d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                  <div>Session</div>
                  <div className="opacity-50">{filterLabel}</div>
                </div>
                <div className="graph-layout">
                  {
                    sessionsChart?.data?.datasets?.length > 0 &&
                    <Line width={'800px'} height={'200px'} options={sessionsChart?.options} data={sessionsChart?.data} />
                  }
                </div>
              </div>
              <div className="col-6">
                <div className="graph-header d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                  <div>Users</div>
                  {/* <div className="opacity-50">{filterLabel}</div> */}
                </div>
                <div className="graph-layout">
                  {
                    usersChart?.data?.datasets?.length > 0 &&
                    <Line options={usersChart?.options} data={usersChart?.data} />
                  }
                </div>
              </div>
              <div className="col-6">
                <div className="graph-header d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                  <div>Goals</div>
                  {/* <div className="opacity-50">{filterLabel}</div> */}
                </div>
                <div className="graph-layout">
                  {
                    conversionsChart?.data?.datasets?.length > 0 &&
                    <Line options={conversionsChart?.options} data={conversionsChart?.data} />
                  }
                </div>
              </div>
            </div>

            <div className="graph-matrix-grid">
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Sessions</div>
                  {/* <div className="graph-matrix-grid-res text-danger">29%</div> */}
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(totalSessions) ? numFormatter.format(totalSessions) : '-'}
                </div>
              </div>

              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Users</div>
                  {/* <div className="graph-matrix-grid-res text-danger">29%</div> */}
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(totalUsers) ? numFormatter.format(totalUsers) : '-'}
                </div>
              </div>

              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">New Users</div>
                  {/* <div className="graph-matrix-grid-res text-danger">29%</div> */}
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(totalViews) ? numFormatter.format(totalViews) : ''}
                </div>
              </div>
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Bounce Rate</div>
                  {/* <div className="graph-matrix-grid-res text-danger">29%</div> */}
                </div>
                <div className="graph-matrix-grid-number">
                  {/* {!isNaN(Number(purchaseRevenue * 100)) ? `${Number(purchaseRevenue * 100).toFixed(2)}%` : '-'} */}
                  {!isNaN(Number(purchaseRevenue)) ? `${Number(purchaseRevenue).toFixed(2)}%` : '-'}
                </div>
              </div>
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Events</div>
                  {/* <div className="graph-matrix-grid-res text-danger">29%</div> */}
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(totalEvents) ? numFormatter.format(totalEvents) : '-'}
                </div>
              </div>
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Conversions</div>
                  {/* <div className="graph-matrix-grid-res text-danger">29%</div> */}
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(totalConversion) ? numFormatter.format(totalConversion) : '-'}
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

      <div className="col-6 mb-5">
        <div className="card google-analytics-graph">

          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="m-0">Google Ads - Campaigns</h6>
            <div>
              <button type="button" className="btn btn-link pe-0" onClick={() => {
                navigate('/ads?clinic=' + selectedClinic + '&filter=' + selectedQuater);
              }}>View full report</button>
            </div>
          </div>

          <div className="card-body">

            <div className="row mb-3">
              <div className="col-12 mb-3 d-none">
                <div className="graph-header d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                  <div>Session</div>
                  {/* <div className="opacity-50">{filterLabel}</div> */}
                </div>
                <div className="graph-layout">
                  {/* Graph here */}
                </div>
              </div>
              <div className="col-12">
                <div className="graph-header d-flex justify-content-between align-items-center border-bottom pb-2">
                  <div>Clicks</div>
                  <div className="opacity-50">{filterLabel}</div>
                </div>
                <div className="graph-layout">
                  {
                    campaignClicksLineData?.data?.datasets?.length > 0 &&
                    <Line width={'800px'} height='200px' options={campaignClicksLineData?.options} data={campaignClicksLineData?.data} />
                  }
                </div>
              </div>
              <div className="col-12">
                <div className="graph-header d-flex justify-content-between align-items-center border-bottom pb-2 mb-2 mt-4">
                  <div>Clicks</div>
                  {/* <div className="opacity-50">{filterLabel}</div> */}
                </div>
                <div className="graph-layout">
                  <Bar width={'800px'} height='200px' options={campaignClicksBarData?.options} data={campaignClicksBarData?.data} />
                </div>
              </div>
            </div>

            <div className="graph-matrix-grid">
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Clicks</div>
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(googleAdsTileData?.clicks) ? numFormatter.format(googleAdsTileData?.clicks) : '-'}
                </div>
              </div>

              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Impressions</div>
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(Number(googleAdsTileData?.impressions)) ? numFormatter.format(Number(googleAdsTileData?.impressions)) : '-'}
                </div>
              </div>

              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">CTR</div>
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(Number(googleAdsTileData?.ctr)) ? `${Number(googleAdsTileData?.ctr).toFixed(2)}%` : '-'}
                </div>
              </div>
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Cost</div>
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(Number(googleAdsTileData?.costMicros)) ? `$` + Number(googleAdsTileData?.costMicros).toFixed(2) : '-'}
                </div>
              </div>
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Avg CPC</div>
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(Number(googleAdsTileData?.averageCpc)) ? '$' + Number(googleAdsTileData?.averageCpc).toFixed(2) : '-'}
                </div>
              </div>
              <div className="graph-matrix-grid-item">
                <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                  <div className="graph-matrix-grid-lbl">Conversions</div>
                </div>
                <div className="graph-matrix-grid-number">
                  {!isNaN(Number(googleAdsTileData?.conversions)) ? numFormatter.format(Number(googleAdsTileData?.conversions)) : '-'}
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );

  const reviewsCharts = (
    <div className="row">
      <div className="col-6 mb-5 d-flex">
        <div className="card reviews-section">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="m-0">Reviews</h6>
            <div className="d-flex">
              <div className="btn-group d-none" role="group" aria-label="Basic outlined example">
                <button type="button" className="btn btn-sm btn-outline-secondary active">Overviews</button>
                <button type="button" className="btn btn-sm btn-outline-secondary">Reviews</button>
                <button type="button" className="btn btn-sm btn-outline-secondary">Sources</button>
              </div>

              <button type="button" className="btn btn-link pe-0" onClick={() => {
                navigate('/review?clinic=' + selectedClinic + '&filter=' + selectedQuater);
              }}>View full report</button>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-12">
                <div className="graph-header d-flex justify-content-between align-items-center pb-2 mb-2">
                  <div>Review summary</div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="avg-reviewbox">
                      <div className="avg-title text-white">Average Star Rating</div>
                      <div className="avg-rating text-white">{!isNaN(reviewRatingData?.Avgrating) ? Number(reviewRatingData?.Avgrating).toFixed(2) : '-'}</div>
                      <div className="avg-starts d-none">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-half"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="total-reviewbox">
                      <div className="total-title">New / Total Reviews</div>
                      <div className="total-number">{!isNaN(reviewRatingData?.total) ? `${Number(reviewRatingData?.total)}/${Number(reviewRatingData?.all?.total)}` : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8 w-100">
                <div className="graph-header d-flex justify-content-between align-items-center mb-2">
                  <div>Review Growth</div>
                  <div className="d-none">
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                      <button type="button" className="btn btn-sm btn-outline-secondary active">All Reviews</button>
                      <button type="button" className="btn btn-sm btn-outline-secondary">New Reviews</button>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-2 mb-2 d-none">
                  <select className="form-select form-select-sm flex-1" aria-label="Default select example">
                    <option selected>All Time</option>
                    <option value="1">Faceboook</option>
                    <option value="2">Google</option>
                  </select>
                  <select className="form-select form-select-sm flex-1" aria-label="Default select example">
                    <option selected>All Sources</option>
                    <option value="1">Faceboook</option>
                    <option value="2">Google</option>
                  </select>
                  <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button type="button" className="btn btn-sm btn-outline-secondary">Reset</button>
                  </div>
                </div>
                <div className="graph-layout" style={{ height: '200px', width: '100%' }}>
                  {
                    reviewGrowthChart?.data?.datasets?.length > 0 &&
                    <Line width='800px' height={'250px'} options={reviewGrowthChart?.options} data={reviewGrowthChart?.data} />
                  }
                </div>
              </div>
              <div className="col-12 mt-4 d-none">
                <div className="graph-layout" style={{ height: '245px' }}>
                  {/* Graph here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-6 mb-5">
        <div className="card sentiment-section">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="m-0">Customer Sentiment</h6>
            <div>
              <button onClick={() => {
                navigate('/sentiment?clinic=' + selectedClinic + '&filter=' + selectedQuater);
              }} type="button" className="btn btn-link pe-0">View full report</button>
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-6">
                <div className="graph-layout" style={{ height: '180px' }} dangerouslySetInnerHTML={{ __html: sentimentsData?.positive }}>
                  {/* Graph here */}
                </div>
              </div>
              <div className="col-6">
                <div className="graph-layout" style={{ height: '180px' }} dangerouslySetInnerHTML={{ __html: sentimentsData?.negative }}>
                  {/* Graph here */}
                </div>
              </div>
            </div>
            <div className="graph-matrix-grid row d-flex gap-0 justify-content-center">
              <div className="col-md-4 pb-4">
                <div className="graph-matrix-grid-item">
                  <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                    <div className="graph-matrix-grid-lbl"><b>Staff</b></div>
                  </div>
                  <div className="graph-matrix-grid-number">
                    {!isNaN(sentimentsData?.category?.Staff) ? numFormatter.format(sentimentsData?.category?.Staff) : '-'}
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="graph-matrix-grid-item">
                  <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                    <div className="graph-matrix-grid-lbl"><b>Provider</b></div>
                  </div>
                  <div className="graph-matrix-grid-number">
                    {!isNaN(sentimentsData?.category?.Provider) ? numFormatter.format(sentimentsData?.category?.Provider) : '-'}
                  </div>
                </div>
              </div>
              <div className="col-md-4 pb-4">
                <div className="graph-matrix-grid-item">
                  <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                    <div className="graph-matrix-grid-lbl"><b>Service</b></div>
                  </div>
                  <div className="graph-matrix-grid-number">
                    {!isNaN(sentimentsData?.category?.Service) ? numFormatter.format(sentimentsData?.category?.Service) : '-'}
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="graph-matrix-grid-item">
                  <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                    <div className="graph-matrix-grid-lbl"><b>Cost</b></div>
                  </div>
                  <div className="graph-matrix-grid-number">
                    {!isNaN(sentimentsData?.category?.Cost) ? numFormatter.format(sentimentsData?.category?.Cost) : '-'}
                  </div>
                </div>
              </div>

              <div className="col-md-4 pb-4">
                <div className="graph-matrix-grid-item">
                  <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                    <div className="graph-matrix-grid-lbl"><b>General</b></div>
                  </div>
                  <div className="graph-matrix-grid-number">
                    {!isNaN(sentimentsData?.category?.General) ? numFormatter.format(sentimentsData?.category?.General) : '-'}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const callAnalysisCharts = (
      <div className="row">
        <div className="col-md-6 mb-5">
          <div className="card sentiment-section">
            <div className="card-header d-flex justify-content-between align-items-center py-3">
              <h6 className="m-0">Call analysis</h6>
                <button onClick={() => {
                  navigate('/calls?clinic=' + selectedClinic + '&filter=' + selectedQuater);
                }} type="button" className="btn btn-link p-0">View full report</button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="graph-header d-flex justify-content-between align-items-center pb-2 mb-2">
                    <div>Calls</div>
                    <div className="opacity-50">{filterLabel}</div>
                  </div>
                  <div className="graph-layout" style={{ height: '200px' }}>
                    {/* Graph here */}
                    {
                      callRailChart && callRailChart?.data?.labels?.length > 0 &&
                      <Bar width={'480'} height={'250'} options={callRailChart?.options} data={callRailChart?.data} />
                    }
                  </div>
                </div>
              </div>
              <div className="graph-matrix-grid row d-flex gap-0 justify-content-center pt-2">
                <div className="col-md-4 pb-4">
                  <div className="graph-matrix-grid-item">
                    <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                      <div className="graph-matrix-grid-lbl"><b>Leads</b></div>
                    </div>
                    <div className="graph-matrix-grid-number">
                      {!isNaN(callSummaryData?.totalCallLeads) ? numFormatter.format(callSummaryData?.totalCallLeads) : '-'}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pb-4">
                  <div className="graph-matrix-grid-item">
                    <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                      <div className="graph-matrix-grid-lbl"><b>Acquisitions</b></div>
                    </div>
                    <div className="graph-matrix-grid-number">
                      {!isNaN(callSummaryData?.totalCallAcquisitions) ? numFormatter.format(callSummaryData?.totalCallAcquisitions) : '-'}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pb-4">
                  <div className="graph-matrix-grid-item">
                    <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                      <div className="graph-matrix-grid-lbl"><b>Calls</b></div>
                    </div>
                    <div className="graph-matrix-grid-number">
                      {!isNaN(callSummaryData?.total_results?.total_calls) ? numFormatter.format(callSummaryData?.total_results?.total_calls) : '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-5">
          <div className="card sentiment-section">
            <div className="card-header d-flex justify-content-between align-items-center py-3">
              <h6 className="m-0">Google Business Profile Audit</h6>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="graph-header d-flex justify-content-between align-items-center pb-2 mb-2">
                    <div>Customer Action</div>
                    <div className="opacity-50">{filterLabel}</div>
                  </div>
                  <div className="graph-layout" style={{ height: '200px' }}>
                    {/* Graph here */}
                    {
                      businessProfileChart && businessProfileChart?.labels?.length &&
                      <Line width={'480'} height={'250'} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: 'bottom' as const,
                          },
                          title: {
                            display: false,
                            text: '',
                          },
                        },
                        scales: {
                          x: {
                            ticks: {
                              display: false //this will remove only the label
                            },
                            // to remove the x-axis grid
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            // to remove the y-axis grid
                            grid: {
                              display: false,
                            },
                          }
                        }
                      }} data={businessProfileChart} />
                    }
                  </div>
                </div>

              </div>
              <div className="graph-matrix-grid row d-flex gap-0 justify-content-center pt-2">
                  <div className="col-md-4 pb-4">
                    <div className="graph-matrix-grid-item">
                      <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                        <div className="graph-matrix-grid-lbl"><b>Clicks</b></div>
                      </div>
                      <div className="graph-matrix-grid-number">
                        {!isNaN(businessProfileChartSummary?.clicks) ? numFormatter.format(businessProfileChartSummary?.clicks) : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 pb-4">
                    <div className="graph-matrix-grid-item">
                      <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                        <div className="graph-matrix-grid-lbl"><b>Directions</b></div>
                      </div>
                      <div className="graph-matrix-grid-number">
                        {!isNaN(businessProfileChartSummary?.directions) ? numFormatter.format(businessProfileChartSummary?.directions) : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 pb-4">
                    <div className="graph-matrix-grid-item">
                      <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                        <div className="graph-matrix-grid-lbl"><b>Calls</b></div>
                      </div>
                      <div className="graph-matrix-grid-number">
                        {!isNaN(businessProfileChartSummary?.calls) ? numFormatter.format(businessProfileChartSummary?.calls) : '-'}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
  );

  const emailCampaignCharts = (
    <div className="row">
      <div className="col-12 mb-5">
        <div className="card sentiment-section mb-4">
          <div className="card-header d-flex justify-content-between align-items-center py-3">
            <h6 className="m-0">Email Campaigns</h6>
            {/* <div>
            <button type="button" className="btn btn-link pe-0">View full report</button>
          </div> */}
          </div>
          <div className="card-body">
            <div className="row mb-3">
              {
                emailCampaignData?.datasets?.length > 0 && emailCampaignData?.datasets?.map((item: any) => {
                  return (
                    <div className="col-4 mb-3 text-center">
                      <label className="ellipsis-max-width">{item.labels}</label>
                      <div className="graph-layout" style={{ height: '300px' }}>
                        <Pie options={{
                          plugins: {
                            legend: {
                              labels: {
                                generateLabels: (chart: any) => {
                                  const datasets = chart.data.datasets;
                                  return datasets[0].data.map((data: any, i: number) => ({
                                    text: `${chart.data.labels[i]} - ${data}`,
                                    fillStyle: datasets[0].backgroundColor[i],
                                    index: i
                                  }))
                                }
                              }
                            }
                          }
                        }} data={{
                          labels: emailCampaignData?.labels,
                          datasets: [item]
                        }} />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );

  const mainContent = (
    <>
        <div ref={handleAddRef}>
          {topContent}
          <section className="graphs px-30 pt-3">
            <div className="container">
              {googleCharts}
            </div>
          </section>
        </div>
        <section className="graphs px-30 pt-3" ref={handleAddRef}>
          <div className="container">
            {reviewsCharts}
            {callAnalysisCharts}
          </div>
        </section>
        <section className="graphs px-30 pt-3" ref={handleAddRef}>
          <div className="container">
            {emailCampaignCharts}
          </div>
        </section>
    </>
  )

  return (
    <>
      <section className="middle-navbar py-4 px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt m-0">Dashboard</div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="d-flex gap-3 align-items-center">
                <div className="dashb-filters">
                  <ReactDatePicker
                    className="form-control col-2"
                    selected={moment(dateFilter?.startDate).toDate()}
                    onChange={(date: any) => {
                      setDateFilter({
                        startDate: moment(date).startOf("month").format("YYYY-MM-DD"),
                        endDate: moment(date).endOf("month").format("YYYY-MM-DD")
                      });
                    }}
                    showMonthYearPicker
                    dateFormat="MMM/yyyy"
                  />
                  <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    setSelectedQuater(e.target.value);
                    switch (e.target.value) {
                      case 'thismonths':
                        setDateFilter({
                          startDate: moment().startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().format("YYYY-MM-DD")
                        });
                        break;
                      case '1months':
                        setDateFilter({
                          startDate: moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '3months':
                        setDateFilter({
                          startDate: moment().subtract(3, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '6months':
                        setDateFilter({
                          startDate: moment().subtract(6, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '9months':
                        setDateFilter({
                          startDate: moment().subtract(9, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '12months':
                        setDateFilter({
                          startDate: moment().subtract(12, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                    }
                  }} value={selectedQuater}>
                    <option value="thismonths">This Month</option>
                    <option value="1months">Last Month</option>
                    <option value="3months">Last 3 Months</option>
                    <option value="6months">Last 6 Months</option>
                    <option value="9months">Last 9 Months</option>
                    <option value="12months">Last 12 Months</option>
                  </select>
                </div>

                <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                  setSelectedClinic(e.target.value);
                  sessionStorage.setItem('selectedClinic', e.target.value);
                }}>
                  {
                    clinics?.map((item: any) => {
                      return (
                        <option value={item.uniqueId} key={item.uniqueId}>{item.name}</option>
                      )
                    })
                  }
                </select>

                {
                  user?.role !== "Read Only" &&
                  <div>
                    <button onClick={() => {
                      navigate('/api-connection')
                    }}><i className="bi bi-gear"></i></button>
                  </div>
                }
                <div>
                <button onClick={async () => {
                  setLoadingPdf(true);
                  const pdf = new jsPDF('p', 'mm', 'a4');
                  const options = {
                    scale: 2
                  };
                  for (let i = 0; i < divRefs.current.length; i++) {
                    const canvas = await html2canvas(divRefs.current[i], options);
                    const imgData = canvas.toDataURL('image/png');
                    const imgProps =  pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    pdf.setFontSize(10);
                    if (i > 0) {
                      pdf.addPage();
                    }
                    if (user?.agency?.base64logo) {
                      var img = new Image()
                      const boxWidth = 50;
                      const boxHeight = 50;

                      img.src = `data:image/png;base64,${user?.agency?.base64logo}`;
                      img.onload = function () {
                        let width = img.width;
                        let height = img.height;

                        const aspectRatio = width / height;
                        const boxAspectRatio = boxWidth / boxHeight;

                        if (aspectRatio > boxAspectRatio) {
                          // image is wider relative to the box
                          width = boxWidth;
                          height = boxWidth / aspectRatio;
                        } else {
                          height = boxHeight;
                          width = boxHeight * aspectRatio;
                        }

                        pdf.addImage(img, 'png', 10, 10, width, height);
                      }
                    }
                    pdf.text(clinicData?.name, pdfWidth - 60, 14);
                    pdf.text(`From ${dateFilter?.startDate} to ${dateFilter?.endDate}`, pdfWidth - 60, 20);
                    pdf.addImage(imgData, 'PNG', 0, 25, pdfWidth, pdfHeight);
                    pdf.text('Copyright @ Data-Driven.io '+ new Date().getFullYear(), (pdfWidth / 2) - 25, pdfHeight + 40);
                  }
                  pdf.save(`report_${moment().format("DD-MM-YYYY")}.pdf`);
                  setLoadingPdf(false);
                  return;
                  toPDF({
                    method: 'open',
                    resolution: Resolution.HIGH,
                    page: {
                        margin: Margin.SMALL,
                        format: 'letter',
                        orientation: 'landscape',
                    },
                    canvas: {
                        mimeType: 'image/png',
                        qualityRatio: 1
                    },
                    overrides: {
                        pdf: {
                          compress: true
                        },
                        canvas: {
                          useCORS: false,
                          height: 1000
                        },
                    },
                  });
                }}><i className="bi bi-file-earmark-pdf"></i></button>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <small className="pt-1 text-success">From {dateFilter?.startDate} to {dateFilter?.endDate}</small>
                {
                  loadingPdf &&
                  <small className="pt-1 text-info">Preparing...</small>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <div ref={targetRef} id="page">
        {mainContent}
      </div>
    </>
  )
}