import { useEffect, useMemo, useState } from "react";
import { getGoalsInputDelete, getGoalsInputList, goalInputsUpdate } from "../../services/configurations";
import { useAppSelector } from "../../store/hook";
import moment from "moment";
import { formatCalcNumber, formatter } from "../../helpers/currency";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { uniqBy } from "lodash";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import copy from 'copy-to-clipboard';
const keysToCheck = [
  "year",
  "month",
  "ltv",
  "goodwill",
  {
    "id": "seoBudget",
    "dependants": ["seoLeads", "seoAcquisitions"]
  },
  {
    "id": "gmbBudget",
    "dependants": ["gmbLeads", "gmbAcquisitions"]
  },
  {
    "id": "paidAdsBudget",
    "dependants": ["paidAdsLead", "paidAdsAcquisition"]
  },
  {
    "id": "emailBudget",
    "dependants": ["emailLeads", "emailAcquisitions"]
  },
  {
    "id": "otherBudget",
    "dependants": ["otherLeads", "otherAcquisitions"]
  },
  {
    "id": "reviewBudget",
    "dependants": ["reviewAcquisitions"]
  },
  {
    "id": "socialBudget",
    "dependants": ["socialAcquisitions"]
  },
  {
    "id": "xcareBudget",
    "dependants": ["xcareAcquisitions"]
  },
  {
    "id": "otherBrandingBudget",
    "dependants": ["otherBrandingAcquisitions"]
  },
  {
    "id": "paidAdsFee",
    "dependants": []
  }
];
export const GoalInput = () => {
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic')!== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const [selectedConfigs, setSelectedConfigs] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState(moment().toDate());
  const clinic = useMemo(() => {
    return clinics?.find((item: any) => Number(item.uniqueId) === Number(selectedClinic));
  }, [selectedClinic, clinics]);
  const [goalsInput, setGoalsInput] = useState({
    year: moment().toDate(),
    month: moment().toDate(),
    ltv: '',
    goodwill: '',
    seoBudget: '',
    seoLeads: '',
    seoAcquisitions: '',
    gmbBudget: '',
    gmbLeads: '',
    gmbAcquisitions: '',
    paidAdsBudget: '',
    paidAdsLead: '',
    paidAdsAcquisition: '',
    emailBudget: '',
    emailLeads: '',
    emailAcquisitions: '',
    otherBudget: '',
    reviewBudget: '',
    reviewAcquisitions: '',
    reviewActual: '',
    socialBudget: '',
    socialAcquisitions: '',
    socialActual: '',
    xcareBudget: '',
    xcareAcquisitions: '',
    xcareActual: '',
    otherBrandingBudget: '',
    otherAcquisitions: '',
    otherBrandingAcquisitions: '',
    otherActual: '',
    paidAdsFee: '',
    otherLeads: '',
    applytoall: false,
    loyaltyBudget: '',
    xcareLoyaltyLeads: '',
    xcareLoyaltyAcquisitions: '',
  });
  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }

  useEffect(() => {
    _getConfigList();
  }, [selectedClinic]);

  const _getConfigList = (year = moment(selectedYear).format("YYYY")) => {
    if (selectedClinic) {
      getGoalsInputList(selectedClinic, year).then((success) => {
        setSelectedConfigs(success?.data ?? []);
      }).catch((error) => {
        console.log(error);
      })
    }
  };

  const setDefaultGoal = (year = moment().toDate(), month = moment().toDate()) => {
    setGoalsInput({
      year: year,
      month: month,
      ltv: '',
      goodwill: '',
      seoBudget: '',
      seoLeads: '',
      seoAcquisitions: '',
      gmbBudget: '',
      gmbLeads: '',
      gmbAcquisitions: '',
      paidAdsBudget: '',
      paidAdsLead: '',
      paidAdsAcquisition: '',
      emailBudget: '',
      emailLeads: '',
      emailAcquisitions: '',
      otherBudget: '',
      reviewBudget: '',
      reviewAcquisitions: '',
      reviewActual: '',
      socialBudget: '',
      socialAcquisitions: '',
      socialActual: '',
      xcareBudget: '',
      xcareAcquisitions: '',
      xcareActual: '',
      otherBrandingBudget: '',
      otherAcquisitions: '',
      otherBrandingAcquisitions: '',
      otherActual: '',
      paidAdsFee: '',
      otherLeads: '',
      applytoall: false,
      loyaltyBudget: '',
      xcareLoyaltyLeads: '',
      xcareLoyaltyAcquisitions: '',
    });
  }

  const handleSave = () => {
    goalInputsUpdate({
      ...goalsInput,
      year: moment(goalsInput?.year).format("YYYY"),
      month: moment(goalsInput?.month).format("M")
    }, selectedClinic).then((success) => {
      setDefaultGoal();
      _getConfigList();
      toast.success(success?.status?.msg);
    }).catch((error) => {
      console.log(error);
    });
  }
  const years = uniqBy(selectedConfigs, 'year')?.map((item: any) => item.year);
  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  const isValid = useMemo(() => {
      const cloned: any = JSON.parse(JSON.stringify(goalsInput));
      let isValid = true;
      for (const item of keysToCheck) {
        if (typeof item === "object") {
          if (cloned[item?.id] && cloned[item?.id] !== 0) {
            if (item?.dependants?.length === 1 && !Boolean(cloned[item.dependants[0]])) {
              isValid = false;
              break;
            } else if (item.dependants?.length === 2 && 
              (!cloned[item.dependants[1]] || !cloned[item.dependants[0]])
            ) {
              isValid = false;
              break;
            }
          } 
        } else  if ((!cloned[item] && typeof cloned[item] !== "boolean") || Number(cloned[item]) === 0) {
          isValid = false;
          break;
        }
      }
      return isValid;
  }, [goalsInput]);

  const totalLeadBudget = Number(goalsInput?.seoBudget) + Number(goalsInput?.gmbBudget) + Number(goalsInput?.paidAdsBudget) + Number(goalsInput?.paidAdsFee) + Number(goalsInput?.emailBudget) + Number(goalsInput?.otherBudget);
  const totalLeadsGoal = Number(goalsInput?.seoLeads) + Number(goalsInput?.gmbLeads) + Number(goalsInput?.emailLeads) + Number(goalsInput?.paidAdsLead) + Number(goalsInput?.otherLeads ?? 0);
  const totalAcquisitionsGoal = Number(goalsInput?.seoAcquisitions) + Number(goalsInput?.gmbAcquisitions) + Number(goalsInput?.emailAcquisitions) + Number(goalsInput?.paidAdsAcquisition) + Number(goalsInput?.otherAcquisitions);

  const totalBudgetBranding = Number(goalsInput?.reviewBudget ?? 0) + Number(goalsInput?.socialBudget ?? 0) + Number(goalsInput?.xcareBudget ?? 0) + Number(goalsInput?.otherBrandingBudget ?? 0);
  const totalAcquisitionsBranding = Number(goalsInput?.reviewAcquisitions) + Number(goalsInput?.socialAcquisitions) + Number(goalsInput?.xcareAcquisitions) + Number(goalsInput?.otherBrandingAcquisitions);
  const totalAcquisitionsBrandingActuals = Number(goalsInput?.reviewActual) + Number(goalsInput?.socialActual) + Number(goalsInput?.xcareActual) + Number(goalsInput?.otherActual);

  const totalTableBudget = selectedConfigs?.reduce((acc: number, item: any) => {
    return Number(acc) + (Number(item.seoBudget) + Number(item.gmbBudget) + Number(item.paidAdsBudget) + Number(item.paidAdsFee) + Number(item.emailBudget) + Number(item.otherBudget) + Number(item.reviewBudget) + Number(item.socialBudget) + Number(item.xcareBudget) + Number(item.otherBrandingBudget));
  }, 0);

  const totalValue = selectedConfigs?.reduce((acc: number, item: any) => {
    const totalAcquisitions = Number(item.seoAcquisitions) + Number(item.gmbAcquisitions) + Number(item.paidAdsAcquisition) + Number(item.emailAcquisitions) + Number(item.otherAcquisitions);
    const brandAcquisition = Number(item.reviewAcquisitions) + Number(item.xcareAcquisitions) + Number(item.socialAcquisitions) + Number(item?.otherBrandingAcquisitions ?? 0);
    return acc + ((totalAcquisitions * item.ltv) + (brandAcquisition * item.goodwill));
  }, 0);

  const setGoalData = (year: any, month: any) => {
    const _year = moment(year).format("YYYY");
    const _month = moment(month).format("M");
    const found: any = selectedConfigs?.find((item: any) => Number(item.year) === Number(_year) && Number(item.month) === Number(_month));
    if (found) {
      setGoalsInput({
        ...found,
        month: moment(_month).toDate(),
        year: moment(_year).toDate(),
      });
    } else {
      setDefaultGoal(moment(_year).toDate(), moment(`${_month}-${_year}`, 'M-YYYY').toDate());
    }
  };

  const handleFieldChange = (value: any, fieldName: string) => {
    if (!isNaN(Number(value)) || value === '') {
      if (value === '') {
        setGoalsInput({
          ...goalsInput,
          [fieldName]: ''
        });
      }else {
        setGoalsInput({
          ...goalsInput,
          [fieldName]: Number(value).toString()
        });
      }
    }
  }

  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt">Configuration</div>
              </div>
              <ul className="nav nav-tabs">
                {
                  user?.role !== "Read Only" &&
                  <li className="nav-item"><NavLink className="nav-link" to="/api-connection">Api integration</NavLink></li>
                }
                <li className="nav-item"><NavLink className="nav-link" to="/configure-widget">Configure Widgets</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/user-assign">Users</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/goal-input">Goals and Inputs</NavLink></li>
              </ul>
            </div>

            <div className="d-flex gap-3 align-items-center">

              <DatePicker
                className="form-control col-2"
                selected={moment(selectedYear).toDate()}
                onChange={(date: any) => {
                  setSelectedYear(moment(date).toDate());
                  _getConfigList(moment(date).format("YYYY"));
                }}
                showYearPicker
                dateFormat="yyyy"
              />

              <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                setSelectedClinic(e.target.value);
                sessionStorage.setItem('selectedClinic', e.target.value);
                setGoalsInput({
                  year: moment().toDate(),
                  month: moment().toDate(),
                  ltv: '',
                  goodwill: '',
                  seoBudget: '',
                  seoLeads: '',
                  seoAcquisitions: '',
                  gmbBudget: '',
                  gmbLeads: '',
                  gmbAcquisitions: '',
                  paidAdsBudget: '',
                  paidAdsLead: '',
                  paidAdsAcquisition: '',
                  emailBudget: '',
                  emailLeads: '',
                  emailAcquisitions: '',
                  otherBudget: '',
                  reviewBudget: '',
                  reviewAcquisitions: '',
                  reviewActual: '',
                  socialBudget: '',
                  socialAcquisitions: '',
                  socialActual: '',
                  xcareBudget: '',
                  xcareAcquisitions: '',
                  xcareActual: '',
                  otherBrandingBudget: '',
                  otherAcquisitions: '',
                  otherBrandingAcquisitions: '',
                  otherActual: '',
                  paidAdsFee: '',
                  otherLeads: '',
                  applytoall: false,
                  loyaltyBudget: '',
                  xcareLoyaltyLeads: '',
                  xcareLoyaltyAcquisitions: '',
                });
              }}>
                {
                  clinics?.map((item: any) => {
                    return (
                      <option value={item.uniqueId}>{item.name}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </div>
      </section>


      <section className="configration-details-sec px-30 pt-5">
        <div className="container">
          <div className="row">
            <table className="table user-table">
              <thead>
                <tr>
                  <th scope="col">Month</th>
                  <th scope="col">Total Budget</th>
                  <th scope="col" className="col-2">Leads</th>
                  <th scope="col" className="col-2">Acquisitions</th>
                  <th scope="col" className="col-2">Value</th>
                  <th scope="col" className="col-2">ROI</th>
                </tr>
              </thead>
              <tbody>
                {
                  selectedConfigs?.map((item: any) => {
                    const totalBudget = Number(item.seoBudget) + Number(item.gmbBudget) + Number(item.paidAdsBudget) + Number(item.paidAdsFee) + Number(item.emailBudget) + Number(item.otherBudget) + Number(item.reviewBudget) + Number(item.socialBudget) + Number(item.xcareBudget) + Number(item.otherBrandingBudget);
                    const totalLeads = Number(item?.seoLeads ?? 0) + Number(item?.gmbLeads ?? 0) + Number(item?.paidAdsLead ?? 0) + Number(item?.emailLeads ?? 0) + Number(item?.otherLeads ?? 0);
                    const totalAcquisitions = Number(item.seoAcquisitions) + Number(item.gmbAcquisitions) + Number(item.paidAdsAcquisition) + Number(item.emailAcquisitions) + Number(item.otherAcquisitions);
                    const brandAcquisition = Number(item.reviewAcquisitions) + Number(item.xcareAcquisitions) + Number(item.socialAcquisitions) + Number(item?.otherBrandingAcquisitions ?? 0);
                    const value = (totalAcquisitions * item.ltv) + (brandAcquisition * item.goodwill);
                    return (
                      <tr>
                        <th scope="row">{moment(item.month, 'M').format('MMMM')}-{moment(item.year, 'YYYY').format('YY')}</th>
                        <td>{formatter.format(totalBudget)}</td>
                        <td>{totalLeads}</td>
                        <td>{totalAcquisitions}</td>
                        <td>{formatter.format(value)}</td>
                        <td>
                          {totalBudget > 0 && value > 0 && !isNaN(value) && !isNaN(totalBudget) ?  (Number(value) / Number(totalBudget) * 100).toFixed(2) : 0}%
                          {
                            user.role !== "Read Only" &&
                            <>  
                              <span className="mx-2 cursor-pointer"><i className="bi bi-pencil" onClick={() => {
                                setGoalsInput({
                                  ...item,
                                  month: moment(item.year + '-' + item.month, 'YY-MM').toDate(),
                                  year: moment(item.year).toDate(),
                                });
                              }}></i></span>
                              <span className="mx-2 cursor-pointer"><i className="bi bi-trash" onClick={async () => {
                                const res = window.confirm('Are you sure?');
                                if (res) {
                                  getGoalsInputDelete(selectedClinic, item.id).then((success) => {
                                    _getConfigList();
                                  }).catch((error) => {
                                    console.log(error);
                                  })
                                }
                              }}></i></span>
                            </>
                          }
                        </td>
                      </tr>
                    )
                  })
                }

                <tr>
                  <th scope="row">Total</th>
                  <th scope="row">
                    {formatter.format(totalTableBudget)}
                  </th>
                  <th scope="row">
                    {
                      selectedConfigs?.reduce((acc: number, item: any) => {
                        return acc + (Number(item.seoLeads) + Number(item.gmbLeads) + Number(item.paidAdsLead) + Number(item.emailLeads) + Number(item?.otherLeads ?? 0));
                      }, 0)
                    }
                  </th>
                  <th scope="row">
                    {
                      selectedConfigs?.reduce((acc: number, item: any) => {
                        return acc + (Number(item.seoAcquisitions) + Number(item.gmbAcquisitions) + Number(item.paidAdsAcquisition) + Number(item.emailAcquisitions) + Number(item.otherAcquisitions));
                      }, 0)
                    }
                  </th>
                  <th scope="row">
                    {
                      formatter.format(selectedConfigs?.reduce((acc: number, item: any) => {
                        const totalAcquisitions = Number(item.seoAcquisitions) + Number(item.gmbAcquisitions) + Number(item.paidAdsAcquisition) + Number(item.emailAcquisitions) + Number(item.otherAcquisitions);
                        const brandAcquisition = Number(item.reviewAcquisitions) + Number(item.xcareAcquisitions) + Number(item.socialAcquisitions) + Number(item?.otherBrandingAcquisitions ?? 0);
                        return acc + ((totalAcquisitions * item.ltv) + (brandAcquisition * item.goodwill));
                      }, 0))
                    }
                  </th>
                  <th scope="row">
                    {!isNaN(Number(totalTableBudget)) && !isNaN(Number(totalValue)) && !isNaN(Number(totalValue / totalTableBudget)) ? Number((totalValue / totalTableBudget) * 100).toFixed(2) + '%' : '-'}
                  </th>
                </tr>
              </tbody>
            </table>

          </div>

        </div>
      </section>

      {
        user.role !== "Read Only" &&
        <section className="goal-input-add px-30 pt-5">
          <div className="container">

            <div className="row">
              <div className="d-flex mb-3 pb-3 border-bottom px-0 align-items-center justify-content-between">
                <h6 className="fs-5 m-0">Add/update goals</h6>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-4 p-0">

                <div className="d-flex justify-content-start">
                  <div className="d-flex gap-3">
                    <DatePicker
                      className="form-control col-2"
                      selected={goalsInput?.year}
                      onChange={(date: any) => {
                        setGoalsInput({
                          ...goalsInput,
                          year: date,
                          month: moment(`${moment(goalsInput?.month).format("MM")}-${moment(date).format("YYYY")}`, 'MM-YYYY').toDate()
                        });
                        setGoalData(date, moment(`${moment(goalsInput?.month).format("MM")}-${moment(date).format("YYYY")}`, 'MM-YYYY').toDate());
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                    <DatePicker
                      className="form-control col-2"
                      selected={goalsInput?.month}
                      onChange={(date: any) => {
                        setGoalsInput({
                          ...goalsInput,
                          month: moment(`${moment(date).format("MM")}-${moment(goalsInput?.year).format("YYYY")}`, 'MM-YYYY').toDate(),
                        });
                        setGoalData(goalsInput?.year, date);
                      }}
                      showMonthYearPicker
                      dateFormat="MMM"
                    />
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <div className="row py-2 px-3">
                      <div className="col-2">
                        LTV
                      </div>
                      <div className="col-10">
                        <input type="text" min={0} className="form-control form-control-sm" placeholder="" value={goalsInput?.ltv} onChange={(e) => {
                          handleFieldChange(e.target.value, 'ltv');
                        }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row py-2 px-3">
                      <div className="col-4">
                        Good will
                      </div>
                      <div className="col-8">
                        <input type="text" min={0} className="form-control form-control-sm" placeholder="" value={goalsInput?.goodwill} onChange={(e) => {
                          handleFieldChange(e.target.value, 'goodwill');
                        }} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="card bg-light mb-3">

                <div className="row">
                  <div className="col-5 border-end">

                    <div className="row p-3 border-bottom">
                      <div className="col-12">
                        <h6>Lead Generation</h6>
                      </div>
                    </div>

                    <div className="row p-3 border-bottom mb-3">
                      <div className="col-8">
                        <div className="fw-medium">Source/URL</div>
                      </div>
                      <div className="col-4">
                        <div className="fw-medium">Budget</div>
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={(
                          <Popover>
                            <p className="p-2" onClick={() => {
                              copy(`https://xcarepro.com/seo/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`);
                            }}>{`https://xcarepro.com/seo/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                          </Popover>
                        )}><button>SEO <i className="bi bi-info-circle-fill"></i></button></OverlayTrigger>
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.seoBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'seoBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={(
                          <Popover>
                            <p className="p-2" onClick={() => {
                              copy(`https://xcarepro.com/gmb/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`)
                            }}>{`https://xcarepro.com/gmb/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                          </Popover>
                        )}><button>GMB <i className="bi bi-info-circle-fill"></i></button></OverlayTrigger>
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.gmbBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'gmbBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={(
                          <Popover>
                            <ul>
                              <li>
                                <p className="p-2" onClick={() => {
                                  copy(`https://xcarepro.com/google-ads/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`)
                                }}>{`https://xcarepro.com/google-ads/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                              </li>
                              <li>
                                <p className="p-2" onClick={() => {
                                  copy(`https://xcarepro.com/meta-ads/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`)
                                }}>{`https://xcarepro.com/meta-ads/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                              </li>
                            </ul>
                          </Popover>
                        )}><button>Paid Ads (Meta + Google) <i className="bi bi-info-circle-fill"></i></button></OverlayTrigger>
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.paidAdsBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'paidAdsBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        Paid Ads Management Fee
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.paidAdsFee} onChange={(e) => {
                          handleFieldChange(e.target.value, 'paidAdsFee');
                        }} />
                      </div>
                    </div>


                    <div className="row py-2 px-3">
                      <div className="col-8">
                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={(
                          <Popover>
                            <ul>
                              <li>
                                <p className="p-2" onClick={() => {
                                  copy(`https://xcarepro.com/email/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`)
                                }}>{`https://xcarepro.com/email/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                              </li>
                            </ul>
                          </Popover>
                        )}><button>Email Outreach <i className="bi bi-info-circle-fill"></i></button></OverlayTrigger>
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.emailBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'emailBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={(
                          <Popover>
                            <ul>
                              <li>
                                <p className="p-2" onClick={() => {
                                  copy(`https://xcarepro.com/xcare-loyalty/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`)
                                }}>{`https://xcarepro.com/xcare-loyalty/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                              </li>
                            </ul>
                          </Popover>
                        )}><button>Xcare Loyalty <i className="bi bi-info-circle-fill"></i></button></OverlayTrigger>
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.loyaltyBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'loyaltyBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        Other
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.otherBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'otherBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row p-3 border-top mt-3">
                      <div className="col-8">
                        <h6>Total/ Avg (Lead Gen)</h6>
                      </div>
                      <div className="col-4 text-right">
                        <h6>{formatter.format(totalLeadBudget)}</h6>
                      </div>
                    </div>

                  </div>
                  <div className="col-7">
                    <div className="row p-3 border-bottom">
                      <div className="col-12">
                        <h6>Goals</h6>
                      </div>
                    </div>

                    <div className="row p-3 border-bottom mb-3">
                      <div className="col-3">
                        <div className="fw-medium">Leads</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-medium">Cost/ Lead</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-medium">Acquisitions</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-medium">Cost /Acq</div>
                      </div>
                    </div>

                    {/* SEO  */}
                    <div className="row py-2 px-3 ">
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.seoLeads} onChange={(e) => {
                          handleFieldChange(e.target.value, 'seoLeads');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.seoBudget) !== 0 && Number(goalsInput?.seoLeads) !== 0 ?
                            formatter.format(Number(goalsInput?.seoBudget ?? 0) / Number(goalsInput?.seoLeads ?? 0)) : '-'
                        }
                      </div>
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.seoAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'seoAcquisitions');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.seoBudget) !== 0 && Number(goalsInput?.seoAcquisitions) !== 0 ?
                            formatter.format(Number(goalsInput?.seoBudget ?? 0) / Number(goalsInput?.seoAcquisitions ?? 0)) : '-'
                        }
                      </div>
                    </div>

                    {/* GMB  */}
                    <div className="row py-2 px-3">
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.gmbLeads} onChange={(e) => {
                          handleFieldChange(e.target.value, 'gmbLeads');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.gmbBudget) !== 0 && Number(goalsInput?.gmbLeads) !== 0 ?
                            formatter.format(Number(goalsInput?.gmbBudget ?? 0) / Number(goalsInput?.gmbLeads ?? 0)) : '-'
                        }
                      </div>
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.gmbAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'gmbAcquisitions');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.gmbBudget) !== 0 && Number(goalsInput?.gmbAcquisitions) !== 0 ?
                            formatter.format(Number(goalsInput?.gmbBudget ?? 0) / Number(goalsInput?.gmbAcquisitions ?? 0)) : '-'
                        }
                      </div>
                    </div>

                    {/* Paid Ads  */}
                    <div className="row py-2 px-3">
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.paidAdsLead} onChange={(e) => {
                          handleFieldChange(e.target.value, 'paidAdsLead');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.paidAdsBudget) !== 0 && Number(goalsInput?.paidAdsLead) !== 0 ?
                            formatter.format(Number(goalsInput?.paidAdsBudget ?? 0) / Number(goalsInput?.paidAdsLead ?? 0)) : '-'
                        }
                      </div>
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.paidAdsAcquisition} onChange={(e) => {
                          handleFieldChange(e.target.value, 'paidAdsAcquisition');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.paidAdsBudget) !== 0 && Number(goalsInput?.paidAdsAcquisition) !== 0 ?
                            formatter.format(Number(goalsInput?.paidAdsBudget ?? 0) / Number(goalsInput?.paidAdsAcquisition ?? 0)) : '-'
                        }
                      </div>
                    </div>

                    {/* Paid Ads Management Fees  */}
                    <div className="row py-2 px-3 blank-space">
                      <div className="col-3">
                        -
                      </div>
                      <div className="col-3">
                        -
                      </div>
                      <div className="col-3">
                        -
                      </div>
                      <div className="col-3">
                        -
                      </div>
                    </div>

                    {/* Email Outreach  */}
                    <div className="row py-2 px-3">
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.emailLeads} onChange={(e) => {
                          handleFieldChange(e.target.value, 'emailLeads');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.emailBudget) !== 0 && Number(goalsInput?.emailLeads) !== 0 && !isNaN(Number(goalsInput?.emailBudget ?? 0) / Number(goalsInput?.emailLeads ?? 0)) ?
                            formatter.format(Number(goalsInput?.emailBudget ?? 0) / Number(goalsInput?.emailLeads ?? 0)) : '-'}
                      </div>
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.emailAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'emailAcquisitions');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.emailBudget) !== 0 && Number(goalsInput?.emailAcquisitions) !== 0 && !isNaN(Number(goalsInput?.emailBudget ?? 0) / Number(goalsInput?.emailAcquisitions ?? 0)) ?
                            formatter.format(Number(goalsInput?.emailBudget ?? 0) / Number(goalsInput?.emailAcquisitions ?? 0)) : '-'}
                      </div>
                    </div>

                    {/* Xcare Loyalty  */}
                    <div className="row py-2 px-3">
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.xcareLoyaltyLeads} onChange={(e) => {
                          handleFieldChange(e.target.value, 'xcareLoyaltyLeads');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.loyaltyBudget) !== 0 && Number(goalsInput?.xcareLoyaltyLeads) !== 0 && !isNaN(Number(goalsInput?.loyaltyBudget ?? 0) / Number(goalsInput?.xcareLoyaltyLeads ?? 0)) ?
                            formatter.format(Number(goalsInput?.loyaltyBudget ?? 0) / Number(goalsInput?.xcareLoyaltyLeads ?? 0)) : '-'}
                      </div>
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.xcareLoyaltyAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'xcareLoyaltyAcquisitions');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.loyaltyBudget) !== 0 && Number(goalsInput?.xcareLoyaltyAcquisitions) !== 0 && !isNaN(Number(goalsInput?.loyaltyBudget ?? 0) / Number(goalsInput?.xcareLoyaltyAcquisitions ?? 0)) ?
                            formatter.format(Number(goalsInput?.loyaltyBudget ?? 0) / Number(goalsInput?.xcareLoyaltyAcquisitions ?? 0)) : '-'}
                      </div>
                    </div>

                    {/* Other Leads  */}
                    <div className="row py-2 px-3">
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.otherLeads} onChange={(e) => {
                          handleFieldChange(e.target.value, 'otherLeads');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.otherBudget) !== 0 && Number(goalsInput?.otherLeads) !== 0 && !isNaN(Number(goalsInput?.otherBudget)) &&
                          !isNaN(Number(goalsInput?.otherLeads)) ?
                            formatter.format(formatCalcNumber(Number(goalsInput?.otherBudget ?? 0), Number(goalsInput?.otherLeads ?? 0))) : '-'
                        }
                      </div>
                      <div className="col-3">
                        <input type="text" min={0} className="form-control form-control-sm text-center" placeholder="$750.00" value={goalsInput?.otherAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'otherAcquisitions');
                        }} />
                      </div>
                      <div className="col-3">
                        {
                          Number(goalsInput?.otherBudget) !== 0 && Number(goalsInput?.otherAcquisitions) !== 0 &&
                          !isNaN(Number(goalsInput?.otherBudget)) && !isNaN(Number(goalsInput?.otherAcquisitions)) ?
                            formatter.format(formatCalcNumber(goalsInput?.otherBudget, goalsInput?.otherAcquisitions)) : '-'
                        }
                      </div>
                    </div>

                    <div className="row p-3 border-top mt-3">
                      <div className="col-3 text-center">
                        <h6>{totalLeadsGoal}</h6>
                      </div>
                      <div className="col-3">
                        <h6>
                          {
                            formatter.format(formatCalcNumber(totalLeadBudget, totalLeadsGoal))
                          }
                        </h6>
                      </div>
                      <div className="col-3 text-center">
                        <h6>{totalAcquisitionsGoal}</h6>
                      </div>
                      <div className="col-3">
                        <h6>{
                            formatter.format(formatCalcNumber(totalLeadBudget, totalAcquisitionsGoal))
                          }</h6>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

              <div className="card bg-light">

                <div className="row">
                  <div className="col-4 border-end">

                    <div className="row p-3 border-bottom">
                      <div className="col-12">
                        <h6>Branding</h6>
                      </div>
                    </div>

                    <div className="row p-3 border-bottom mb-3">
                      <div className="col-8">
                        <div className="fw-medium">Source/URL</div>
                      </div>
                      <div className="col-4">
                        <div className="fw-medium">Budget</div>
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        Reviews
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.reviewBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'reviewBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={(
                          <Popover>
                            <ul>
                              <li>
                                <p className="p-2" onClick={() => {
                                  copy(`https://xcarepro.com/facebook/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`)
                                }}>{`https://xcarepro.com/facebook/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                              </li>
                              <li>
                                <p className="p-2" onClick={() => {
                                  copy(`https://xcarepro.com/instagram/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`)
                                }}>{`https://xcarepro.com/instagram/${clinic?.name?.split(" ").join("-")}/${selectedClinic}/c`}</p>
                              </li>
                            </ul>
                          </Popover>
                        )}><button>Social <i className="bi bi-info-circle-fill"></i></button></OverlayTrigger>
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.socialBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'socialBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        Xcare Platform
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.xcareBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'xcareBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row py-2 px-3">
                      <div className="col-8">
                        Other
                      </div>
                      <div className="col-4">
                        <input type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.otherBrandingBudget} onChange={(e) => {
                          handleFieldChange(e.target.value, 'otherBrandingBudget');
                        }} />
                      </div>
                    </div>

                    <div className="row p-3 border-top mt-3">
                      <div className="col-8">
                        <h6>Total/ Avg (Branding)</h6>
                      </div>
                      <div className="col-4 text-right">
                        <h6>{formatter.format(
                          Number(goalsInput?.reviewBudget ?? 0) + Number(goalsInput?.socialBudget ?? 0) + Number(goalsInput?.xcareBudget ?? 0) + Number(goalsInput?.otherBrandingBudget ?? 0)
                        )}</h6>
                      </div>
                    </div>

                  </div>

                  <div className="col-4 border-end">

                    <div className="row p-3 border-bottom">
                      <div className="col-12">
                        <h6>Goals</h6>
                      </div>
                    </div>

                    <div className="row p-3 border-bottom mb-3">
                      <div className="col-7">
                        <div className="fw-medium">Acquisitions</div>
                      </div>
                      <div className="col-5">
                        <div className="fw-medium">Cost /Acq</div>
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.reviewAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'reviewAcquisitions');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.reviewBudget) !== 0 && Number(goalsInput?.reviewAcquisitions) !== 0 &&
                          !isNaN(Number(goalsInput?.reviewBudget ?? 0) / Number(goalsInput?.reviewAcquisitions ?? 0)) ?
                            formatter.format(Number(goalsInput?.reviewBudget ?? 0) / Number(goalsInput?.reviewAcquisitions ?? 0)) : '-'
                        }
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.socialAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'socialAcquisitions');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.socialBudget) !== 0 && Number(goalsInput?.socialAcquisitions) !== 0 ?
                            formatter.format(Number(goalsInput?.socialBudget ?? 0) / Number(goalsInput?.socialAcquisitions ?? 0)) : '-'
                        }
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.xcareAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'xcareAcquisitions');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.xcareBudget) !== 0 && Number(goalsInput?.xcareAcquisitions) !== 0 ?
                            formatter.format(Number(goalsInput?.xcareBudget ?? 0) / Number(goalsInput?.xcareAcquisitions ?? 0)) : '-'
                        }
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.otherBrandingAcquisitions} onChange={(e) => {
                          handleFieldChange(e.target.value, 'otherBrandingAcquisitions');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.otherBrandingBudget) !== 0 && Number(goalsInput?.otherBrandingAcquisitions) !== 0 ?
                            formatter.format(formatCalcNumber(goalsInput?.otherBrandingBudget ?? 0, Number(goalsInput?.otherBrandingAcquisitions ?? 0))) : '-'
                        }
                      </div>
                    </div>


                    <div className="row p-3 border-top mt-3">
                      <div className="col-8">
                        <h6>{totalAcquisitionsBranding}</h6>
                      </div>
                      <div className="col-4">
                        <h6>{formatter.format(formatCalcNumber(totalBudgetBranding, totalAcquisitionsBranding))}</h6>
                      </div>
                    </div>

                  </div>

                  <div className="col-4 border-end">

                    <div className="row p-3 border-bottom">
                      <div className="col-12">
                        <h6>Actuals</h6>
                      </div>
                    </div>

                    <div className="row p-3 border-bottom mb-3">
                      <div className="col-7">
                        <div className="fw-medium">Acquisitions</div>
                      </div>
                      <div className="col-5">
                        <div className="fw-medium">Cost /Acq</div>
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.reviewActual} onChange={(e) => {
                          handleFieldChange(e.target.value, 'reviewActual');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.reviewBudget) !== 0 && Number(goalsInput?.reviewActual) !== 0 ?
                            formatter.format(formatCalcNumber(goalsInput?.reviewBudget ?? 0, Number(goalsInput?.reviewActual ?? 0))) : '-'}
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.socialActual} onChange={(e) => {
                          handleFieldChange(e.target.value, 'socialActual');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.socialBudget) !== 0 && Number(goalsInput?.socialActual) !== 0 ?
                            formatter.format(formatCalcNumber(goalsInput?.socialBudget ?? 0, Number(goalsInput?.socialActual ?? 0))) : '-'}
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.xcareActual} onChange={(e) => {
                          handleFieldChange(e.target.value, 'xcareActual');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.xcareBudget) !== 0 && Number(goalsInput?.xcareActual) !== 0 ?
                            formatter.format(formatCalcNumber(goalsInput?.xcareBudget ?? 0, Number(goalsInput?.xcareActual ?? 0))) : '-'}
                      </div>
                    </div>

                    <div className="row row-reverse py-2 px-3">
                      <div className="col-8">
                        <input style={{ maxWidth: '100px' }} type="text" min={0} className="form-control form-control-sm text-right" placeholder="$750.00" value={goalsInput?.otherActual} onChange={(e) => {
                          handleFieldChange(e.target.value, 'otherActual');
                        }} />
                      </div>
                      <div className="col-4">
                        {
                          Number(goalsInput?.otherBrandingBudget) !== 0 && Number(goalsInput?.otherActual) !== 0 ?
                            formatter.format(formatCalcNumber(goalsInput?.otherBrandingBudget ?? 0,  Number(goalsInput?.otherActual ?? 0))) : '-'}
                      </div>
                    </div>


                    <div className="row p-3 border-top mt-3">
                      <div className="col-8">
                        <h6>{totalAcquisitionsBrandingActuals}</h6>
                      </div>
                      <div className="col-4">
                        <h6>{!isNaN(totalBudgetBranding) && !isNaN(totalAcquisitionsBrandingActuals) ? formatter.format(formatCalcNumber(totalBudgetBranding, totalAcquisitionsBrandingActuals)) : '-'}</h6>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

              <div className="goal-action d-flex justify-content-end align-items-center gap-4 px-0 py-4">
                {
                  !isValid && <label className="text-danger">*All fields are mandatory</label>
                }
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="gridCheck" checked={goalsInput?.applytoall} onChange={(e) => {
                    setGoalsInput({
                      ...goalsInput,
                      applytoall: !goalsInput?.applytoall
                    });
                  }} />
                  <label className="form-check-label m-0" htmlFor="gridCheck">
                    Apply to all months
                  </label>
                </div>
                <button className="btn btn-primary" disabled={!isValid} onClick={handleSave}>Update</button>

              </div>

            </div>
          </div>
        </section>
      }
    </>
  )
}