import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hook";
import { useEffect, useState } from "react";
import Plans from "./Plans";
import { getLoyaltyPlanList, getPointsOverview } from "../../services/subscription";

export const SubscriptionPlan = () => {
    const user = useAppSelector((state) => state.user.user);
    const clinics = useAppSelector((state) => state.user.user.clinics);
    const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic') !== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
    const navigate = useNavigate();
    const [state, setState] = useState({
        planList: [],
        currentPlan: [],
    });
    const [totalPoints, setTotalPoints] = useState(0);
    if (!selectedClinic && clinics?.[0]?.uniqueId) {
        setSelectedClinic(clinics?.[0]?.uniqueId);
    }
    useEffect(() => {
        if (selectedClinic !== undefined) {
            getPlans();
            getPointTotal();
        }
    }, [selectedClinic]);
    const handlePurchasePlan = (selectedPlan: any) => {
        navigate('/subscription-upgrade-plan',{
            state: {
                data: state.planList,
                selectedPlan: selectedPlan,
            }
        })
    }
    const getPlans = async () => {
        const res = await getLoyaltyPlanList(selectedClinic);
        setState({
            ...state,
            currentPlan: res?.currentPlan,
            planList: res?.plans ?? []
        });
    }
    const getPointTotal = async () => {
        const res = await getPointsOverview(selectedClinic);
        setTotalPoints(res?.data?.totalPoints ?? 0);
    }
    return (
        <>
            <section className="middle-navbar px-30">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex flex-row">
                                <div className="nav-title-txt">Configuration</div>
                            </div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item"><NavLink className="nav-link" to="/subscription-plan">Subscription plan</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/payment-method">Payment method</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/billing-and-invoices">Billing & Invoices</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/campaigns">Campaigns</NavLink></li>
                            </ul>
                        </div>

                        <div className="d-flex gap-3 align-items-center">

                            <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                                setSelectedClinic(e.target.value);
                                sessionStorage.setItem('selectedClinic', e.target.value);
                            }}>
                                {
                                    clinics?.map((item: any) => {
                                        return (
                                            <option value={item.uniqueId}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                user?.role !== "Read Only" &&
                                <div>
                                    <button onClick={() => {
                                        navigate('/api-connection')
                                    }}><i className="bi bi-gear"></i></button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="configration-details-sec px-30 pt-4">
                <div className="px-3">
                    <div className="container">
                        <p className="text-muted fs-4">
                            Welcome to Xcare Loyalty Subscription Platform!
                        </p>
                        <p className="text-muted fs-7">
                            Unlock the power of data-driven marketing with our subscription plans tailored to meet your needs. As a token of appreciation for joining our platform, we're delighted to offer you bonus points upon subscription. These bonus points can be redeemed towards your marketing campaigns, giving you an instant boost in engagement and conversion.
                        </p>
                    </div>
                </div>
            </section>


            <section className="configration-details-sec px-30">
                <Plans
                    currentPlan={state?.currentPlan}
                    planList={state.planList}
                    handlePurchasePlan={handlePurchasePlan}
                    totalPoints={totalPoints}
                ></Plans>
            </section>
        </>);
}