import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './counter/counterSlice'
import dashboardConfigureReducer  from './dashboardConfigure/DashboardConfireSlice';
import loadingIndicatorReducer from './loadingIndicator/loadingIndicatorSlice';
import dashboardReducer  from './mainDashboard/mainDashboardSlice';
import userSlice from './user/userSlice'
import chartReducer from './chart/ChartSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userSlice,
    loading: loadingIndicatorReducer,
    configuration: dashboardConfigureReducer,
    dashboard: dashboardReducer,
    chart : chartReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch