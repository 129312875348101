import axios from "axios"
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { attachHeader } from "../utils/apiHandler";
import { commonApi } from "../helpers/api";

export const getGoogleAnalyticsAccounts = async (token: string) => {
    const response = await axios.get('https://analyticsadmin.googleapis.com/v1beta/accountSummaries', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const getGMBAccounts = async (token: string) => {
    const response = await axios.get('https://mybusinessaccountmanagement.googleapis.com/v1/accounts', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const getGMBLocations = async (token: string, accountId: string) => {
    const response = await axios.get(`
    https://mybusinessbusinessinformation.googleapis.com/v1/${accountId}/locations?pageSize=100&readMask=storeCode,regularHours,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const getGASessionsData = async (property: string, dateFilter: any, token: string, refreshToken: any) => {
    try {
        const response = await axios.post("https://analyticsdata.googleapis.com/v1beta/"+property+":runReport?alt=json", {
            "dimensions": [
                {
                    "name": "day"
                },
                {
                    "name": "isoYear"
                },
                {
                    "name": "month"
                }
            ],
            "metrics": [
                {
                    "name": "sessions"
                }
            ],
            "dateRanges": [
                dateFilter
            ]
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error: any) {
        if (error.response.data.error.code === 401) {
            refreshToken();
        }
        console.log(error.response);
    }
}

export const getGAUsersData = async (property: string, dateFilter: any, token: string, refreshToken: any) => {
    try {
        const response = await axios.post("https://analyticsdata.googleapis.com/v1beta/"+property+":runReport?alt=json", {
            "dimensions": [
                {
                    "name": "day"
                },
                {
                    "name": "isoYear"
                },
                {
                    "name": "month"
                }
            ],
            "metrics": [
                {
                    "name": "activeUsers"
                }
            ],
            "dateRanges": [
                dateFilter
            ]
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error: any) {
        if (error.response.data.error.code === 401) {
            refreshToken();
        }
        console.log(error.response);
    }
}

export const getGAConversionsData = async (property: string, dateFilter: any, token: string, refreshToken: any) => {
    try {
        const response = await axios.post("https://analyticsdata.googleapis.com/v1beta/"+property+":runReport?alt=json", {
            "dimensions": [
                {
                    "name": "day"
                },
                {
                    "name": "isoYear"
                },
                {
                    "name": "month"
                }
            ],
            "metrics": [
                {
                    "name": "conversions"
                }
            ],
            "dateRanges": [
                dateFilter
            ]
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error: any) {
        if (error.response.data.error.code === 401) {
            refreshToken();
        }
        console.log(error.response);
    }
}
export const getConversionsTotalData = async (property: string, dateFilter: any, token: string, refreshToken: any) => {
    try {
        const response = await axios.post("https://analyticsdata.googleapis.com/v1beta/"+property+":runReport?alt=json", {
            "metrics": [
                {
                    "name": "sessions"
                },
                {
                    "name": "totalUsers"
                },
                {
                    "name": "newUsers"
                },
                {
                    "name": "bounceRate"
                },
                {
                    "name": "eventCount"
                },
                {
                    "name": "conversions"
                },
                
            ],
            "metricAggregations":[
                "TOTAL"
            ],
            "dateRanges": [
                dateFilter
            ]
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error: any) {
        if (error.response.data.error.code === 401) {
            refreshToken();
        }
        console.log(error.response);
    }
}

export const getGoogleAdsAccounts = async (token: string) => {
    const response = await axios.get('https://googleads.googleapis.com/v17/customers:listAccessibleCustomers', {
        headers: {
            'Developer-Token': 'Rp1bTOZZ7-WD-tM1s8vfQA',
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const getGoogleAdsAccountsCustomer = async (token: string, accountId: string, body: any) => {
    const response = await axios.post(`https://googleads.googleapis.com/v17/${accountId}/googleAds:search`, body, {
        headers: {
            'Developer-Token': 'Rp1bTOZZ7-WD-tM1s8vfQA',
            Authorization: `Bearer ${token}`,
            'Login-Customer-Id': accountId?.split("/")[1]
        }
    });
    return response.data;
};

export const getAdsClickData = async (token: string, customerId: string, accountId: string, body: any, refreshToken: any) => {
    try {
        const response = await axios.post(`https://googleads.googleapis.com/v14/${accountId}/googleAds:search`, body, {
            headers: {
                'Developer-Token': 'Rp1bTOZZ7-WD-tM1s8vfQA',
                Authorization: `Bearer ${token}`,
                'Login-Customer-Id': customerId
            }
        });
        return response.data;
    } catch (error: any) {
        console.log(error);
        if (error.response.data.error.code === 401) {
            refreshToken();
        }
    }
};

export const getAnalyticsData = (uniqueId: any, query: string) => {
    const request: CommonApiOptions = {
      url: configs.API_URL + "googleanalytics/"+uniqueId+'/'+ query,
      method: METHOD_TYPE.GET,
      apiOptions: {
          headers: attachHeader(true),
          withCredentials: true
      }
    };
    return commonApi(request);
};

export const getAdsData = (uniqueId: any, query: string) => {
    const request: CommonApiOptions = {
      url: configs.API_URL + "googleads/"+uniqueId+'/'+ query,
      method: METHOD_TYPE.GET,
      apiOptions: {
          headers: attachHeader(true),
          withCredentials: true
      }
    };
    return commonApi(request);
};
  