import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hook";
import { getTreatmentReportData } from "../../services/report";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

export const ReportCategoryList = () => {
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [selectedClinic, setSelectedClinic] = useState(
    sessionStorage.getItem("selectedClinic") &&
      sessionStorage.getItem("selectedClinic") !== ""
      ? sessionStorage.getItem("selectedClinic")
      : clinics?.[0]?.uniqueId
  );
  const [categoryList, setCategoryList] = useState([]);
  const [categoryPendingList, setCategoryPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [selectedQuater, setSelectedQuater] = useState("thismonths");
  const [selectedType, setSelectedType] = useState("category");

  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }

  useEffect(() => {
    if (selectedClinic) {
      getData();
    }
  }, [selectedClinic, selectedQuater, selectedType, dateFilter]);

  const getData = async () => {
    setIsLoading(true);
    console.log("selectedClinic", selectedClinic);
    const categoies = await getTreatmentReportData(
      selectedType,
      selectedClinic,
      dateFilter?.startDate,
      dateFilter?.endDate
    );
    setCategoryList(categoies.category);
    setCategoryPendingList(categoies.category_pending);
    console.log("selected categoies", categoies);
    setIsLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <div className="container-box">
          <div className="mt-5 loder_in rounded text-center">
            <div
              className="spinner-border align-items-center"
              role="status"
            ></div>
            <div className="mt-2">Loading...</div>
          </div>
        </div>
      ) : (
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center m-2">
            <div>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
                value={selectedType}
              >
                <option value="category">Category</option>
                <option value="subcategory">Sub Category</option>
              </select>
            </div>
            <div className="d-flex">
              <div
                className="d-flex flex-column"
                style={{ marginTop: "22px", marginRight: "15px" }}
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="dashb-filters">
                    <ReactDatePicker
                      className="form-control col-2"
                      selected={moment(dateFilter?.startDate).toDate()}
                      onChange={(date: any) => {
                        setDateFilter({
                          startDate: moment(date)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          endDate: moment(date)
                            .endOf("month")
                            .format("YYYY-MM-DD"),
                        });
                      }}
                      showMonthYearPicker
                      dateFormat="MMM/yyyy"
                    />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setSelectedQuater(e.target.value);
                        switch (e.target.value) {
                          case "thismonths":
                            setDateFilter({
                              startDate: moment()
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              endDate: moment().format("YYYY-MM-DD"),
                            });
                            break;
                          case "1months":
                            setDateFilter({
                              startDate: moment()
                                .subtract(1, "months")
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              endDate: moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                            });
                            break;
                          case "3months":
                            setDateFilter({
                              startDate: moment()
                                .subtract(3, "months")
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              endDate: moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                            });
                            break;
                          case "6months":
                            setDateFilter({
                              startDate: moment()
                                .subtract(6, "months")
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              endDate: moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                            });
                            break;
                          case "9months":
                            setDateFilter({
                              startDate: moment()
                                .subtract(9, "months")
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              endDate: moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                            });
                            break;
                          case "12months":
                            setDateFilter({
                              startDate: moment()
                                .subtract(12, "months")
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              endDate: moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                            });
                            break;
                        }
                      }}
                      value={selectedQuater}
                    >
                      <option value="thismonths">This Month</option>
                      <option value="1months">Last Month</option>
                      <option value="3months">Last 3 Months</option>
                      <option value="6months">Last 6 Months</option>
                      <option value="9months">Last 9 Months</option>
                      <option value="12months">Last 12 Months</option>
                    </select>
                  </div>
                </div>
                <small className="pt-1 text-success">
                  From {dateFilter?.startDate} to {dateFilter?.endDate}
                </small>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedClinic}
                  onChange={(e) => {
                    setSelectedClinic(e.target.value);
                    sessionStorage.setItem("selectedClinic", e.target.value);
                  }}
                >
                  {clinics?.map((item: any) => {
                    return <option value={item.uniqueId}>{item.name}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
          <section className="client-table-details-sec px-30 pt-4">
            <div
              style={{
                fontWeight: "bold",
                // marginLeft: "50px",
                fontSize: "20px",
              }}
            >
              Case Acceptance Insights
            </div>
            <div className="container">
              <div className="row">
                <table
                  className="table"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Total Case Presented</th>
                      <th>Cases Accepted</th>
                      <th>% Acceptance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList?.map((item: any, index: any) => {
                      const isLastRow = index === categoryList.length - 1;
                      return (
                        <tr
                          key={index}
                          style={isLastRow ? { fontWeight: "bold" } : {}}
                        >
                          <td>{item?.title}</td>
                          <td>{item?.total_cases_presented}</td>
                          <td>{item?.cases_accepted}</td>
                          <td>
                            {isLastRow
                              ? "-"
                              : `${item?.acceptance_percentage} %`}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          {categoryPendingList?.length > 0 && (
            <section className="client-table-details-sec px-30 pt-4">
              <div
                style={{
                  fontWeight: "bold",
                  // marginLeft: "50px",
                  fontSize: "20px",
                }}
              >
                Pending Treatment Follow-Up Insights
              </div>
              <div className="container">
                <div className="row">
                  <table
                    className="table"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>Pending Treatments</th>
                        <th>Total Patients</th>
                        <th>Pending Plans</th>
                        <th>Potential Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryPendingList?.map((item: any, index: any) => {
                        const isLastRow =
                          index === categoryPendingList.length - 1;
                        return (
                          <tr style={isLastRow ? { fontWeight: "bold" } : {}}>
                            <td>{item?.title}</td>
                            <td>{item?.total_patients}</td>
                            <td>{item?.pending_plans}</td>
                            <td>$ {item?.potential_revenue}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};
