import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const roiCalcAdd = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "roi/add/",
    method: METHOD_TYPE.POST,
    body: {
      data: data,
      type: BODY_TYPE.RAW,
    },
    apiOptions: {
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const getAgency = (id: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "agencydetails/" + id + "/",
    method: METHOD_TYPE.GET,
    apiOptions: {
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const getROIList = (id: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "roi/"+id+"/list/",
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const roiAddThankYouNote = (data: any, id: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "addthankyounotes/"+id+"/",
    method: METHOD_TYPE.POST,
    body: {
      data: data,
      type: BODY_TYPE.RAW,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const deleteROICalc = (id: string, roiId: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "roi/"+id+"/list/?deleteId="+roiId,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}