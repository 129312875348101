import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getDashboardData = (
  clinicId: string,
  data: any,
  queryParams: string = ""
) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "overview/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.POST,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
  };
  return commonApi(request);
};

export const getReviewRatingDashboard = (
  clinicId: string,
  queryParams: string
) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "totalavgandcount/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getReviewGrowth = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "reviewgrowth/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getReviewList = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "reviewlist/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getReviewCountSource = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url:
      configs.API_URL +
      "sourcewiseavgcountandratingcount/" +
      clinicId +
      "/" +
      queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getDailyClicks = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url:
      configs.API_URL +
      "dailyclickscallsmetrics/" +
      clinicId +
      "/" +
      queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getSentiments = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "sentiment/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getEmailCampaigns = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "emailcampaign/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getCallRails = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "callrailcalls/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getCostByChannelAds = (
  clinicId: string,
  queryParams: string,
  data: any
) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "bychannel/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getCostByChannelByMonthAds = (
  clinicId: string,
  queryParams: string,
  data: any
) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "bymonth/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getLeadListTable = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "leadlist/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getAcquistionsListTable = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "leadlist2/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getSentimentListTable = (
  clinicId: string,
  queryParams: string
) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "sentimentscore/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};


export const getCallRailsCallDetails = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "callrailcallsdetails/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const updateCall = (clinicId: string, id: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "callupdate/" + clinicId + "/" + id + '/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const matchCallLeads = (clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "matchcalllead/" + clinicId + "/",
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const countInRevenue = (clinicId: string, id: any, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "leadupdate/" + clinicId + "/" + id + '/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getCallSummary = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "callsummary/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};

export const getAcquistionsNewListTable = (clinicId: string, queryParams: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "acqulist/" + clinicId + "/" + queryParams,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true,
    },
  };
  return commonApi(request);
};