import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../store/hook';
import { getPointsOverview } from '../../services/subscription';
import { numFormatter } from '../../helpers/currency';
import moment from 'moment';
function Invoices(props: any) {
    const user = useAppSelector((state) => state.user.user);
    const clinics = useAppSelector((state) => state.user.user.clinics);
    const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic') !== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
    const navigate = useNavigate();
    if (!selectedClinic && clinics?.[0]?.uniqueId) {
        setSelectedClinic(clinics?.[0]?.uniqueId);
    }
    const [invoiceList, setInvoiceList] = useState([]);
    const [totalPoints, setTotalPoints] = useState(0);
    useEffect(() => {
        if (selectedClinic !== undefined) {
            getPointTotal();
        }
    }, [selectedClinic]);

    const getPointTotal = async () => {
        const res = await getPointsOverview(selectedClinic);
        setTotalPoints(res?.data?.totalPoints ?? 0);
        setInvoiceList(res?.data?.points ?? []);
    }

    return (
        <>
            <section className="middle-navbar px-30">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex flex-row">
                                <div className="nav-title-txt">Configuration</div>
                            </div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item"><NavLink className="nav-link" to="/subscription-plan">Subscription plan</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/payment-method">Payment method</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/billing-and-invoices">Billing & Invoices</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/campaigns">Campaigns</NavLink></li>
                            </ul>
                        </div>

                        <div className="d-flex gap-3 align-items-center">

                            <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                                setSelectedClinic(e.target.value);
                                sessionStorage.setItem('selectedClinic', e.target.value);
                            }}>
                                {
                                    clinics?.map((item: any) => {
                                        return (
                                            <option value={item.uniqueId}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                user?.role !== "Read Only" &&
                                <div>
                                    <button onClick={() => {
                                        navigate('/api-connection')
                                    }}><i className="bi bi-gear"></i></button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>


            <section className="configration-details-sec px-30 pt-5">
                <section className="subscription-sec px-3">
                    <div className="container">
                        <div className='mb-3 d-flex justify-content-end'>
                            <h4>Total Points : {numFormatter.format(totalPoints)}</h4>
                        </div>
                        {invoiceList && invoiceList.length > 0 ?
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="advances-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Amount</th>
                                                        <th>Points</th>
                                                        <th>Description</th>
                                                        {/* <th>Campaign</th> */}
                                                        <th>Invoice</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        invoiceList.map((item: any, index: number) => {
                                                            return (
                                                                <tr key={index + index}>
                                                                    <td>
                                                                        <small>{moment(item?.created_at).format('YYYY MMM, DD')}</small>
                                                                    </td>
                                                                    <td>
                                                                        <small>${numFormatter.format(item?.amount)}</small>
                                                                    </td>
                                                                    <td className={`${item.type === 'Credited' ? 'text-success' : item.type === 'Debited' ? 'text-danger' : ''}`}>
                                                                        <small>{numFormatter.format(item?.points)}</small>
                                                                    </td>
                                                                    <td><small>{item?.description}</small></td>
                                                                    {/* <td><small>{item?.campaign?.title}</small></td> */}
                                                                    <td>
                                                                        {
                                                                            item?.receipt_url ?
                                                                            <a href={item.receipt_url} target='_blank' className="btn mx-1"><i className="bi bi-download"></i></a> :
                                                                            '-'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="alert alert-danger py-2 mt-4" role="alert">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="m-0">You don't have points added.</p>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </section>
        </>
    )
}
export default Invoices;
