import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/index'

// Define a type for the slice state
interface CounterState {
  isLoad: boolean
}

// Define the initial state using that type
const initialState: CounterState = {
  isLoad: false,
}

export const loadingIndicatorSlice = createSlice({
  name: 'loading',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loadingStart: (state) => {
      state.isLoad =true
    },
    loadingEnd: (state) => {
        state.isLoad = false;
    },
  },
})

export const { loadingStart, loadingEnd } = loadingIndicatorSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.loading.isLoad

export default loadingIndicatorSlice.reducer