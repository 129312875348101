import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../assets/images/dd-black.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { forgotPassword, forgotPasswordResetPassword, forgotPasswordVerifyCode } from '../../services/auth';
import { toast } from "react-toastify";
import { passwordValidator } from '../../utils/validators';


const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showCode, setShowCode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotKey, setForgotKey] = useState('');
    const { register, handleSubmit, setError, formState: { errors }, getValues } = useForm();
    const navigate = useNavigate();
    console.log(errors);
    const onSubmit = (data: any) => {
        setIsLoading(true);
        if (!showCode && !showPassword) {
            forgotPassword({ email: data.email }).then((success: any) => {
                setIsLoading(false)
                if (success && success.status && success.status.error === false) {
                    setShowPassword(false);
                    setShowCode(true);
                    setForgotKey(success?.forgotkey);
                }
            }).catch((err: any) => {
                setIsLoading(false);
                if (err && err.error === true) {
                    setError('email', { type: 'invalidemailpass', message: err.msg });
                } else {
                    setError('email', { type: 'someerr', message: 'Something went wrong.' });
                }
            });
        }
        if (showCode) {
            setIsLoading(true);
            forgotPasswordVerifyCode({
                code: Number(data?.code)
            }, forgotKey).then((success) => {
                setIsLoading(false);
                if (success && success?.status && success?.status?.error === false) {
                    setShowCode(false);
                    setShowPassword(true);
                }
            }).catch((err) => {
                setIsLoading(false);
                if (err && err.error === true) {
                    setError('code', { type: 'invalidemailpass', message: err.msg });
                } else {
                    setError('code', { type: 'someerr', message: 'Something went wrong.' });
                }
            })
        }
        if (showPassword) {
            setIsLoading(true);
            forgotPasswordResetPassword({
                password: data?.password,
                repassword: data?.repassword,
            }, forgotKey).then((success) => {
                setIsLoading(false);
                if (success && success?.status && success?.status?.error === false) {
                    toast.success(success?.status?.msg);
                    navigate('/login');
                }
            }).catch((err) => {
                setIsLoading(false);
                if (err && err.error === true) {
                    setError('password', { type: 'invalidemailpass', message: err.msg });
                } else {
                    setError('password', { type: 'someerr', message: 'Something went wrong.' });
                }
            })
        }
    };
    return (
        <>
            <div className="app login-container d-lg-flex min-vh-100 d-flex">
                <div className="form-signin">
                    <Helmet><title>Signin - xcarepro</title></Helmet>
                    <div className="card shadow-sm">

                        <div className="card-header bg-light px-4 py-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <img src={logo} alt="Logo" width="100px" />
                            </div>
                        </div>

                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="email">Email address: </label>
                                    <input type="text" className="form-control" placeholder="Email address"
                                        id="email"
                                        {...register("email", {
                                            required: true,
                                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })}
                                    />
                                    {
                                        (errors && errors.email && errors.email.type === "required") &&
                                        <div className="text-danger">Email is required.</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "pattern") &&
                                        <div className="text-danger">Email format is invalid.</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "invalidemailpass") &&
                                        //@ts-ignore
                                        <div className="text-danger">{errors.email.message}</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "someerr") &&
                                        //@ts-ignore
                                        <div className="text-danger">{errors.email.message}</div>
                                    }
                                    {
                                        (errors && errors.email && errors.email.type === "firebaseloginerr") &&
                                        //@ts-ignore
                                        <div className="text-danger">{errors.email.message}</div>
                                    }
                                </div>
                                {
                                    showCode &&
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="code">Code</label>
                                        <input type="text" className="form-control" id="code" placeholder="Code"
                                            {...register("code", { required: true, validate: (value) => { return !!value.trim() } })} />
                                        {
                                            (errors && errors.code && errors.code.type === "required") &&
                                            <div className="text-danger">Code is required.</div>
                                        }
                                        {
                                            (errors && errors.code && errors.code.type === "validate") &&
                                            <div className="text-danger">Code is required.</div>
                                        }
                                        {
                                            (errors && errors.code && errors.code.type === "invalidemailpass") &&
                                            //@ts-ignore
                                            <div className="text-danger">{errors.code.message}</div>
                                        }
                                    </div>
                                }
                                {
                                    showPassword &&
                                    <>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="password">New Password</label>
                                            <input type="password" className="form-control" id="password" placeholder="Password"
                                                {...register("password", { required: true, validate: (value) => {
                                                    return !!passwordValidator(value)
                                                } })} />
                                            {
                                                (errors && errors.password && errors.password.type === "required") &&
                                                <div className="text-danger">Password is required.</div>
                                            }
                                            {
                                                (errors && errors.password && errors.password.type === "validate") &&
                                                <div className="text-danger">Password must have at least one uppercase, one lowercase, one special character and one number with a minimum of 8 characters.</div>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="repassword">Confirm Password</label>
                                            <input type="password" className="form-control" id="repassword" placeholder="Password"
                                                {...register("repassword", { required: true, validate: (value) => {
                                                    return value === getValues('password')
                                                } })} />
                                            {
                                                (errors && errors.repassword && errors.repassword.type === "required") &&
                                                <div className="text-danger">Password is required.</div>
                                            }
                                            {
                                                (errors && errors.repassword && errors.repassword.type === "validate") &&
                                                <div className="text-danger">Password should match.</div>
                                            }
                                            {
                                                (errors && errors.email && errors.email.type === "invalidemailpass") &&
                                                //@ts-ignore
                                                <div className="text-danger">{errors.email.message}</div>
                                            }
                                        </div>
                                    </>
                                }
                                <div className="">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                            <NavLink to="/login" className="text-decoration-none link-danger">Sign In</NavLink>
                                        </span>
                                        <button id="signin-button" className="btn btn-primary" disabled={isLoading}>
                                            {
                                                isLoading &&
                                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                            }
                                            {
                                                showCode ? 'Verify' : showPassword ? 'Submit' : 'Send Code'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword;