import React, { Component, useState } from 'react';
import CardInfo from './CardInfo'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { roundNumberWithRoundingFactorDecimalFix } from '../../utils';
import { useAppSelector } from '../../store/hook';
import { buyLoyaltyPoints } from '../../services/subscription';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

const GetPlan = () => {
    const [isCheckOut, setIsCheckout] = useState(false);
    const user = useAppSelector((state) => state.user.user);
    const clinics = useAppSelector((state) => state.user.user.clinics);
    const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic') !== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const selectedPlan  = location.state.selectedPlan;
    if (!selectedPlan) {
        navigate('/subscription-plan');
    }
    const handleCreateSubscription = async () => {
        setLoading(true);
        const res = await buyLoyaltyPoints(selectedClinic, {
            cardId: card,
            planId: selectedPlan?.id,
            amount: selectedPlan?.amount,
            points: (Number(selectedPlan.totalPoints) + Number(selectedPlan?.additionalPoints)).toString()
        });
        setLoading(false);
        if (!res?.status?.error) {
            toast.success('Points added successfully');
            navigate('/subscription-plan-success', {
                replace: true
            });
        } else {
            navigate('/subscription-plan-failed', {
                replace: true
            });
        }
    }
    const handleProcessAndCheckout = () => {
        setIsCheckout(true);
    }
    const selectedCard = (item: any) => {
        setCard(item);
    }
    return (
        <section className="subscription-sec py-5">

                <div className="backtohome position-fixed">
                    <NavLink to="/subscription-plan" type="button" className="btn btn-sm btn-outline-secondary rounded-pill px-3">
                        <i className="bi bi-arrow-left-short"></i> back to plan
                    </NavLink>
                </div>

                <div className="container max-width-center">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="step-wizard">
                                <ul className="d-flex">
                                    <li className="active">
                                        <div id="step1" className="step-box">
                                            <div className="step"><i className="bi bi-bookmark-check"></i></div>
                                            <div className="title">Select plan</div>
                                        </div>
                                    </li>
                                    <li className="active">
                                        <div id="step2" className="step-box">
                                            <div className="step"><i className="bi bi-ui-checks-grid"></i></div>
                                            <div className="title">Select addons</div>
                                        </div>
                                    </li>
                                    <li className={isCheckOut ? "active" : ""}>
                                        <div id="step3" className="step-box">
                                            <div className="step"><i className="bi bi-credit-card-2-front"></i></div>
                                            <div className="title">Process to checkout</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {!isCheckOut && selectedPlan &&
                            <div className="col-8">
                                <div className="border p-4 border-1 rounded bg-light custom-card my-4 default-card">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {/* <small className="fw-medium text-muted">{item.title}</small> */}
                                            <div className="info-box fs-5 fw-medium">{selectedPlan.title}</div>
                                        </div>
                                        <div className="align-self-center">
                                            <div className="d-flex">
                                                <div className="pr-2 price-tag">
                                                    <span className="fs-6 pr-1 position-relative">$</span>
                                                    <span className="fw-medium fs-1">{selectedPlan.amount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="text-muted small align-self-center">You will get {selectedPlan.totalPoints} points {`${selectedPlan.additionalPoints && selectedPlan.additionalPoints !== 0 ?  `and additionally ${selectedPlan.additionalPoints} points` : ''}`}</div>
                                    </div>
                                </div>
                            </div>}
                        {
                            isCheckOut &&
                            <CardInfo
                                selectedCard={selectedCard}
                                selectedClinic={selectedClinic}
                            ></CardInfo>
                        }
                        <div className="col-4">
                            <div className="sidebar sticky-top p-4 border border-1 rounded bg-light mt-4 default-card">
                                <div className="fw-medium text-muted mb-2">
                                    Bill summary
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <ul className="list-group list-group-flush">
                                            {
                                                selectedPlan.amount &&
                                                <li className="list-group-item d-flex justify-content-between bg-transparent px-0 border-0">
                                                    <div className="align-self-right fw-medium ">Total</div>
                                                    <div className="text-end">
                                                        {/* <i className="d-block text-muted">Tax included</i> */}
                                                        <strong className="fs-5">${roundNumberWithRoundingFactorDecimalFix(selectedPlan.amount, 2)}</strong>
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {
                                    (isCheckOut) ?
                                        <button disabled={selectedPlan.amount <= 0  || !card || loading} className="btn btn-primary w-100 fw-medium mt-4"
                                            onClick={() => { handleCreateSubscription() }}
                                        >
                                            {loading && <Spinner size="sm"/>}
                                            {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                            {'Proceed to payment'}</button>
                                        :
                                        <button disabled={selectedPlan.amount <= 0} className="btn btn-primary w-100 fw-medium mt-4" onClick={() => { handleProcessAndCheckout() }}>
                                            {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                            {'Proceed to checkout'}</button>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default GetPlan;