import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hook";
import {
  getTreatmentReportData,
  getTreatmentReportRecallData,
} from "../../services/report";

export const ReportSubCategoryList = () => {
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [selectedClinic, setSelectedClinic] = useState(
    sessionStorage.getItem("selectedClinic") &&
      sessionStorage.getItem("selectedClinic") !== ""
      ? sessionStorage.getItem("selectedClinic")
      : clinics?.[0]?.uniqueId
  );
  const [selectedInterval, setSelectedInterval] = useState("recall");
  const [hygineList, setHygineList] = useState([]);
  const [overdueList, setOverdueList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }

  useEffect(() => {
    if (selectedClinic) {
      getData();
    }
  }, [selectedClinic, selectedInterval]);

  const getData = async () => {
    setIsLoading(true);
    console.log("selectedClinic", selectedClinic);
    const recallData = await getTreatmentReportRecallData(
      selectedClinic,
      selectedInterval
    );
    setHygineList(recallData.hygiene);
    setOverdueList(recallData.overdue);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="container-box">
          <div className="mt-5 loder_in rounded text-center">
            <div
              className="spinner-border align-items-center"
              role="status"
            ></div>
            <div className="mt-2">Loading...</div>
          </div>
        </div>
      ) : (
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-end align-items-center m-2">
            <div className="d-flex gap-3 align-items-center">
              <select
                className="form-select"
                aria-label="Default Interval Type"
                value={selectedInterval}
                onChange={(e) => {
                  setSelectedInterval(e.target.value);
                }}
              >
                <option value="recall">Recall</option>
                <option value="scaling">Scaling</option>
              </select>
              <select
                className="form-select"
                aria-label="Default select example"
                value={selectedClinic}
                onChange={(e) => {
                  setSelectedClinic(e.target.value);
                  sessionStorage.setItem("selectedClinic", e.target.value);
                }}
              >
                {clinics?.map((item: any) => {
                  return <option value={item.uniqueId}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
          <section className="client-table-details-sec px-30 pt-4">
            <div
              style={{
                fontWeight: "bold",
                // marginLeft: "50px",
                fontSize: "20px",
              }}
            >
              Hygiene Patient Insights
            </div>
            <div className="container">
              <div className="row">
                <table
                  className="table"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>Hygiene Frequency</th>
                      <th>3 months</th>
                      <th>6 months</th>
                      <th>+9 months</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hygineList?.map((item: any, index: any) => {
                      const isLastRow = index === hygineList.length - 1;
                      const isSecondLastRow = index === hygineList.length - 2;
                      return (
                        <tr>
                          <td>{item?.title}</td>
                          <td>{`${isSecondLastRow ? "$" : ""} ${
                            item["3_months"]
                          } ${isLastRow ? "%" : ""}`}</td>
                          <td>{`${isSecondLastRow ? "$" : ""} ${
                            item["6_months"]
                          } ${isLastRow ? "%" : ""}`}</td>
                          <td>{`${isSecondLastRow ? "$" : ""} ${
                            item["9_months"]
                          } ${isLastRow ? "%" : ""}`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          {overdueList?.length > 0 && (
            <section className="client-table-details-sec px-30 pt-4">
              <div
                style={{
                  fontWeight: "bold",
                  // marginLeft: "50px",
                  fontSize: "20px",
                }}
              >
                Overdue Patient Insights
              </div>
              <div className="container">
                <div className="row">
                  <table
                    className="table"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>Overdue Category</th>
                        <th>9-12 months</th>
                        <th>12-18 months</th>
                        <th>+18 months</th>
                      </tr>
                    </thead>
                    <tbody>
                      {overdueList?.map((item: any, index: any) => {
                        const isLastRow = index === overdueList.length - 1;
                        const isSecondLastRow =
                          index === overdueList.length - 2;
                        return (
                          <tr>
                            <td>{item?.title}</td>
                            <td>{`${isSecondLastRow ? "$" : ""} ${
                              item["9-12_months"]
                            } ${isLastRow ? "%" : ""}`}</td>
                            <td>{`${isSecondLastRow ? "$" : ""} ${
                              item["12-18_months"]
                            } ${isLastRow ? "%" : ""}`}</td>
                            <td>{`${isSecondLastRow ? "$" : ""} ${
                              item["18_plus_months"]
                            } ${isLastRow ? "%" : ""}`}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};
