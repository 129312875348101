import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getConfigListByClinic, updateConfigListByClinic } from "../../services/configurations";
import { useAppSelector } from "../../store/hook";
import { NavLink } from "react-router-dom";

export const ConfigureWidget = () => {
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic')!== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const [selectedConfigs, setSelectedConfigs] = useState<any>({});
  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }
  useEffect(() => {
    if (selectedClinic !== undefined) {
      _getConfigList();
    }
  }, [selectedClinic]);
  const _getConfigList = () => {
    getConfigListByClinic(selectedClinic).then((success) => {
      setSelectedConfigs(success?.config);
    }).catch((error) => {
      console.log(error);
    })
  }
  const updateConfigs = (updateObj: any) => {
    updateConfigListByClinic(selectedClinic, updateObj).then((success) => {
      if (success?.status?.error === false) {
        setSelectedConfigs(updateObj);
        toast.success(success?.status?.msg);
      } else {
        toast.error(success?.status?.msg);
      }
    }).catch((error) => {
      toast.error("Failed to update");
    });
  }

  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt">Configuration</div>
              </div>
              <ul className="nav nav-tabs">
                {
                  user?.role !== "Read Only" &&
                  <li className="nav-item"><NavLink className="nav-link" to="/api-connection">Api integration</NavLink></li>
                }
                <li className="nav-item"><NavLink className="nav-link" to="/configure-widget">Configure Widgets</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/user-assign">Users</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/goal-input">Goals and Inputs</NavLink></li>
              </ul>
            </div>

            <div className="d-flex gap-3 align-items-center">

              <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                setSelectedClinic(e.target.value);
                sessionStorage.setItem('selectedClinic', e.target.value);
              }}>
                {
                  clinics?.map((item: any) => {
                    return (
                      <option value={item.uniqueId}>{item.name}</option>
                    )
                  })
                }
              </select>

              {/* <div>
                <button><i className="bi bi-gear"></i></button>
                </div> */}

            </div>
          </div>
        </div>
      </section>


      <section className="configration-details-sec px-30 pt-5">
        <div className="container">

          <div className="row all-widget-configration">

            <div className="widget-config mb-4">
              <div className="d-flex justify-content-between mb-2">
                <h6>General widgets</h6>
                <div className="opacity-50">Show/hide widgets in analytic dashboard</div>
              </div>
              <div className="card">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Revenue</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.revenue} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          revenue: !selectedConfigs?.general?.revenue
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Spend</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.spend} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          spend: !selectedConfigs?.general?.spend
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Profit</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.profit} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          profit: !selectedConfigs?.general?.profit
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>ROI</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.roi} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          roi: !selectedConfigs?.general?.roi
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Leads</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.leads} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          leads: !selectedConfigs?.general?.leads
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Acquisitions</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.acquisitions} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          acquisitions: !selectedConfigs?.general?.acquisitions
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Cost/Lead</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.['cost/lead']} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          'cost/lead': !selectedConfigs?.general?.['cost/lead']
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Cost/Acquisition</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.general?.['cost/acquisitions']} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        general: {
                          ...selectedConfigs?.general,
                          'cost/acquisitions': !selectedConfigs?.general?.['cost/acquisitions']
                        }
                      });
                    }} /></div>
                  </li>

                </ul>
              </div>
            </div>


            <div className="widget-config mb-4">
              <div className="d-flex justify-content-between mb-2">
                <h6>Dynamic Widgets</h6>
                <div className="opacity-50">Show/hide widgets in analytic dashboard</div>
              </div>
              <div className="card">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Google Analytics</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.dynamic?.analytics} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        dynamic: {
                          ...selectedConfigs?.dynamic,
                          analytics: !selectedConfigs?.dynamic?.analytics
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Google adwords</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.dynamic?.adwords} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        dynamic: {
                          ...selectedConfigs?.dynamic,
                          adwords: !selectedConfigs?.dynamic?.adwords
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>GMB</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.dynamic?.gmb} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        dynamic: {
                          ...selectedConfigs?.dynamic,
                          gmb: !selectedConfigs?.dynamic?.gmb
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Social Impressions</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.dynamic?.socialImpression} onChange={(e) => {
                      updateConfigs({
                        ...selectedConfigs,
                        dynamic: {
                          ...selectedConfigs?.dynamic,
                          socialImpression: !selectedConfigs?.dynamic?.socialImpression
                        }
                      });
                    }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Customer Reviews</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.dynamic?.review}
                      onChange={(e) => {
                        updateConfigs({
                          ...selectedConfigs,
                          dynamic: {
                            ...selectedConfigs?.dynamic,
                            review: !selectedConfigs?.dynamic?.review
                          }
                        });
                      }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Customer Sentiment</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.dynamic?.sentiment}
                      onChange={(e) => {
                        updateConfigs({
                          ...selectedConfigs,
                          dynamic: {
                            ...selectedConfigs?.dynamic,
                            sentiment: !selectedConfigs?.dynamic?.sentiment
                          }
                        });
                      }} /></div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>Campaigns</div>
                    <div className="form-switch"><input disabled={user.role === "Read Only"} type="checkbox" id="custom-switch" className="form-check-input" checked={selectedConfigs?.dynamic?.campaigns}
                      onChange={(e) => {
                        updateConfigs({
                          ...selectedConfigs,
                          dynamic: {
                            ...selectedConfigs?.dynamic,
                            campaigns: !selectedConfigs?.dynamic?.campaigns
                          }
                        });
                      }} /></div>
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}