import { useEffect, useState } from "react"
import { useAppSelector } from "../../store/hook";
import { Link, useNavigate } from "react-router-dom";
import { formatter, numFormatter } from "../../helpers/currency";
import { deleteROICalc, getROIList } from "../../services/roicalc";
import moment from "moment";

export const ROIList = () => {
  const user = useAppSelector((state) => state.user.user);
  const [roiList, setROIList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.uniqueId) {
      getData();
    }
  }, [user]);
  const getData = async () => {
    const res = await getROIList(user?.uniqueId);
    setROIList(res.data);
  }
  const handleDeleteRoiCalculator = async (item: any) => {
    const res = await deleteROICalc(user?.uniqueId, item.roi_id);
    if (res?.status) {
      getData();
    }
  }
  return (
    <>
      {/* <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt">List</div>
              </div>

            </div>
          </div>
        </div>
      </section> */}

      <section className="client-table-details-sec px-30 pt-4">
        <div className="container">

          <div className="row">

            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>City</th>
                  <th>PMS</th>
                  <th>Engagement software used</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  roiList?.map((item: any) => {
                    let step2: any = {};
                    try {
                      step2 = JSON.parse(JSON.parse(item.step2));
                    } catch (error) {
                    }
                    return (
                      <tr>
                        <td>
                            {item?.name}
                        </td>
                        <td>
                            {item?.email}
                        </td>
                        <td>
                            {item?.city}
                        </td>
                        <td>
                            {item?.pms}
                        </td>
                        <td>
                          {step2?.patient_engagement_tool?.['1a']}
                        </td>
                        <td>
                          {moment(item?.created_at).format('YYYY-MM-DD LT')}
                        </td>
                        <td>
                          <div className="flex gap-2">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => {
                              navigate('/roi/calculator', {
                                  state: {
                                    ...item
                                  }
                              })
                            }}>{item?.status === "pending" ? 'Continue' : 'View'}</button>
                            <button type="button" className="btn btn-outline-secondary" onClick={() => {
                              const res = window.confirm('Are you sure to delete?');
                              if (res) {
                                handleDeleteRoiCalculator(item);
                              }
                            }}>Delete?</button>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  )
}