import configs from "../configs/apiConfigs";
import { CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getTreatmentReportData = (category: any, clinic_id: any, start_date: any, end_date: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + `treatmentreport/${clinic_id}/?type=${category}&startDate=${start_date}&endDate=${end_date}`,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}


export const getTreatmentReportRecallData = (clinic_id: any, interval: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + `recallreengage/${clinic_id}/?interval=${interval}`,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}