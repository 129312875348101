import React, { Component } from 'react';
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import configs from '../../configs/apiConfigs';
import { allowOnlyAlpha, alphanumericAndSpace } from '../../utils/validators';
import { addPaymentCards, getPaymentCards } from '../../services/subscription';


interface Props {
    selectedCard(Id: any): void;
    selectedClinic: any;
}
interface State {
    isValidCard: boolean;
    cards: any;
    isAddCard: boolean;
    cardNumberValidation: { isInvalid: boolean, msg: string };
    stripeReady: boolean;
    cardHolderName: string;
    cardHolderNameValidation: { isInvalid: boolean, msg: string };
    cardExpiryValidation: { isInvalid: boolean, msg: string };
    cardCvvValidation: { isInvalid: boolean, msg: string };
    cardError: { isInvalid: boolean, msg: string };
    billingResponse?: any;
    selectedCard: any;
    billingList: any;
    id: any;
    name: any;
    address1: any;
    address2: any;
    city: any;
    zipCode: any;
    state: any;
    country: any;
    selectedBillInfo: boolean;
    isEdit: boolean;
    countryList: any;
    stateList: any;
    cityList: any;
}
const stripePromise = loadStripe(configs.STRIPE_PUBLIC_KEY);
class CardInfo extends Component<Props, State>{
    cardNumberElement: any;
    constructor(props: any) {
        super(props);
        this.state = {
            isValidCard: true,
            cards: [],
            isAddCard: false,
            cardNumberValidation: { isInvalid: false, msg: '' },
            stripeReady: false,
            cardHolderName: '',
            cardHolderNameValidation: { isInvalid: false, msg: '' },
            cardExpiryValidation: { isInvalid: false, msg: '' },
            cardCvvValidation: { isInvalid: false, msg: '' },
            cardError: { isInvalid: false, msg: '' },
            selectedCard: '', billingList: [], id: '', isEdit: false,
            name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false,
            countryList: [],
            stateList: [],
            cityList: [],
        }
    }
    componentDidMount() {
        this.getCards();
    }
    createStripeToken = () => {
        try {
            stripePromise.then((success: Stripe | null) => {
                if (success) {
                    const stripe = success;
                    stripe.createToken(this.cardNumberElement, { name: this.state.cardHolderName }).then(async (success) => {
                        if (success.error && (success.error.code === "incomplete_number" || success.error.code === "invalid_number")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            
                        } else if (this.state.cardHolderName === "") {
                            this.setState({
                                cardHolderNameValidation: { isInvalid: true, msg: 'Please enter card holder name.' },
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            
                            return;
                        } else if (success.error && (success.error.code === "incomplete_expiry" ||
                            success.error.code === "invalid_expiry_year_past" ||
                            success.error.code === "invalid_expiry_year")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            
                        } else if (success.error && (success.error.code === "incomplete_cvc")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            
                        } else if (success.error) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: true, msg: success.error.message ? success.error.message : '' }
                            });
                            
                        } else if (success.token) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            // 
                            // this.props.addCard({ stripeToken: success.token.id });
                            const formData = new FormData();
                            formData.append('stripeToken', success.token.id);
                            
                            const res = await addPaymentCards({
                                stripeToken: success.token.id
                            }, this.props.selectedClinic);
                            if (res) {
                                this.setState({
                                    cardHolderName: '',
                                    cardNumberValidation: { isInvalid: false, msg: '' },
                                    cardCvvValidation: { isInvalid: false, msg: '' },
                                    cardHolderNameValidation: { isInvalid: false, msg: '' },
                                    cardExpiryValidation: { isInvalid: false, msg: '' },
                                    cardError: { isInvalid: false, msg: '' },
                                    isAddCard: false
                                });
                                this.getCards();
                            }
                        }
                    });
                }
            }, (error) => {
                

            });
        } catch (err) {
            
        }
    }
    cardNumberRef = (element: any) => {
        if (element) {
            this.cardNumberElement = element;
            this.setState({ stripeReady: true });
        }
    };

    selectCard = (Id: any) => {
        this.props.selectedCard(Id);
        this.setState({ selectedCard: Id });
    }

    getCards = async () => {
        const res = await getPaymentCards(this.props.selectedClinic);
        this.setState({
            billingResponse: {
                stripeCards: res?.cards
            },
        })
    }
    
    render() {
        return (
            <>
                <div className="col-8">
                    {
                        <>
                            {(!this.state.isValidCard) &&
                                <div className="my-4 border px-4 py-3 border-1 rounded d-flex bg-danger error-box justify-content-between">
                                    <div className="me-4">
                                        Selected card is not valid please add new card
                                    </div>
                                    <img src="../../assets/images/error-icon.svg" className="align-self-center" />
                                </div>
                            }
                            {(this.state.billingResponse && this.state.billingResponse.stripeCards.length > 0) &&
                                this.state.billingResponse.stripeCards.map((item: any) => {
                                    if (item.status === "Active") {
                                        return (
                                            <div key={item.id} className={item.id === this.state.selectedCard ? "default-card border-1 saved-card rounded d-flex p-4 justify-content-between border-1 border my-4" : "default-card border-1 rounded  d-flex p-4 justify-content-between border-1 border my-4"}>
                                                <div className="data-box">
                                                    <div className="card-no text-muted">
                                                        ●●●● ●●●● ●●●● {item.last4}
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="holder-name me-5">
                                                            <small className="text-muted">Card holder name</small>
                                                            <div>{item.name}</div>
                                                        </div>
                                                        <div className="ex-date">
                                                            <small className="text-muted">Expiration date </small>
                                                            <div>{item.expMonth}/{item.expYear}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-box text-center align-self-center">
                                                    <button onClick={() => { this.selectCard(item.id) }} className="btn btn-primary py-1 d-block rounded-pill my-3 px-4">Use this card</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })

                            }
                            {(this.state.billingResponse && this.state.billingResponse.stripeCards.length > 0) &&
                                <div className="text-end">
                                    <button onClick={() => { this.setState({ isAddCard: true }) }} className="btn bnt-link text-primary p-0">+ Add new card</button>
                                </div>
                            }

                        </>
                    }
                    {(this.state.billingResponse && this.state.billingResponse.stripeCards.length <= 0) &&
                        <div className="my-4 border px-4 py-3 border-1 rounded d-flex warning-box">
                            {/* <img src={ReportProblemIcon} className="align-self-center" /> */}
                            <div className="ml-4">
                                <div>You don't have any saved credit card, please add.</div>
                                <small>Your credit card details are encrypted</small>
                            </div>
                        </div>
                    }
                    {(this.state.isAddCard) &&
                        <Elements stripe={stripePromise}>
                            <div className="default-card card my-4 form-card">
                                <h5 className="card-header fs-6 fw-medium p-4 py-3">Credit card information</h5>
                                <div className="card-body px-4 py-3">
                                    {/* <form className="needs-validation px-3"> */}
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <label className="form-label">Card number</label>
                                            <div className="input-group has-validation">
                                                {/* <span className="input-group-text bg-white pr-00"><img src={require("../../assets/images/visa-icon.svg")} /></span> */}

                                                <CardNumberElement className={"form-control"}
                                                    options={{ showIcon: true, placeholder: "1234 1234 1234 1234" }}
                                                    onReady={this.cardNumberRef}></CardNumberElement>
                                                {
                                                    (this.state.cardNumberValidation.isInvalid) &&
                                                    <label className="text-danger">{this.state.cardNumberValidation.msg}</label>
                                                }
                                                {/* <input type="text" className="form-control border-start-0" placeholder="4242 4242 4242 4242" required />  */}
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label className="form-label">Name on card</label>
                                            <input type="text" className="form-control" placeholder="Name on card"
                                                onInput={(e: any) => {
                                                    if (allowOnlyAlpha(e.target.value)) {
                                                        this.setState({ cardHolderName: e.target.value });
                                                    } else if (e.target.value === "") {
                                                        this.setState({ cardHolderName: "" });
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    if (allowOnlyAlpha(e.target.value)) {
                                                        this.setState({ cardHolderName: e.target.value });
                                                    } else if (e.target.value === "") {
                                                        this.setState({ cardHolderName: "" });
                                                    }
                                                }} value={this.state.cardHolderName} required />
                                            <div className="invalid-feedback">
                                                Required.
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="cc-expiration" className="form-label">Expiration date (mm/yy)</label>
                                            <CardExpiryElement className="form-control"></CardExpiryElement>
                                            {/* <input type="text" className="form-control" id="cc-expiration" placeholder="" required /> */}
                                            <div className="invalid-feedback">
                                                Expiration date required
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="cc-expiration" className="form-label">CVV</label>
                                            <CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
                                            {/* <input type="text" className="form-control" id="cc-expiration" placeholder="" required /> */}
                                            <div className="invalid-feedback">
                                                CVV is required
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button disabled={!this.state.stripeReady || !this.state.isAddCard} onClick={(e) => {
                                                this.createStripeToken();
                                            }} className="btn btn-secondary">
                                                {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                                Save and continue</button>
                                        </div>
                                        <div className="text-muted fs-7"><i className="bi bi-info-circle"></i> XCare doesn't store your payment card information. We are use stripe as our payment processor.</div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </Elements>
                    }
                </div>
            </>
        )
    }
}

export default CardInfo;