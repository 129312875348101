import { useEffect, useRef, useState } from "react"
import { useAppSelector } from "../../store/hook";
import { fetchUserData, fetchUserProfile, updateUserProfile, updateUserProfileFormData } from "../../services/user";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addUserData, addUserProfile } from "../../store/user/userSlice";
import configs from "../../configs/apiConfigs";

export const Company = () => {
  const user = useAppSelector((state) => state.user.user);
  console.log("user", user);
  const [companyAddress, setCompanyAddress] = useState(user?.agency?.address);
  const dispatch = useDispatch();
  let uploadRef: any = useRef();

  useEffect(() => {
    if (user?.agency?.address) {
      setCompanyAddress(user?.agency?.address);
    }
  }, [user]);

  const fetchData = () => {
    fetchUserData().then((success) => {
        if (success) {
            dispatch(addUserData(success));
            fetchUserProfile(success.uniqueId).then((success: any) => {
                dispatch(addUserProfile(success));
            }).catch((error: any) => {
              console.log(error);
            });
        }
    })
        .catch((error) => {
            console.log('error', error);
        })
}

  const onSubmit = () => {
    updateUserProfile({ address: companyAddress, type: "address" }, user?.uniqueId).then((success) => {
      toast.success(success?.status?.msg);
      fetchData();
    }).catch((error: any) => {
      console.log(error);
      toast.error(error?.msg);
    })
  };

  const [previewFile, setPreviewFile] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
  const [imageError, setImageError] = useState('');

  const onFileChange = (event: any) => {
		const file = event.target.files[0];
		if (file && (file.type === "image/png" ||
			file.type === 'image/jpg' ||
			file.type == 'image/jpeg')) {
			setSelectedFile(event.target.files[0]);
			var reader: any = new FileReader();
			var url: any = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function (e: any) {
				//Initiate the JavaScript Image object.
				var image = new Image();
				//Set the Base64 string return from FileReader as source.
				image.src = e.target.result;
				//Validate the File Height and Width.
				image.onload = function () {
					var height = image.height;
					var width = image.width;
					if (height < 256 || width < 256) {
						setImageError('');
						return false;
					}
					setImageError('');
					setPreviewFile(reader.result);
					return true;
				};
			};
			return;
		} else {
			setImageError('');
		}
	};

  const handleUpload = () => {
    updateUserProfileFormData({
      type: "upload",
      uphoto: selectedFile
    }, user?.uniqueId).then((success) => {
      toast.success(success?.status?.msg);
      fetchData();
      setSelectedFile('');
      setImageError('');
    }).catch((error) => {
      console.log(error);
    })
  }
  console.log(imageError);
  return (
      <>
        <section className="profile-details-sec px-30 pt-5">
            <div className="container">
               <div className="row mb-5">
                  <div className="d-flex mb-3 pb-2 border-bottom px-0">
                      <h6 className="fs-5">Company details</h6>
                  </div>

                  <div className="row g-3">
                    <div className="col-4"> 
                      <input disabled={user.role === "Read Only"} type="text" className="form-control" id="" placeholder="Company address" value={companyAddress} 
                        onChange={(e) => {
                          setCompanyAddress(e.target.value);
                        }}/>
                    </div>
                    <div className="col-auto gap-2 d-flex">
                      <button className="btn btn-primary" disabled={companyAddress?.trim()?.length === 0 || user.role === "Read Only"} type="button" onClick={onSubmit}>Update</button>
                      {/* <button className="btn btn btn-outline-secondary" type="button">Cancel</button> */}
                    </div>
                  </div>
                </div>

                <div className="company-logo-section gap-4 d-flex align-items-center">
                  <div>
                    <img src={previewFile !== '' ? previewFile : user?.agency?.logo ? configs.IMAGE_URL + 'agency/' + user?.agency?.logo?.name + '-original.' + user?.agency?.logo?.type : require("../../assets/images/placeholder.png")} alt="" width="140px" height="140px" />
                  </div>
                  <div className="company-logo-action">
                        <div>Upload logo image</div> 
                        <input disabled={user.role === "Read Only"} type="file" ref={uploadRef} style={{display: "none"}} onChange={onFileChange}/>
                        <div className="my-2 gap-2 d-flex">
                          {
                            selectedFile ? 
                              <button disabled={user.role === "Read Only"} className="btn btn-primary" type="button" onClick={() => {
                                handleUpload();
                              }}>Save</button>
                            :
                              <button disabled={user.role === "Read Only"} className="btn btn-primary" type="button" onClick={() => {
                                uploadRef.current.click();
                              }}>Upload</button>

                          }
                          <button disabled={user.role === "Read Only"} className="btn btn btn-outline-secondary" type="button" onClick={() => {
                            setSelectedFile('');
                            setPreviewFile('');
                            uploadRef.current.value = '';
                          }}>Reset</button>
                        </div>
                        {
                          imageError && imageError !== '' &&
                          <div className="is-invalid">Required</div>
                        }
                        {/* <div>Upload logo image information or suggestions</div> */}
                  </div>
                </div>

              </div>
        </section>
      </>
  )
}