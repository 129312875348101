import { NavLink, Outlet } from "react-router-dom";

export const ReportMainPage = () => {
  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <div className="d-flex flex-row justify-content-between w-100">
                <div className="nav-title-txt">Practice Insights</div>
              </div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/report/treatmentplans">
                    Treatment Plans
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/report/recall">
                    Recall & Re-engage
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/report/sub-sub-category">
                    SubSubCategory
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Outlet />
    </>
  );
};
