import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const fetchUserData = () => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "agencydata/",
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const getAccountUsersList = (uniqueId: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "getuser/"+uniqueId+'/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const addAccountUser = (data: any, uniqueId: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "adduser/"+uniqueId+'/',
    method: METHOD_TYPE.POST,
    body: {
        type: BODY_TYPE.RAW,
        data: data
    },
    apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
    }
  };
  return commonApi(request);
}

export const deleteAccountUser = (userUniqueId: any, uniqueId: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "deleteuser/"+uniqueId+'/'+userUniqueId+'/',
    method: METHOD_TYPE.GET,
    apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
    }
  };
  return commonApi(request);
}

export const fetchUserProfile = (uniqueId: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "profiledata/"+uniqueId+'/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const updateUserProfile = (data: any,uniqueId: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "updateprofile/"+uniqueId+'/',
    method: METHOD_TYPE.POST,
    body: {
      data: data,
      type: BODY_TYPE.RAW,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}

export const updateUserProfileFormData = (data: any,uniqueId: string) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "updateprofile/"+uniqueId+'/',
    method: METHOD_TYPE.POST,
    body: {
      data: data,
      type: BODY_TYPE.FORM_DATA,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}
