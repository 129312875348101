/**
 * 
 * @param givenNumber - any number
 * @param roundingFactor must be positive integer
 */
export const roundNumberWithRoundingFactorDecimalFix = (givenNumber: any, roundingFactor: number) => {
    if (isNaN(givenNumber)) {
        return '';
    } else if (isNaN(roundingFactor)) {
        return '';
    }
    let roundBy: string = "1";
    for (let start = 0; start < Math.floor(roundingFactor); start++) {
        roundBy += "0";
    }
    return Number(Math.round((Number(givenNumber) + Number.EPSILON) * Number(roundBy)) / Number(roundBy)).toFixed(roundingFactor);
}

export function toDataUrl(url: string, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}