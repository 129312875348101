import { useEffect, useMemo, useState } from "react"
import { getReviewCountSource, getReviewList, getReviewRatingDashboard } from "../../services/newdashboard";
import { useAppSelector } from "../../store/hook";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Table } from "antd";
import { uniqBy } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ReviewDetailDashboard = () => {
  const [searchParams] = useSearchParams();
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [dashboardData, setDashboardData] = useState<any>({});
  const [selectedClinic, setSelectedClinic] = useState(searchParams.get('clinic') ? searchParams.get('clinic') : sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic')!== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const clinicData = clinics?.find((item: any) => item.uniqueId === selectedClinic);
  const [reviewList, setReviewList] = useState([]);
  const [reviewRatingData, setReviewRatingData] = useState<any>({});
  const [reviewSourceData, setReviewSourceData] = useState<any>([]);
  const [reviewSourceChartData, setReviewSourceChartData] = useState<any>({
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      elements: {
        bar: {
          borderWidth: 2,
          barPercentage: 0.4
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: false,
          text: ""
        }
      },
      scales: {
        x: {
          ticks: {
            precision: 0,
            autoSkip: false,
            beginAtZero: true,
          }
        },
        y: {
          ticks: {
            precision: 0,
            autoSkip: false,
            beginAtZero: true,
          }
        },
      }
    }
  });
  const navigate = useNavigate();
  const month = useMemo(() => {
    let retLabel = 0;
    switch (searchParams.get('filter')) {
      case 'thismonths':
        retLabel = 0;
        break;
      case '1months':
        retLabel = 1;
        break;
      case '3months':
        retLabel = 3;
        break;
      case '6months':
        retLabel = 6;
        break;
      case '9months':
        retLabel = 9;
        break;
      case '12months':
        retLabel = 12;
        break;
    }
    return retLabel;
  }, [searchParams]);
  const [dateFilter, setDateFilter] = useState({
    startDate: month ? moment().subtract(month, 'months').startOf("month").format("YYYY-MM-DD") : moment().startOf("month").format("YYYY-MM-DD"),
    endDate: month ? moment().subtract(1, 'month').endOf("month").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
  });
  const [selectedQuater, setSelectedQuater] = useState(searchParams.get('filter') ? (searchParams.get('filter') ?? 'thismonths') : 'thismonths');
  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }
  useEffect(() => {
    if (selectedClinic !== undefined) {
      getData();
    }
  }, [selectedClinic, dateFilter]);
  const getData = () => {
    getReviewRatingDashboard(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`).then((success) => {
      setReviewRatingData(success);
    });
    getReviewCountSource(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`).then((success) => {
      const data: any = [];
      Object.keys(success).forEach((key) => {
        if (key !== 'status') {
          data.push(success[key]);
        }
      });
      setReviewSourceData(data);

      const labels: any = [];
      const datasets: any = [
        {
          label: "1",
          data: [],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)"
        },
        {
          label: "2",
          data: [],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)"
        },
        {
          label: "3",
          data: [],
          borderColor: "rgb(53, 235, 77)",
          backgroundColor: "rgba(53, 235, 77, 0.5)"
        },
        {
          label: "4",
          data: [],
          borderColor: "rgb(150, 53, 235)",
          backgroundColor: "rgba(150, 53, 235, 0.5)"
        },
        {
          label: "5",
          data: [],
          borderColor: "rgb(205, 235, 53)",
          backgroundColor: "rgba(205, 235, 53, 0.5)"
        }
      ]
      for (const item of data) {
        labels.push(item.source);
        if (item?.rating?.["1"]) {
          datasets[0].data = [...datasets[0].data, Number(item?.rating?.["1"])]
        } else {
          datasets[0].data = [...datasets[0].data, 0]
        }

        if (item?.rating?.["2"]) {
          datasets[1].data = [...datasets[1].data, Number(item?.rating?.["2"])]
        } else {
          datasets[1].data = [...datasets[1].data, 0]
        }

        if (item?.rating?.["3"]) {
          datasets[2].data = [...datasets[2].data, Number(item?.rating?.["3"])]
        } else {
          datasets[2].data = [...datasets[2].data, 0]
        }

        if (item?.rating?.["4"]) {
          datasets[3].data = [...datasets[3].data, Number(item?.rating?.["4"])]
        } else {
          datasets[3].data = [...datasets[3].data, 0]
        }

        if (item?.rating?.["5"]) {
          datasets[4].data = [...datasets[4].data, Number(item?.rating?.["5"])]
        } else {
          datasets[4].data = [...datasets[4].data, 0]
        }
      }
      setReviewSourceChartData({
        ...reviewSourceChartData,
        data: {
          labels: labels,
          datasets: datasets
        },
      })
    });
    getReviewList(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`).then((success) => {
      setReviewList(success?.reviews);
    });
  };

  const filterLabel = useMemo(() => {
    let retLabel = '';
    switch (selectedQuater) {
      case 'thismonths':
        retLabel = 'This month';
        break;
      case '1months':
        retLabel = 'Last month';
        break;
      case '3months':
        retLabel = 'Last 3 months';
        break;
      case '6months':
        retLabel = 'Last 6 months';
        break;
      case '9months':
        retLabel = 'Last 9 months';
        break;
      case '12months':
        retLabel = 'Last 12 months';
        break;
    }
    return retLabel;
  }, [selectedQuater]);

  const sourceFilters = uniqBy(reviewList?.map((item: any) => ({ text: item.source, value: item.source })), 'value');

  const sourceIcon = (text: any) => {
    if (text === 'google') {
      return (<i className="bi bi-google"></i>);
    } else if (text === 'facebook') {
      return (<i className="bi bi-facebook"></i>);
    } else if (text === 'instagram') {
      return (<i className="bi bi-instagram"></i>);
    } else if (text?.toLowerCase() === 'xcare') {
      return (<img src={require('../../assets/images/xcare-api-icon.png')} width={14} height={14}></img>);
    }
    return ( <p>{text}</p>)
  }

  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'date',
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value: string, record: any) => record.source.indexOf(value) === 0,
      sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
      // sortDirections: ['descend'],
      render: (text: any) => {
        return moment(text).format("YYYY-MM-DD")
      },
      width: '10%'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      filters: sourceFilters,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value: string, record: any) => record.source.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.source.length - b.source.length,
      // sortDirections: ['descend'],
      render: (text: any) => {
        return sourceIcon(text);
      },
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => a.rating - b.rating,
    },
    {
      title: 'Review Text',
      dataIndex: 'reviewText',
    },
    {
      title: 'Name',
      dataIndex: 'FullName',
    },
  ];

  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  return (
    <>
      <section className="middle-navbar py-4 px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <button className="me-2" onClick={() => {
                  let url = '/newdashboard?';
                  if (searchParams.get('clinic')) {
                    url += 'clinic=' + searchParams.get('clinic');
                  }
                  if (searchParams.get('filter')) {
                    url += '&filter=' + searchParams.get('filter');
                  }
                  navigate(url)
                }}><i className="bi bi-arrow-left"></i></button>
                <div className="nav-title-txt m-0">Review Detail</div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="d-flex gap-3 align-items-center">
                <div className="dashb-filters">
                  <ReactDatePicker
                    className="form-control col-2"
                    selected={moment(dateFilter?.startDate).toDate()}
                    onChange={(date: any) => {
                      setDateFilter({
                        startDate: moment(date).startOf("month").format("YYYY-MM-DD"),
                        endDate: moment(date).endOf("month").format("YYYY-MM-DD")
                      });
                    }}
                    showMonthYearPicker
                    dateFormat="MMM/yyyy"
                  />
                  <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    setSelectedQuater(e.target.value);
                    switch (e.target.value) {
                      case 'thismonths':
                        setDateFilter({
                          startDate: moment().startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().format("YYYY-MM-DD")
                        });
                        break;
                      case '1months':
                        setDateFilter({
                          startDate: moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '3months':
                        setDateFilter({
                          startDate: moment().subtract(3, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '6months':
                        setDateFilter({
                          startDate: moment().subtract(6, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '9months':
                        setDateFilter({
                          startDate: moment().subtract(9, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '12months':
                        setDateFilter({
                          startDate: moment().subtract(12, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                    }
                  }} value={selectedQuater}>
                    <option value="thismonths">This Month</option>
                    <option value="1months">Last Month</option>
                    <option value="3months">Last 3 Months</option>
                    <option value="6months">Last 6 Months</option>
                    <option value="9months">Last 9 Months</option>
                    <option value="12months">Last 12 Months</option>
                  </select>
                </div>

                <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                  setSelectedClinic(e.target.value);
                  sessionStorage.setItem('selectedClinic', e.target.value);
                }}>
                  {
                    clinics?.map((item: any) => {
                      return (
                        <option value={item.uniqueId}>{item.name}</option>
                      )
                    })
                  }
                </select>

                {
                user?.role !== "Read Only" &&
                <div>
                  <button onClick={() => {
                    navigate('/api-connection')
                  }}><i className="bi bi-gear"></i></button>
                </div>
              }
              </div>
              <small className="pt-1 text-success">From {dateFilter?.startDate} to {dateFilter?.endDate}</small>
            </div>
          </div>
        </div>
      </section>

      <section className="graphs px-30 pt-5 pb-5">
        <div className="container">
          <div className="row">

            <div className="col-12 mb-5">
              <div className="card reviews-section">
                <div className="card-header d-flex justify-content-between align-items-center py-3">
                  <h6 className="m-0">Reviews</h6>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="graph-header d-flex justify-content-between align-items-center pb-2 mb-2">
                        <div>Review summary</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="avg-reviewbox">
                            <div className="avg-title text-white">Average Star Rating</div>
                            <div className="avg-rating text-white">{!isNaN(reviewRatingData?.Avgrating) ? Number(reviewRatingData?.Avgrating).toFixed(2) : '-'}</div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="total-reviewbox">
                            <div className="total-title">New / Total Reviews</div>
                            <div className="total-number">{reviewRatingData?.total ? `${reviewRatingData?.total}/${reviewRatingData?.all?.total}`  : '-'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="graph-header d-flex justify-content-between align-items-center pb-2 mb-2">
                        <div>Sources</div>
                      </div>
                      <div className="row">
                        <div className="col-12 w-100">
                          <div className="graph-layout">
                            {
                              reviewSourceChartData?.data?.labels?.length > 0 &&
                              <Bar width={'800px'} height={'200px'} options={reviewSourceChartData?.options} data={reviewSourceChartData?.data} />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 w-100">
                      <div className="graph-header d-flex justify-content-between align-items-center mb-2">
                        <h6>Top reviews</h6>
                      </div>
                      <div>
                        {
                          reviewSourceData?.length > 0 &&
                          <table className="table user-table">
                            <thead>
                              <tr>
                                <th>Source</th>
                                <th>New / Total Reviews</th>
                                <th>Ratings</th>
                                <th style={{width: '100px'}}><i className="bi bi-star-fill"></i></th>
                                <th style={{width: '100px'}}>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                </th>
                                <th style={{width: '100px'}}>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                </th>
                                <th style={{width: '100px'}}>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                </th>
                                <th style={{width: '100px'}}>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                reviewSourceData?.map((item: any) => {
                                  return (<tr>
                                    <td>
                                      {sourceIcon(item?.source)}
                                    </td>
                                    <td>{item?.total}/{item?.finalTotal}</td>
                                    <td>{Number(item?.Avgrating).toFixed(2)}</td>
                                    <td>
                                      {item.rating['1'] ?? '-'}
                                    </td>
                                    <td>
                                      {item.rating['2'] ?? '-'}
                                    </td>
                                    <td>
                                      {item.rating['3'] ?? '-'}
                                    </td>
                                    <td>
                                      {item.rating['4'] ?? '-'}
                                    </td>
                                    <td>
                                      {item.rating['5'] ?? '-'}
                                    </td>
                                  </tr>)
                                })
                              }
                            </tbody>
                          </table>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="px-30 pb-5">
        <div className="container">
          <div className="row">

            <div className="col-12 mb-5">
              <div className="card reviews-section">
                <div className="card-header d-flex justify-content-between align-items-center py-3">
                  <h6 className="m-0">Reviews</h6>
                </div>
                <div className="client-table-details-sec card-body">
                  <div className="row">

                    <Table className="p-0" columns={columns} dataSource={reviewList} />

                    {/* <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Source</th>
                          <th>Rating</th>
                          <th>Review</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          reviewList?.map((item: any) => {
                            return (
                              <tr>
                                <td>{item?.date}</td>
                                <td>{item?.source}</td>
                                <td>{item?.rating}</td>
                                <td>{item?.reviewText}</td>
                                <td>{item?.FullName}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}