import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../store/hook";
import { getCallRailAccountList, getCallRailCompanyList, getCallRailConfigs, saveCallRailConfigs, saveGoogleConfigs } from "../../services/configurations";
import { toast } from "react-toastify";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { fetchUserData, fetchUserProfile } from "../../services/user";
import { useDispatch } from "react-redux";
import { addUserData, addUserProfile } from "../../store/user/userSlice";
import { Button, Form, Modal } from "react-bootstrap";
import { getGMBAccounts, getGMBLocations, getGoogleAdsAccounts, getGoogleAdsAccountsCustomer, getGoogleAnalyticsAccounts } from "../../services/google";
import { cloneDeep } from "lodash";

export const APIConnection = () => {
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [callRailAccount, setCallRailAccount] = useState('');
  const [callRailCompany, setCallRailCompany] = useState('');
  const [callRailAccountList, setCallRailAccountList] = useState([]);
  const [callRailCompanyList, setCallRailCompanyList] = useState([]);
  const [configs, setConfigs] = useState<any>({});
  const [mapSource, setMapSource] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedClinic, setSelectedClinic] = useState(searchParams.get('clinic') ? searchParams.get('clinic') : sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic')!== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const [trustHorizonAPIKey, setTrustHorizonAPIKey] = useState('');
  const [showGAAccountSelect, setShowGAAccountSelect] = useState(false);
  const [showGMBAccountSelect, setShowGMBAccountSelect] = useState(false);
  const [showGAdsAccountSelect, setShowGAdsAccountSelect] = useState(false);
  const [tokenMetadata, setTokenMetadata] = useState({});
  const clinicFromUrl = decodeURIComponent(searchParams.get('state') ?? '')?.split("-")?.[0];
  const type = decodeURIComponent(searchParams.get('state') ?? '')?.split("-")?.[1];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedClinicData = useMemo(() => {
    return clinics?.find((item: any) => item.uniqueId == selectedClinic);
  }, [selectedClinic, user]);
  useEffect(() => {
    if (selectedClinicData?.trustH?.key) {
      setTrustHorizonAPIKey(selectedClinicData?.trustH?.key);
    } else {
      setTrustHorizonAPIKey('');
    }
  }, [selectedClinicData]);
  useEffect(() => {
    if (user?.client_id && searchParams.get('code') && searchParams.get('code') !== '') {
      setSelectedClinic(clinicFromUrl);
      axios.post("https://www.googleapis.com/oauth2/v4/token", {
        code: searchParams.get('code'),
        client_id: user?.client_id,
        client_secret: user?.client_secret,
        redirect_uri: `${window.location.origin}/api-connection`,
        grant_type: "authorization_code",
      }).then((success) => {
        setTokenMetadata(success.data);
        if (type === 'ga') {
          setShowGAAccountSelect(true);
        } else if (type === 'gmb') {
          setShowGMBAccountSelect(true);
        } else if (type === 'gads') {
          setShowGAdsAccountSelect(true);
        } else {
          saveGoogleToken(success.data);
        }
      }).catch((error) => {
        toast.error("Error in connecting to google");
      });
    }
  }, [searchParams.get('code'), user?.client_id]);

  const saveGoogleToken = (info: any) => {
    saveGoogleConfigs(user?.uniqueId, {
      data: info
    }, clinicFromUrl, type).then((success) => {
      toast.success(success?.status?.msg);
      fetchData();
      setShowGAAccountSelect(false);
      setShowGMBAccountSelect(false);
      setShowGAdsAccountSelect(false);
      setTokenMetadata({});
    }).catch((error) => {
      toast.error("Error in connecting to google");
    })
  };

  if (!selectedClinic && clinics?.[0]?.uniqueId && !clinicFromUrl) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }
  useEffect(() => {
    if (selectedClinic !== undefined) {
      getConfigs();
      getCallRailAccountList(user?.uniqueId).then((success) => {
        setCallRailAccountList(success?.accounts ?? []);
      }).catch((error) => {
        console.log(error);
      })
    }
  }, [selectedClinic]);

  const fetchData = () => {
    fetchUserData().then((success) => {
      if (success) {
        dispatch(addUserData(success));
        fetchUserProfile(success.uniqueId).then((success: any) => {
          dispatch(addUserProfile(success));
        }).catch((error: any) => {
          console.log(error);
        });
      }
    })
      .catch((error) => {
        console.log('error', error);
      })
  }

  const callCompanyList = (numericId: string) => {
    getCallRailCompanyList(user?.uniqueId, numericId).then((success) => {
      setCallRailCompanyList(success?.companies ?? []);
    }).catch((error) => {
      console.log(error);
    })
  };

  const getConfigs = () => {
    getCallRailConfigs(user?.uniqueId, selectedClinic).then((success) => {
      setCallRailAccount(success?.data?.accountId);
      setCallRailCompany(success?.data?.companyId);
      setConfigs(success?.data?.sourceAndNumber);
      callCompanyList(success?.data?.accountId);
    }).catch((error) => {
      console.log(error);
    });
  }


  const handleCallRailRemove = (configs: any) => {
    saveCallRailConfigs(user?.uniqueId, {
      data: {
        "accountId": callRailAccount,
        "companyId": callRailCompany,
        "sourceAndNumber": configs
      }
    }, selectedClinic).then((success) => {
      if (!success?.status?.error) toast.success(success?.status?.msg);
      else toast.error(success?.status?.msg);
      getConfigs()
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleCallRailAdd = () => {
    saveCallRailConfigs(user?.uniqueId, {
      data: {
        "accountId": callRailAccount,
        "companyId": callRailCompany,
        "sourceAndNumber": {
          ...configs,
          [mapSource]: trackingNumber
        }
      }
    }, selectedClinic).then((success) => {
      if (!success?.status?.error) toast.success(success?.status?.msg);
      else toast.error(success?.status?.msg);
      getConfigs()
    }).catch((error) => {
      console.log(error);
    });
    setMapSource('');
    setTrackingNumber('');
  };

  /*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
  function oauthSignIn(type = 'ga') {
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    let scope = 'https://www.googleapis.com/auth/analytics.readonly';
    if (type === 'gmb') {
      scope = 'https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage';
    } else if (type === 'gads') {
      scope = 'https://www.googleapis.com/auth/adwords';
    }

    // Parameters to pass to OAuth 2.0 endpoint.
    var params: any = {
      'client_id': user?.client_id,
      'redirect_uri': `${window.location.origin}/api-connection`,
      'response_type': 'code',
      'access_type': 'offline',
      'scope': scope,
      'include_granted_scopes': 'true',
      'state': selectedClinic + '-' + type,
      "prompt": "consent"
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }

  const handleGoogleAnalyticsConnect = (type: string, disconnect = false) => {
    if (disconnect) {
      const token = selectedClinicData?.[type]?.refresh_token ?? '';
      axios.post("https://oauth2.googleapis.com/revoke?token=" + token, {}).then((success) => {
        saveGoogleConfigs(user?.uniqueId, {
          data: {}
        }, selectedClinic, type).then((success) => {
          toast.success(success?.status?.msg);
          fetchData();
        }).catch((error) => {
          toast.error("Error in disconnect to google");
        })
      }).catch((error) => {
        toast.error("Error in disconnect to google");
        saveGoogleConfigs(user?.uniqueId, {
          data: {}
        }, selectedClinic, type).then((success) => {
          toast.success(success?.status?.msg);
          fetchData();
        }).catch((error) => {
          toast.error("Error in disconnect to google");
        })
      });
    } else {
      oauthSignIn(type);
    }
  };

  const handleConnectTrustHorizon = (disconnect = false) => {
    saveGoogleConfigs(user?.uniqueId, {
      data: {
        key: disconnect ? undefined : trustHorizonAPIKey
      }
    }, selectedClinic, 'trustH').then((success) => {
      toast.success(success?.status?.msg);
      fetchData();
    }).catch((error) => {
      toast.error("Error in connecting to trust horizon");
    });
  }

  const hideGAAccountSelect = () => {
    setShowGAAccountSelect(false);
    setTokenMetadata({});
  };

  const hideGMBAccountSelect = () => {
    setShowGMBAccountSelect(false);
    setTokenMetadata({});
  };

  const hideGAdsAccountSelect = () => {
    setShowGMBAccountSelect(false);
    setTokenMetadata({});
  };

  const handleSubmitGASelect = (selected: any) => {
    const cloned: any = cloneDeep(tokenMetadata);
    cloned.account = selected;
    saveGoogleToken(cloned);
  };

  const handleSubmitGAdsSelect = (selected: any) => {
    const cloned: any = cloneDeep(tokenMetadata);
    cloned.account = selected;
    saveGoogleToken(cloned);
  };

  const handleSubmitGMBSelect = (selected: any) => {
    const cloned: any = cloneDeep(tokenMetadata);
    cloned.account = selected;
    saveGoogleToken(cloned);
  };

  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  return (
    <>

      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt">Configuration</div>
              </div>
              <ul className="nav nav-tabs">
                {
                  user?.role !== "Read Only" &&
                  <li className="nav-item"><NavLink className="nav-link" to="/api-connection">Api integration</NavLink></li>
                }
                <li className="nav-item"><NavLink className="nav-link" to="/configure-widget">Configure Widgets</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/user-assign">Users</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/goal-input">Goals and Inputs</NavLink></li>
              </ul>
            </div>

            <div className="d-flex gap-3 align-items-center">

              <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                setSelectedClinic(e.target.value);
                sessionStorage.setItem('selectedClinic', e.target.value);
              }}>
                {
                  clinics?.map((item: any) => {
                    return (
                      <option key={item?.uniqueId} value={item.uniqueId}>{item.name}</option>
                    )
                  })
                }
              </select>

              {
                user?.role !== "Read Only" &&
                <div>
                  <button onClick={() => {
                    navigate('/api-connection')
                  }}><i className="bi bi-gear"></i></button>
                </div>
              }

            </div>
          </div>
        </div>
      </section>


      {/* For Clinic api connection */}
      <section className="configration-details-sec px-30 pt-5">
        <div className="container">

          <div className="row">

            <div className="col-12 api-lists">
              <div className="api-list-item">
                <div className="wapi d-flex align-items-center gap-3">
                  <img src={require("../../assets/images/ga-icon.png")} alt="" />
                  <h6 className="m-0">Google Analytics</h6>
                </div>

                <div className="api-action-button">
                  {
                    selectedClinicData?.ga?.access_token &&
                    <button disabled={user.role === "Read Only"} className="btn btn-danger" type="button" onClick={() => handleGoogleAnalyticsConnect('ga', true)}>Disconnect</button>
                  }
                  {
                    !selectedClinicData?.ga?.access_token &&
                    <button disabled={user.role === "Read Only"} className="btn btn-primary" type="button" onClick={() => handleGoogleAnalyticsConnect('ga')}>Connect</button>
                  }
                </div>
              </div>
              <div className="api-list-item d-none">
                <div className="wapi d-flex align-items-center gap-3">
                  <img src={require("../../assets/images/gmb-icon.png")} alt="" />
                  <h6 className="m-0">GBP</h6>
                </div>

                <div className="api-action-button">
                  {
                    selectedClinicData?.gmb?.access_token &&
                    <button disabled={user.role === "Read Only"} className="btn btn-danger" type="button" onClick={() => handleGoogleAnalyticsConnect('gmb', true)}>Disconnect</button>
                  }
                  {
                    !selectedClinicData?.gmb?.access_token &&
                    <button disabled={user.role === "Read Only"} className="btn btn-primary" type="button" onClick={() => handleGoogleAnalyticsConnect('gmb')}>Connect</button>
                  }
                </div>
              </div>
              <div className="api-list-item">
                <div className="wapi d-flex align-items-center gap-3">
                  <img src={require("../../assets/images/gad-icon.png")} alt="" />
                  <h6 className="m-0">Google adwords</h6>
                </div>

                <div className="api-action-button">
                  {
                    selectedClinicData?.gads?.access_token &&
                    <button disabled={user.role === "Read Only"} className="btn btn-danger" type="button" onClick={() => handleGoogleAnalyticsConnect('gads', true)}>Disconnect</button>
                  }
                  {
                    !selectedClinicData?.gads?.access_token &&
                    <button disabled={user.role === "Read Only"} className="btn btn-primary" type="button" onClick={() => handleGoogleAnalyticsConnect('gads')}>Connect</button>
                  }
                </div>
              </div>

              <div className="api-list-item">
                <div className="wapi d-flex align-items-center gap-3">
                  <img src={require("../../assets/images/gmb-icon.png")} alt="" />
                  <h6 className="m-0">Trust Horizon (using GBP & reviews)</h6>
                </div>

                <div className="row g-3 align-items-center">
                  <div className="col-auto">
                    <label htmlFor="inputclientId" className="col-form-label">API Key</label>
                  </div>
                  <div className="col-auto">
                    <input disabled={user.role === "Read Only"} type="text" id="inputclientId" className="form-control" value={trustHorizonAPIKey} onChange={(e) => {
                      setTrustHorizonAPIKey(e.target.value);
                    }} />
                  </div>
                </div>

                <div className="api-action-button">
                  {
                    selectedClinicData?.trustH?.key &&
                    <button disabled={user.role === "Read Only"} className="btn btn-danger" type="button" onClick={() => handleConnectTrustHorizon(true)}>Disconnect</button>
                  }
                  {
                    !selectedClinicData?.trustH?.key &&
                    <button disabled={user.role === "Read Only"} className="btn btn-primary" type="button" onClick={() => handleConnectTrustHorizon()}>Connect</button>
                  }
                </div>
              </div>

              <div className="api-list-item modify-collrail">
                <div className="wapi d-flex align-items-center gap-3">
                  <img src={require("../../assets/images/call-rail-icon.png")} alt="" />
                  <h6 className="m-0">Call rail</h6>
                </div>

                <div className="callrail-data-add">
                  <div className="row">
                    <div className="col">
                      <div className="row g-3 align-items-center">
                        <div className="col-auto">
                          <label htmlFor="inputclientId" className="col-form-label">Account</label>
                        </div>
                        <div className="col-auto">
                          <select disabled={user.role === "Read Only"} className="form-select" value={callRailAccount} onChange={(e: any) => {
                            setCallRailAccount(e.target.value);
                            if (e.target.value !== "") callCompanyList(e.target.value);
                          }}>
                            <option value="">--Select--</option>
                            {
                              callRailAccountList?.map((item: any) => {
                                return (
                                  <option key={item.numeric_id} value={item.numeric_id}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row g-3 align-items-center">
                        <div className="col-auto">
                          <label htmlFor="inputclientId" className="col-form-label">Company</label>
                        </div>
                        <div className="col-auto">
                          <select disabled={user.role === "Read Only"} className="form-select" value={callRailCompany} onChange={(e: any) => {
                            setCallRailCompany(e.target.value);
                            if (e.target.value !== "") callCompanyList(e.target.value);
                          }}>
                            <option value="">--Select--</option>
                            {
                              callRailCompanyList?.map((item: any) => {
                                return (
                                  <option key={item.id} value={item.id}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>

                    <table className="table table-borderless mt-4">
                      <thead>
                        <tr>
                          <th scope="col">Map Source</th>
                          <th scope="col">Tracking Number</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          configs && Object.keys(configs).map((key) => {
                            return (
                              <tr key={key}>
                                <td>{key}</td>
                                <td>{configs[key]}</td>
                                {
                                  user.role === "Read Only" ?
                                  <td></td> :
                                  <td><i className="bi bi-x" onClick={() => {
                                    const cloned = JSON.parse(JSON.stringify(configs));
                                    delete cloned[key];
                                    // setConfigs(cloned);
                                    handleCallRailRemove(cloned);
                                  }}></i></td>
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>

                    </table>

                    <div className="d-flex gap-3">
                      <div className="col-auto">
                        <label htmlFor="MapSource" className="col-form-label">Map Source</label>
                        <input disabled={user.role === "Read Only"} type="text" id="MapSource" className="form-control" value={mapSource} onChange={(e) => {
                          setMapSource(e.target.value);
                        }} />
                      </div>
                      <div className="col-auto">
                        <label htmlFor="TrackingNumber" className="col-form-label">Tracking Number	</label>
                        <input disabled={user.role === "Read Only"} type="text" id="TrackingNumber" className="form-control" value={trackingNumber} onChange={(e) => {
                          setTrackingNumber(e.target.value);
                        }} />
                      </div>
                      <div className="col-auto">
                        <button disabled={user.role === "Read Only"} className="btn btn-primary" style={{ marginTop: '38px' }} type="button" onClick={() => {
                          handleCallRailAdd();
                        }}>Add</button>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <div className="api-action-button">
                  <button className="btn btn-primary" type="submit">Connect</button>
                </div> */}


              </div>
            </div>

          </div>

        </div >
      </section >

      {
        showGAAccountSelect &&
        <SelectGAAccount
          show={showGAAccountSelect}
          onHide={hideGAAccountSelect}
          tokenMetadata={tokenMetadata}
          handleSubmitGASelect={handleSubmitGASelect}
        />
      }

      {
        showGMBAccountSelect &&
        <SelectGMBAccount
          show={showGMBAccountSelect}
          onHide={hideGMBAccountSelect}
          tokenMetadata={tokenMetadata}
          handleSubmitGASelect={handleSubmitGMBSelect}
        />
      }

      {
        showGAdsAccountSelect &&
        <SelectGAdsAccount
          show={showGAdsAccountSelect}
          onHide={hideGAdsAccountSelect}
          tokenMetadata={tokenMetadata}
          handleSubmitGAdsSelect={handleSubmitGAdsSelect}
        ></SelectGAdsAccount>
      }
    </>
  )
}

const SelectGAAccount = (props: any) => {
  const {
    show, onHide,
    tokenMetadata,
    handleSubmitGASelect
  } = props;
  const [list, setList] = useState([]);
  const [checkedValue, setCheckValue] = useState('');
  const [selected, setSelected] = useState({});
  const getAccountSummary = async () => {
    const response = await getGoogleAnalyticsAccounts(tokenMetadata?.access_token);
    setList(response?.accountSummaries);
  }
  useEffect(() => {
    getAccountSummary();
  }, []);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          list?.map((item: any) => {
            if (!item?.propertySummaries) return null;
            return (
              <div>
                <p>{item?.displayName}</p>
                {
                  item?.propertySummaries?.map((_item: any) => {
                    return (
                      <>
                        <Form.Check // prettier-ignore
                          type={"radio"}
                          name="accountGroup"
                          id={`default-${_item?.property}`}
                          label={_item?.displayName}
                          onChange={() => {
                            let obj = JSON.parse(JSON.stringify(item));
                            delete obj.propertySummaries;
                            obj.property = _item;
                            setSelected(obj);
                            setCheckValue(_item?.property);
                          }}
                          checked={checkedValue === _item?.property}
                          key={_item?.property}
                        />
                      </>
                    )
                  })
                }
              </div>
            )
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary">Close</Button>
        <Button variant="primary" disabled={!checkedValue || checkedValue === ''} onClick={() => {
          handleSubmitGASelect(selected);
        }}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}

const SelectGMBAccount = (props: any) => {
  const {
    show, onHide,
    tokenMetadata,
    handleSubmitGASelect
  } = props;
  const [list, setList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [checkedValue, setCheckValue] = useState('');
  const [checkedValueLocation, setCheckValueLocation] = useState('');
  const [selected, setSelected] = useState({});
  const getAccountSummary = async () => {
    const response = await getGMBAccounts(tokenMetadata?.access_token);
    setList(response?.accounts);
  };
  const getLocations = async (accountId: string) => {
    const response = await getGMBLocations(tokenMetadata?.access_token, accountId);
    setLocations(response?.locations);
  };
  useEffect(() => {
    getAccountSummary();
  }, []);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          list?.map((item: any) => {
            return (
              <div>
                <Form.Check // prettier-ignore
                  type={"radio"}
                  name="accountGroup"
                  id={`default-${item?.name}`}
                  label={item?.accountName}
                  onChange={() => {
                    setSelected(item);
                    setCheckValue(item?.name);
                    getLocations(item.name);
                  }}
                  checked={checkedValue === item?.name}
                  key={`default-${item?.name}`}
                />
                {
                  locations?.map((_item: any) => {
                    return (
                      <>
                        <Form.Check // prettier-ignore
                          type={"radio"}
                          name="accountGroup"
                          id={`default-${_item?.name}`}
                          label={_item?.title}
                          onChange={() => {
                            let obj = JSON.parse(JSON.stringify(item));
                            obj.location = _item;
                            setSelected(obj);
                            setCheckValueLocation(_item?.name);
                          }}
                          checked={checkedValueLocation === _item?.name}
                          key={`default-${_item?.name}`}
                        />
                      </>
                    )
                  })
                }
              </div>
            )
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary">Close</Button>
        <Button variant="primary" disabled={(!checkedValue || checkedValue === '' || !checkedValueLocation || checkedValueLocation === '')} onClick={() => {
          handleSubmitGASelect(selected);
        }}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}

const SelectGAdsAccount = (props: any) => {
  const {
    show, onHide,
    tokenMetadata,
    handleSubmitGAdsSelect
  } = props;
  const [list, setList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [checkedValue, setCheckValue] = useState('');
  const [checkedValueLocation, setCheckValueLocation] = useState('');
  const [selected, setSelected] = useState({});
  const getAccountSummary = async () => {
    const response = await getGoogleAdsAccounts(tokenMetadata?.access_token);
    setList(response?.resourceNames);
  };
  const getLocations = async (accountId: string) => {
    const response = await getGoogleAdsAccountsCustomer(tokenMetadata?.access_token, accountId, {
      "query": "SELECT customer_client.descriptive_name, customer_client.manager, customer_client.client_customer, customer_client.id FROM customer_client WHERE customer_client.manager = FALSE"
    });
    setLocations(response?.results);
  };
  useEffect(() => {
    getAccountSummary();
  }, []);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          list?.map((item: any) => {
            return (
              <div>
                <Form.Check // prettier-ignore
                  type={"radio"}
                  name="customerGroup"
                  id={`default-${item}`}
                  label={item}
                  onChange={() => {
                    setSelected(item);
                    setCheckValue(item);
                    getLocations(item);
                  }}
                  checked={checkedValue === item}
                  key={`default-${item}`}
                />
                <div className="mx-5">
                  {
                    checkedValue === item && locations?.map((_item: any) => {
                      return (
                        <>
                          <Form.Check // prettier-ignore
                            type={"radio"}
                            name="accountGroup"
                            id={`default-${_item?.customerClient?.id}`}
                            label={_item?.customerClient?.descriptiveName ?? _item?.customerClient?.id}
                            onChange={() => {
                              let obj = JSON.parse(JSON.stringify({ customer: item }));
                              obj.account = _item;
                              setSelected(obj);
                              setCheckValueLocation(_item?.customerClient?.id);
                            }}
                            checked={checkedValueLocation === _item?.customerClient?.id}
                            key={`default-${_item?.customerClient?.id}`}
                          />
                        </>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary">Close</Button>
        <Button variant="primary" disabled={(!checkedValue || checkedValue === '' || !checkedValueLocation || checkedValueLocation === '')} onClick={() => {
          handleSubmitGAdsSelect(selected);
        }}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}