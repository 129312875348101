import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form";
import { emailValidator } from "../../utils/validators";
import { newFormatter as formatter } from "../../helpers/currency";
import Select from 'react-select';
import moment from "moment";
import { Margin, Resolution, usePDF } from "react-to-pdf";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useAppSelector } from "../../store/hook";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getAgency, roiCalcAdd } from "../../services/roicalc";
import configs from "../../configs/apiConfigs";
import logo from '../../assets/images/dd-black.svg';
import { toast } from "react-toastify";

const PMSList = [
    {
        label: 'Chameleon',
        value: 'Chameleon'
    },
    {
        label: 'Shelter Buddy',
        value: 'Shelter Buddy'
    },
    {
        label: 'ChiroTouch',
        value: 'ChiroTouch'
    },
    {
        label: 'Eclipse',
        value: 'Eclipse'
    },
    {
        label: 'AbelDent',
        value: 'AbelDent'
    },
    {
        label: 'Lytec',
        value: 'Lytec'
    },
    {
        label: 'Autopia',
        value: 'Autopia'
    },
    {
        label: 'Axxium VisionR',
        value: 'Axxium VisionR'
    },
    {
        label: 'ClearDent',
        value: 'ClearDent'
    },
    {
        label: 'Curve Hero',
        value: 'Curve Hero'
    },
    {
        label: 'DentalVision',
        value: 'DentalVision'
    },
    {
        label: 'Dentech',
        value: 'Dentech'
    },
    {
        label: 'DentiMax',
        value: 'DentiMax'
    },
    {
        label: 'DentiTek',
        value: 'DentiTek'
    },
    {
        label: 'Dentonovo',
        value: 'Dentonovo'
    },
    {
        label: 'Dentrix',
        value: 'Dentrix'
    },
    {
        label: 'Dolphin Management',
        value: 'Dolphin Management'
    },
    {
        label: 'DOM',
        value: 'DOM'
    },
    {
        label: 'Domtrak',
        value: 'Domtrak'
    },
    {
        label: 'Dox Pedo',
        value: 'Dox Pedo'
    },
    {
        label: 'DSN-Dental',
        value: 'DSN-Dental'
    },
    {
        label: 'DSN-OMS',
        value: 'DSN-OMS',
    },
    {
        label: 'DSN-Perio',
        value: 'DSN-Perio'
    },
    {
        label: 'EagleSoft',
        value: 'EagleSoft'
    },
    {
        label: 'Easy Dental',
        value: 'Easy Dental'
    },
    {
        label: 'EndoVision',
        value: 'EndoVision'
    },
    {
        label: 'Exceldent',
        value: 'Exceldent'
    },
    {
        label: 'Genesis',
        value: 'Genesis'
    },
    {
        label: 'LiveDDM',
        value: 'LiveDDM'
    },
    {
        label: 'MacPractice',
        value: 'MacPractice'
    },
    {
        label: 'Maxident',
        value: 'Maxident'
    },
    {
        label: 'MediaDent',
        value: 'MediaDent'
    },
    {
        label: 'Mogo',
        value: 'Mogo'
    },
    {
        label: 'OCS-Office Partner',
        value: 'OCS-Office Partner'
    },
    {
        label: 'OMSVision',
        value: 'OMSVision'
    },
    {
        label: 'OpenDental',
        value: 'OpenDental'
    },
    {
        label: 'Opes',
        value: 'Opes'
    },
    {
        label: 'Ortho2 ViewPoint',
        value: 'Ortho2 ViewPoint'
    },
    {
        label: 'Ortho2Edge',
        value: 'Ortho2Edge'
    },
    {
        label: 'OrthoNovo',
        value: 'OrthoNovo'
    },
    {
        label: 'OrthoTrac',
        value: 'OrthoTrac'
    },
    {
        label: 'Oryx',
        value: 'Oryx'
    },
    {
        label: 'Paradigm',
        value: 'Paradigm'
    },
    {
        label: 'PBS Endo',
        value: 'PBS Endo'
    },
    {
        label: 'PerioVision',
        value: 'PerioVision'
    },
    {
        label: 'PlanetDDS',
        value: 'PlanetDDS'
    },
    {
        label: 'Power Practice Pro',
        value: 'Power Practice Pro'
    },
    {
        label: 'PracticeWeb',
        value: 'PracticeWeb'
    },
    {
        label: 'PracticeWorks',
        value: 'PracticeWorks'
    },
    {
        label: 'Private Practice Software',
        value: 'Private Practice Software'
    },
    {
        label: 'Progident',
        value: 'Progident'
    },
    {
        label: 'QSIDental Web',
        value: 'QSIDental Web'
    },
    {
        label: 'SoftDent',
        value: 'SoftDent'
    },
    {
        label: 'Software of Excellence',
        value: 'Software of Excellence'
    },
    {
        label: 'SuzyDental',
        value: 'SuzyDental'
    },
    {
        label: 'TDO',
        value: 'TDO'
    },
    {
        label: 'Total Dental',
        value: 'Total Dental'
    },
    {
        label: 'Tracker',
        value: 'Tracker'
    },
    {
        label: 'WinDent',
        value: 'WinDent'
    },
    {
        label: 'WinDentSQL',
        value: 'WinDentSQL'
    },
    {
        label: 'WinOMS',
        value: 'WinOMS'
    },
    {
        label: 'XLDent',
        value: 'XLDent'
    },
    {
        label: '21st Century Accounting',
        value: '21st Century Accounting'
    },
    {
        label: 'FreshBook',
        value: 'FreshBook'
    },
    {
        label: 'MYOB',
        value: 'MYOB'
    },
    {
        label: 'Peachtree',
        value: 'Peachtree'
    },
    {
        label: 'QuickBooks',
        value: 'QuickBooks'
    },
    {
        label: 'OfficeMate',
        value: 'OfficeMate'
    },
    {
        label: 'Allscripts',
        value: 'Allscripts'
    },
    {
        label: 'Lytec',
        value: 'Lytec'
    },
    {
        label: 'Medinformatix',
        value: 'Medinformatix'
    },
    {
        label: 'MediSoft',
        value: 'MediSoft'
    },
    {
        label: 'Advantage',
        value: 'Advantage'
    },
    {
        label: 'AVIMark',
        value: 'AVIMark'
    },
    {
        label: 'ClientTrax',
        value: 'ClientTrax'
    },
    {
        label: 'CornerStone',
        value: 'CornerStone'
    },
    {
        label: 'DVMAX',
        value: 'CornerStone'
    },
    {
        label: 'eVetPractice',
        value: 'eVetPractice'
    },
    {
        label: 'HVMS',
        value: 'HVMS'
    },
    {
        label: 'ImpromedInfinity',
        value: 'ImpromedInfinity'
    },
    {
        label: 'Intravet',
        value: 'Intravet'
    },
    {
        label: 'OpenVPMS',
        value: 'OpenVPMS'
    },
    {
        label: 'RxWorks',
        value: 'RxWorks'
    },
    {
        label: 'StringSoft',
        value: 'StringSoft'
    },
    {
        label: 'Vetech',
        value: 'Vetech'
    },
    {
        label: 'VetSpace',
        value: 'VetSpace'
    },
    {
        label: 'VIA',
        value: 'VIA'
    },
]

export const PublicROICalculator = () => {
    const params = useParams();
    if (!params.agencyId) {
        return null;
    }
    const [showClinicInfoAccept, setShowClinicInfoAccept] = useState(true);
    const [step, setStep] = useState(0);
    const [clinicData, setClincData] = useState({} as any);
    const [agency, setAgency] = useState<any>({});
    const [inputData, setInputData] = useState<any>({
        feedback: {
            '1a': 'software',
            '1b': 0
        }
    });
    const [loadingPdf, setLoadingPdf] = useState(false);
    const { register, handleSubmit, watch, setValue, formState: { errors }, setError } = useForm({
        defaultValues: {
            name: "",
            email: "",
            city: "",
            pms: "",
        }
    });
    const { toPDF, targetRef } = usePDF({ filename: `roi_calc_${moment().format("DD-MM-YYYY")}.pdf` });
    const divRefs = useRef<any[]>([]);
    const user = useAppSelector((state) => state.user.user);
    const navigate = useNavigate();

    const _getAgency = async () => {
        const res = await getAgency(params?.agencyId ?? '');
        setAgency(res);
    }

    useEffect(() => {
        _getAgency();
    }, [params]);

    const handleAddRef = (ref: any) => {
        if (ref && !divRefs.current.includes(ref)) {
            divRefs.current.push(ref);
        }
    }
    const onSubmit = async (values: any) => {
        const res = await roiCalcAdd({
            "agencyid": params.agencyId,
            "step": "0",
            "name": values.name,
            "email": values.email,
            "city": values.city,
            "pms": values.pms
        });
        setShowClinicInfoAccept(false);
        setStep(0);
        setClincData({ ...values, ...res });
    }
    const mad1 = inputData?.marketing_assesment?.['1b'] && inputData?.marketing_assesment?.['1b'] !== '' && inputData?.marketing_assesment?.['1c'] && inputData?.marketing_assesment?.['1c'] !== '' ? (Number(inputData?.marketing_assesment?.['1b']) / Number(inputData?.marketing_assesment?.['1c'])).toFixed(2) : '';
    const eed1 = inputData?.effort_engage?.['1b'] && inputData?.effort_engage?.['1b'] !== '' && inputData?.effort_engage?.['1c'] && inputData?.effort_engage?.['1c'] !== '' ? (Number(inputData?.effort_engage?.['1b']) * Number(inputData?.effort_engage?.['1c'])).toFixed(2) : '';
    const npb1 = inputData?.marketing_assesment?.['1c'] && inputData?.marketing_assesment?.['1c'] !== '' && inputData?.new_patient?.['1a'] && inputData?.new_patient?.['1a'] !== '' ?
        ((Number(inputData?.marketing_assesment?.['1c']) * Number(inputData?.new_patient?.['1a'])) / 100).toFixed(2) : '';
    const npc1 = inputData?.marketing_assesment?.['1b'] && inputData?.marketing_assesment?.['1b'] !== '' && npb1 ? (Number(inputData?.marketing_assesment?.['1b']) / Number(npb1)).toFixed(2) : '';
    const ervc1 = inputData?.effort_return_visit?.['1a'] && inputData?.effort_return_visit?.['1a'] !== '' && inputData?.effort_return_visit?.['1b'] && inputData?.effort_return_visit?.['1b'] !== '' ? (Number(inputData?.effort_return_visit?.['1a']) * Number(inputData?.effort_return_visit?.['1b'])).toFixed(2) : '';
    const rmd1 = inputData?.responsibility_marketing?.['1b'] && inputData?.responsibility_marketing?.['1b'] !== '' && inputData?.responsibility_marketing?.['1c'] && inputData?.responsibility_marketing?.['1c'] !== '' ? (Number(inputData?.responsibility_marketing?.['1b']) * Number(inputData?.responsibility_marketing?.['1c'])).toFixed(2) : '';
    const cprf1 = inputData?.feedback?.['1c'] && inputData?.feedback?.['1c'] !== '' &&
        inputData?.feedback?.['1d'] && inputData?.feedback?.['1d'] !== '' &&
        inputData?.feedback?.['1e'] && inputData?.feedback?.['1e'] !== '' ? ((Number(inputData?.feedback?.['1c']) * Number(inputData?.feedback?.['1d'])) + (Number(inputData?.feedback?.['1b']))).toFixed(2) : '';
    const rrmg1 = cprf1 && inputData?.feedback?.['1e'] && inputData?.feedback?.['1e'] !== '' ? (Number(cprf1) / Number(inputData?.feedback?.['1e'])).toFixed(2) : '';
    const asd1 = inputData?.analysis_assesment?.['1a'] && inputData?.analysis_assesment?.['1a'] !== '' && inputData?.analysis_assesment?.['1c'] && inputData?.analysis_assesment?.['1c'] !== '' ? (Number(inputData?.analysis_assesment?.['1a']) * Number(inputData?.analysis_assesment?.['1c'])).toFixed(2) : '';
    console.log(agency);
    return (
        <>
            <section className="middle-navbar py-4 px-30">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            agency &&
                            <img width={150} src={agency?.logo ? configs.IMAGE_URL + 'agency/' + agency?.logo?.name + '-original.' + agency?.logo?.type : logo} />
                        }
                        <div>
                            <div className="d-flex flex-row">
                                <div className="nav-title-txt m-0">ROI Calculator</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                showClinicInfoAccept &&
                <section className="sync-leas-sec px-30 pt-5">
                    <div className="container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row mb-5">

                                <div className="d-flex mb-3 pb-2 border-bottom px-0">
                                    <h6 className="fs-5">Clinic details</h6>
                                </div>

                                <div className="profile-detail-form px-0">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="name">Clinic name</label>
                                            <input type="text" className="form-control" id="name" placeholder="" {...register("name", {
                                                required: true
                                            })} />
                                            {
                                                (errors && errors.name && errors.name.type === "required") &&
                                                <div className="text-danger">Please enter valid name.</div>
                                            }
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="email">Email address</label>
                                            <input type="email" className="form-control" id="email" placeholder="you@example.com" {...register("email", {
                                                required: true,
                                                validate: (value) => !!emailValidator(value),
                                            })} />
                                            {
                                                (errors && errors.email && errors.email.type === "validate") &&
                                                <div className="text-danger">Please enter valid email.</div>
                                            }
                                            {
                                                (errors && errors.email && errors.email.type === "required") &&
                                                <div className="text-danger">Please enter valid email.</div>
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="city">City</label>
                                            <input type="text" className="form-control" id="city" placeholder="" {...register("city", {
                                                required: true,
                                            })} />
                                            {
                                                (errors && errors.city && errors.city.type === "city") &&
                                                <div className="text-danger">Please enter valid city.</div>
                                            }
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="pms">Practice management software</label>
                                            <select className="form-select" {...register("pms", {
                                                required: true,
                                            })}>
                                                <option value=''>Select</option>
                                                {
                                                    PMSList?.map((item, index) => {
                                                        return (
                                                            <option value={item?.value} key={index}>{item?.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {/* <input type="text" className="form-control" id="pms" placeholder="" {...register("pms", {
                                                    required: true,
                                                })} /> */}
                                            {
                                                (errors && errors.pms && errors.pms.type === "pms") &&
                                                <div className="text-danger">Please enter valid practice management software.</div>
                                            }
                                        </div>
                                    </div>

                                    <div className="buttons">
                                        <button className="btn btn-primary" type="submit">Next</button>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                </section>
            }
            {
                !showClinicInfoAccept &&
                <>
                    {
                        step === 0 &&
                        <section className="sync-leas-sec px-30 pt-5">
                            <div className="container">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Marketing Assessment</h5>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>1. Digital Marketing Strategies and Budgets</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) What are the marketing channels you use for lead generation?
                                                    </td>
                                                    <td style={{ minWidth: '350px', maxWidth: '350px' }}>
                                                        <Select options={[{
                                                            label: 'Website SEO',
                                                            value: 'websiteseo',
                                                        }, {
                                                            label: 'Local SEO',
                                                            value: 'localseo'
                                                        }, {
                                                            label: 'Paid Campaign',
                                                            value: 'paidcampaign'
                                                        }, {
                                                            label: 'Social Media Marketing',
                                                            value: 'socialmedia'
                                                        }, {
                                                            label: 'Email Drop',
                                                            value: 'emaildrop'
                                                        }, {
                                                            label: 'Video Marketing',
                                                            value: 'video'
                                                        }, {
                                                            label: 'Retargeting/Remarketing',
                                                            value: 'retargeting'
                                                        }]} isMulti={true}
                                                            value={inputData?.marketing_assesment?.['1a']}
                                                            onChange={(value: any) => {
                                                                setInputData({
                                                                    ...inputData,
                                                                    marketing_assesment: {
                                                                        ...inputData.marketing_assesment,
                                                                        '1a': value
                                                                    }
                                                                });
                                                            }}
                                                        ></Select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) What is the monthly spent including ad budget allocated for all marketing channels. <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.marketing_assesment?.['1b']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        marketing_assesment: {
                                                                            ...inputData.marketing_assesment,
                                                                            '1b': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        marketing_assesment: {
                                                                            ...inputData.marketing_assesment,
                                                                            '1b': inputData?.marketing_assesment?.['1b'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        c) How many monthly leads do you generate from the channels?
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.marketing_assesment?.['1c']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        marketing_assesment: {
                                                                            ...inputData.marketing_assesment,
                                                                            '1c': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        marketing_assesment: {
                                                                            ...inputData.marketing_assesment,
                                                                            '1c': inputData?.marketing_assesment?.['1c'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        d) Lead Acquisition Cost
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input disabled type="text" value={mad1} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>2. Efforts to Engage/Convert Leads</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) Who is responsible converting online Leads? <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px', maxWidth: '350px' }}>
                                                        <Select options={[{
                                                            label: 'Receptionist',
                                                            value: 'receptionist',
                                                        }, {
                                                            label: 'Admin',
                                                            value: 'admin'
                                                        }, {
                                                            label: 'Marketing manager',
                                                            value: 'marketingmanager'
                                                        }, {
                                                            label: 'Owner',
                                                            value: 'owner'
                                                        }]} isMulti={true}
                                                            value={inputData?.effort_engage?.['1a']}
                                                            onChange={(value: any) => {
                                                                setInputData({
                                                                    ...inputData,
                                                                    effort_engage: {
                                                                        ...inputData.effort_engage,
                                                                        '1a': value
                                                                    }
                                                                });
                                                            }}
                                                        ></Select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) How many hours are spent on converting leads? <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <input type="text" className="form-control form-control-sm" value={inputData?.effort_engage?.['1b']} onChange={((event) => {
                                                            if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                setInputData({
                                                                    ...inputData,
                                                                    effort_engage: {
                                                                        ...inputData.effort_engage,
                                                                        '1b': event.target.value
                                                                    }
                                                                });
                                                            } else {
                                                                setInputData({
                                                                    ...inputData,
                                                                    effort_engage: {
                                                                        ...inputData.effort_engage,
                                                                        '1b': inputData.effort_engage['1b'] ?? ''
                                                                    }
                                                                });
                                                            }
                                                        })}></input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        c) Average resource cost?
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.effort_engage?.['1c']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        effort_engage: {
                                                                            ...inputData.effort_engage,
                                                                            '1c': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        effort_engage: {
                                                                            ...inputData.effort_engage,
                                                                            '1c': inputData?.effort_engage?.['1c'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">per hour</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        d) Conversion cost
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input disabled type="text" value={eed1} className="form-control form-control-sm"></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h6 className="card-title">2. Efforts to Engage/Convert Leads</h6>
                            <div className="mb-3">
                                <ul className="list-group list-group-flush">
                                    <li>a) Who is responsible converting online Leads?</li>
                                    <li>b) How many hours are spent on converting leads?</li>
                                    <li>c) Cost? per hour</li>
                                    <li>d) Display Conversion Cost</li>
                                </ul>
                            </div> */}
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>3. New Patient Conversion Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) What is the percentage of leads converted into new patients?
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.new_patient?.['1a']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        new_patient: {
                                                                            ...inputData.new_patient,
                                                                            '1a': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        new_patient: {
                                                                            ...inputData.new_patient,
                                                                            '1a': inputData?.new_patient?.['1a'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) Monthly New patients
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" value={npb1} disabled className="form-control form-control-sm"></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        c) Patients Acquisition Cost
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" value={npc1} disabled className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        d) What is the value for each new patient (Life time value) <span className="text-danger">*</span> <OverlayTrigger rootClose trigger="click" placement="auto" overlay={(
                                                            <Popover>
                                                                <>{`Patient LTV=Average Revenue per Visit×Number of Visits per Year×Patient Retention Period`}</>
                                                            </Popover>
                                                        )}><i className="bi bi-info-circle-fill"></i></OverlayTrigger>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.new_patient?.['1d']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        new_patient: {
                                                                            ...inputData.new_patient,
                                                                            '1d': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        new_patient: {
                                                                            ...inputData.new_patient,
                                                                            '1d': inputData?.new_patient?.['1d'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        e) Value
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input disabled type="text" value={Number(npb1) * Number(inputData?.new_patient?.['1d'])} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h6 className="card-title">3. New Patient Conversion Rate</h6>
                            <div>
                                <ul className="list-group list-group-flush">
                                    <li>a) What is the percentage of leads converted into new patients?</li>
                                    <li>b) Display Monthly New patients</li>
                                    <li>c) Patients Acquisition Cost</li>
                                    <li>d) Value</li>
                                </ul>
                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    {
                        step === 1 &&
                        <section className="sync-leas-sec px-30 pt-5">
                            <div className="container">
                                <div className="card mt-3">
                                    <div className="card-header">
                                        <h5>Patient Engagement Assessment</h5>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>1. What Patient Engagement Tools do you currently use?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) What Patient engagement software’s do you use?
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <input type="text" className="form-control form-control-sm" value={inputData?.patient_engagement_tool?.['1a']} onChange={((event) => {
                                                            setInputData({
                                                                ...inputData,
                                                                patient_engagement_tool: {
                                                                    ...inputData.patient_engagement_tool,
                                                                    '1a': event.target.value
                                                                }
                                                            });
                                                        })}></input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) What is the monthly cost of the patient engagement software.
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.patient_engagement_tool?.['1b']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        patient_engagement_tool: {
                                                                            ...inputData.patient_engagement_tool,
                                                                            '1b': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        patient_engagement_tool: {
                                                                            ...inputData.patient_engagement_tool,
                                                                            '1b': inputData?.patient_engagement_tool?.['1b'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h6 className="card-title">1. What Patient Engagement Tools do you currently use?</h6>
                            <div className="mb-3">
                                <ul className="list-group list-group-flush">
                                    <li>a) What Patient engagement software’s do you use?</li>
                                    <li>b) What is the monthly cost of the patient engagement software.</li>
                                </ul>
                            </div> */}
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>2. Efforts to Encourage Return Visits</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) How many hours spent on follow-ups, confirmations, recalls, and rescheduling. <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.effort_return_visit?.['1a']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        effort_return_visit: {
                                                                            ...inputData.effort_return_visit,
                                                                            '1a': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        effort_return_visit: {
                                                                            ...inputData.effort_return_visit,
                                                                            '1a': inputData?.effort_return_visit?.['1a'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) Average resource cost? <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.effort_return_visit?.['1b']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        effort_return_visit: {
                                                                            ...inputData.effort_return_visit,
                                                                            '1b': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        effort_return_visit: {
                                                                            ...inputData.effort_return_visit,
                                                                            '1b': inputData?.effort_return_visit?.['1b'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per hour</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) Total cost
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input disabled type="text" value={ervc1} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h6 className="card-title">2. Efforts to Encourage Return Visits</h6>
                            <div className="mb-3">
                                <ul className="list-group list-group-flush">
                                    <li>a) How many hours spent on follow-ups, confirmations, recalls, and rescheduling. per month</li>
                                    <li>b) Cost? per hour</li>
                                    <li>c) Display Total Cost</li>
                                </ul>
                            </div> */}
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>3. Responsibilities for Marketing and Engagement? (Email campaigns and patient education)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) Who is responsible for marketing and engagement efforts? <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px', maxWidth: '350px' }}>
                                                        <Select options={[{
                                                            label: 'Receptionist',
                                                            value: 'receptionist',
                                                        }, {
                                                            label: 'Admin',
                                                            value: 'admin'
                                                        }, {
                                                            label: 'Marketing manager',
                                                            value: 'marketingmanager'
                                                        }, {
                                                            label: 'Owner',
                                                            value: 'owner'
                                                        }]} isMulti={true}
                                                            value={inputData?.responsibility_marketing?.['1a']}
                                                            onChange={(value: any) => {
                                                                setInputData({
                                                                    ...inputData,
                                                                    responsibility_marketing: {
                                                                        ...inputData.responsibility_marketing,
                                                                        '1a': value
                                                                    }
                                                                });
                                                            }}
                                                        ></Select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) How many total hours are spent by all staff members on these tasks. <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.responsibility_marketing?.['1b']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        responsibility_marketing: {
                                                                            ...inputData.responsibility_marketing,
                                                                            '1b': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        responsibility_marketing: {
                                                                            ...inputData.responsibility_marketing,
                                                                            '1b': inputData?.responsibility_marketing?.['1b'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        c) Average resource cost? <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.responsibility_marketing?.['1c']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        responsibility_marketing: {
                                                                            ...inputData.responsibility_marketing,
                                                                            '1c': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        responsibility_marketing: {
                                                                            ...inputData.responsibility_marketing,
                                                                            '1c': inputData?.responsibility_marketing?.['1c'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per hour</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        d) Total cost
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input disabled type="text" value={rmd1} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h6 className="card-title">3.  Responsibilities for Marketing and Engagement? (Email campaigns and patient education)</h6>
                            <div>
                                <ul className="list-group list-group-flush">
                                    <li>a) Who is responsible for marketing and engagement efforts?</li>
                                    <li>b) How many total hours are spent by all staff members on these tasks. [Text input] per month</li>
                                    <li>c) Cost? per hour</li>
                                    <li>d) Display Total Cost</li>
                                </ul>
                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    {
                        step === 2 &&
                        <section className="sync-leas-sec px-30 pt-5">
                            <div className="container">
                                <div className="card mt-3">
                                    <div className="card-header">
                                        <h5>Reviews and Reputation Management</h5>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>1. Handling Online Reviews and Feedback?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) How are online reviews and feedback currently managed?
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <select className="form-select form-select-sm" value={inputData?.feedback?.['1a']} onChange={((event) => {
                                                            setInputData({
                                                                ...inputData,
                                                                feedback: {
                                                                    ...inputData.feedback,
                                                                    '1a': event.target.value,
                                                                    '1b': 0
                                                                }
                                                            });
                                                        })}>
                                                            <option value='software'>Software</option>
                                                            <option value='manual'>Manual</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                {
                                                    inputData?.feedback?.['1a'] === 'software' &&
                                                    <tr>
                                                        <td>
                                                            b) Are you using any automated review software? Cost ?
                                                        </td>
                                                        <td style={{ minWidth: '350px' }}>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input type="text" className="form-control form-control-sm" value={inputData?.feedback?.['1b']} onChange={((event) => {
                                                                    if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                        setInputData({
                                                                            ...inputData,
                                                                            feedback: {
                                                                                ...inputData.feedback,
                                                                                '1b': event.target.value
                                                                            }
                                                                        });
                                                                    } else {
                                                                        setInputData({
                                                                            ...inputData,
                                                                            feedback: {
                                                                                ...inputData.feedback,
                                                                                '1b': inputData?.feedback?.['1b'] ?? ''
                                                                            }
                                                                        });
                                                                    }
                                                                })}></input>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td>
                                                        c) How many hours spent on managing online reviews and feedback. <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.feedback?.['1c']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1c': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1c': inputData?.feedback?.['1c'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        d) Average resource cost?
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.feedback?.['1d']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1d': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1d': inputData?.feedback?.['1d'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per hour</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        e) How many new reviews per month (Average)? <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.feedback?.['1e']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1e': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1e': inputData?.feedback?.['1e'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        f) Total cost
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" disabled value={cprf1} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        g) What is the brand and reputation value for each new patient review. <span className="text-danger">*</span> <OverlayTrigger rootClose trigger="click" placement="auto" overlay={(
                                                            <Popover>
                                                                <>{`Value of each positive review = Total additional  revenue / Number of positive reviews`}</>
                                                            </Popover>
                                                        )}><i className="bi bi-info-circle-fill"></i></OverlayTrigger>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" value={inputData?.feedback?.['1g']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1g': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        feedback: {
                                                                            ...inputData.feedback,
                                                                            '1g': inputData?.feedback?.['1g'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        h) Cost per review
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input disabled type="text" value={rrmg1} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h6 className="card-title">1. Handling Online Reviews and Feedback?</h6>
                            <div className="mb-3">
                                <ul className="list-group list-group-flush">
                                    <li>a) How are online reviews and feedback currently managed?</li>
                                    <li>b) Any you using any automated review software? Cost ?</li>
                                    <li>c) How many hours spent on managing online reviews and feedback. per month</li>
                                    <li>d) Cost? per hour</li>
                                    <li>e) How many new reviews per month (Average)?</li>
                                    <li>f) Total hours X Hourly rate + Cost of software/ New reviews</li>
                                    <li>g) Cost per review X New reviews</li>
                                </ul>
                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    {
                        step === 3 &&
                        <section className="sync-leas-sec px-30 pt-5">
                            <div className="container">
                                <div className="card mt-3">
                                    <div className="card-header">
                                        <h5>Analysis & Optimization Assessment</h5>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th colSpan={2}>1. Management, Measurement and Adjustment for improvement?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        a) How many hours spent on analyzing and adjusting conversion efforts. <span className="text-danger">*</span>
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.analysis_assesment?.['1a']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        analysis_assesment: {
                                                                            ...inputData.analysis_assesment,
                                                                            '1a': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        analysis_assesment: {
                                                                            ...inputData.analysis_assesment,
                                                                            '1a': inputData?.analysis_assesment?.['1a'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">per month</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        b) Who is responsible for this?
                                                    </td>
                                                    <td style={{ minWidth: '350px', maxWidth: '350px' }}>
                                                        <Select options={[{
                                                            label: 'Receptionist',
                                                            value: 'receptionist',
                                                        }, {
                                                            label: 'Admin',
                                                            value: 'admin'
                                                        }, {
                                                            label: 'Marketing manager',
                                                            value: 'marketingmanager'
                                                        }, {
                                                            label: 'Owner',
                                                            value: 'owner'
                                                        }]} isMulti={true}
                                                            value={inputData?.analysis_assesment?.['1b']}
                                                            onChange={(value: any) => {
                                                                setInputData({
                                                                    ...inputData,
                                                                    analysis_assesment: {
                                                                        ...inputData.analysis_assesment,
                                                                        '1b': value
                                                                    }
                                                                });
                                                            }}
                                                        ></Select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        c) Average resource cost?
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input type="text" className="form-control form-control-sm" value={inputData?.analysis_assesment?.['1c']} onChange={((event) => {
                                                                if (!isNaN(Number(event.target.value)) || event.target.value === '') {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        analysis_assesment: {
                                                                            ...inputData.analysis_assesment,
                                                                            '1c': event.target.value
                                                                        }
                                                                    });
                                                                } else {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        analysis_assesment: {
                                                                            ...inputData.analysis_assesment,
                                                                            '1c': inputData?.analysis_assesment?.['1c'] ?? ''
                                                                        }
                                                                    });
                                                                }
                                                            })}></input>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">per hour</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        d) Management cost
                                                    </td>
                                                    <td style={{ minWidth: '350px' }}>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input disabled type="text" value={asd1} className="form-control form-control-sm"></input>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <h6 className="card-title">1. Management, Measurement and Adjustment for improvement?</h6>
                                    <div className="mb-3">
                                        <ul className="list-group list-group-flush">
                                            <li>a) How many hours spent on analyzing and adjusting conversion efforts. per month</li>
                                            <li>b) Who is responsible for this?</li>
                                            <li>c) Cost? per hour</li>
                                            <li>d) Total hours * Hourly rate</li>
                                        </ul>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    {
                        step === 4 &&
                        <section className="sync-leas-sec px-30 pt-5">
                            <div className="container">
                                <div className="card mt-3">
                                    <div className="card-header flex justify-content-between">
                                        <h5>Calculator</h5>
                                        {/* <div><b>Date:</b> {moment().format('YYYY MMM, DD')}</div> */}
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered ">
                                            <tbody className="text-center">
                                                <tr>
                                                    <td className="font-bold">Life time value <OverlayTrigger rootClose trigger="click" placement="auto" overlay={(
                                                        <Popover>
                                                            <>{`Patient LTV=Average Revenue per Visit×Number of Visits per Year×Patient Retention Period`}</>
                                                        </Popover>
                                                    )}><i className="bi bi-info-circle-fill"></i></OverlayTrigger></td>
                                                    <td>{inputData?.new_patient?.['1d']}</td>
                                                    <td className="font-bold">Branding <OverlayTrigger rootClose trigger="click" placement="auto" overlay={(
                                                        <Popover>
                                                            <>{`Value of each positive review = Total additional  revenue / Number of positive reviews`}</>
                                                        </Popover>
                                                    )}><i className="bi bi-info-circle-fill"></i></OverlayTrigger></td>
                                                    <td>{inputData?.feedback?.['1g']}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-bordered">
                                            <thead className="bg-light text-center">
                                                <tr>
                                                    <th rowSpan={2} className="">CURRENT ROI CALCULATOR</th>
                                                    <th colSpan={3}>Current Situation</th>
                                                    {/* <th colSpan={3}>Managed Marketing</th> */}
                                                </tr>
                                                <tr>
                                                    <th>Hours</th>
                                                    <th>Rate</th>
                                                    <th>Cost</th>
                                                    {/* <th>Cost</th>
                                                <th>Saving/Value</th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="text-center">
                                                <tr>
                                                    <td>Can you describe your digital marketing strategies and channels used to promote your dental services along with Budgets?  (Leads & Monthly $ Ad Spend)</td>
                                                    <td>{inputData?.marketing_assesment?.['1c']}</td>
                                                    <td>{formatter.format(Number(mad1))}</td>
                                                    <td>{formatter.format(inputData?.marketing_assesment?.['1b'])}</td>
                                                    {/* <td>{formatter.format(((inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 2) * 300)}</td>
                                                <td>{formatter.format(((inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 2) * 650)}</td> */}
                                                </tr>
                                                <tr>
                                                    <td>How many hours are spent to engage and converting leads? (Hours spent)</td>
                                                    <td>{inputData?.effort_engage?.['1b']}</td>
                                                    <td>{formatter.format(inputData?.effort_engage?.['1c'])}</td>
                                                    <td>{formatter.format(Number(eed1))}</td>
                                                    {/* <td>$100</td>
                                                <td>{formatter.format(Number(eed1) - 100)}</td> */}
                                                </tr>
                                                <tr>
                                                    <td>How many new patients does your practice convert  from the leads you get?  (Conversion Rate)</td>
                                                    <td>{npb1}</td>
                                                    <td>{inputData?.new_patient?.['1a']}%</td>
                                                    <td>{formatter.format(Number(inputData?.marketing_assesment?.['1b']) + Number(eed1))}</td>
                                                    {/* <td>-</td>
                                                <td>-</td> */}
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white " rowSpan={2}>New patient | Acquisition Cost | Value</td>
                                                    <td className="text-white">{npb1}</td>
                                                    <td className="text-white">{formatter.format(Number((Number(inputData?.marketing_assesment?.['1b']) + Number(eed1))) / Number(npb1))}</td>
                                                    <td className="text-white">{formatter.format(Number(npb1) * (Number(inputData?.new_patient?.['1d'])))}</td>
                                                    {/* <td>{(inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 2}</td>
                                                <td>{formatter.format(
                                                        ((inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 2) * 650
                                                        +
                                                        (Number(eed1) - 100)
                                                    )}
                                                </td> */}
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white">Patients</td>
                                                    <td className="text-white">Cost</td>
                                                    <td className="text-white">Value</td>
                                                </tr>
                                                <tr>
                                                    <td>Are you using any specific patient engagement software’s or programs at your practice? (Cost )</td>
                                                    <td>1</td>
                                                    <td>{formatter.format(Number(inputData?.patient_engagement_tool?.['1b']))}</td>
                                                    <td>{formatter.format(Number(inputData?.patient_engagement_tool?.['1b']))}</td>
                                                    {/* <td>$350</td>
                                                <td>{formatter.format((Number(inputData?.patient_engagement_tool?.['1b']) - 350))}</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Can you provide insights into your efforts ( Hours spent) to encourage return visits including follow-ups, confirmations , recall and rescheduling cancelled and missed appointments? </td>
                                                    <td>{inputData?.effort_return_visit?.['1a']}</td>
                                                    <td>{formatter.format(Number(inputData?.effort_return_visit?.['1b']))}</td>
                                                    <td>{formatter.format(Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a'])}</td>
                                                    {/* <td>$300</td>
                                                <td>{formatter.format((Number(inputData?.effort_return_visit?.['1c']) - 300))}</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Can you provide an overview of the staff members responsible for managing marketing efforts and patient engagement initiatives like email marketing promoting restorative services?  (Hours) spent)</td>
                                                    <td>{inputData?.responsibility_marketing?.['1b']}</td>
                                                    <td>{formatter.format(Number(inputData?.responsibility_marketing?.['1c']))}</td>
                                                    <td>{formatter.format(Number(rmd1))}</td>
                                                    {/* <td>$200</td>
                                                <td>{formatter.format((Number(rmd1) - 200))}</td> */}
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white " rowSpan={2}>Engagement Hours | Rate | Cost</td>
                                                    <td className="text-white">{Number(inputData?.effort_return_visit?.['1a']) + Number(inputData?.responsibility_marketing?.['1b'])}</td>
                                                    <td className="text-white">{formatter.format(
                                                        (
                                                            Number(Number(inputData?.patient_engagement_tool?.['1b'])) +
                                                            (Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) +
                                                            Number(Number(rmd1))
                                                        )
                                                        /
                                                        (
                                                            Number(inputData?.effort_return_visit?.['1a']) +
                                                            Number(inputData?.responsibility_marketing?.['1b'])
                                                        )
                                                    )}</td>
                                                    <td className="text-white">{formatter.format((
                                                        Number(Number(inputData?.patient_engagement_tool?.['1b'])) +
                                                        (Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) +
                                                        Number(Number(rmd1))
                                                    ))}</td>
                                                    {/* <td>$850</td>
                                                <td>
                                                    {
                                                     (Number(inputData?.patient_engagement_tool?.['1b']) - 350) +
                                                     (Number(inputData?.effort_return_visit?.['1c']) - 300) +
                                                     (Number(rmd1) - 200)
                                                    }
                                                </td> */}
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white">Hours</td>
                                                    <td className="text-white">Rate</td>
                                                    <td className="text-white">Cost</td>
                                                </tr>
                                                <tr>
                                                    <td>Do you use any review software and what is its cost?</td>
                                                    <td>{inputData?.feedback?.['1a'] === 'software' ? '1' : '0'}</td>
                                                    <td>{formatter.format(inputData?.feedback?.['1b'])}</td>
                                                    <td>{formatter.format((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b']))}</td>
                                                    {/* <td>$50</td>
                                                <td>{formatter.format(((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(formatter.format(inputData?.feedback?.['1b'])) - 200))}</td> */}
                                                </tr>
                                                <tr>
                                                    <td>How do you handle online reviews and feedback from patients? (Time Spent)</td>
                                                    <td>{inputData?.feedback?.['1c']}</td>
                                                    <td>{formatter.format(inputData?.feedback?.['1d'])}</td>
                                                    <td>{formatter.format(Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])))}</td>
                                                    {/* <td>{formatter.format((inputData?.feedback?.['1e'] * 2) * 10)}</td>
                                                <td>{formatter.format(((Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))) - ((inputData?.feedback?.['1e'] * 2) * 10)))}</td> */}
                                                </tr>
                                                <tr>
                                                    <td>How many New Reviews do you generate monthly?</td>
                                                    <td>{inputData?.feedback?.['1e']}</td>
                                                    <td>{formatter.format(Number(rrmg1))}</td>
                                                    <td>{formatter.format(Number(cprf1))}</td>
                                                    {/* <td>{inputData?.feedback?.['1e'] * 2}</td>
                                                <td>
                                                    {
                                                        formatter.format(
                                                            (inputData?.feedback?.['1e'] * 2) * Number(cprf1)
                                                            +
                                                            ((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(formatter.format(inputData?.feedback?.['1b'])) - 200)
                                                            +
                                                            ((Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))) - ((inputData?.feedback?.['1e'] * 2) * 10))
                                                        )
                                                    }
                                                </td> */}
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white " rowSpan={2}>Reviews | Cost | Value</td>
                                                    <td className="text-white">{inputData?.feedback?.['1e']}</td>
                                                    <td className="text-white">{formatter.format(
                                                        (Number(inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) +
                                                        Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))
                                                    )}</td>
                                                    <td className="text-white">{formatter.format((
                                                        Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                    ))}</td>
                                                    {/* <td>
                                                    {
                                                        formatter.format(
                                                            100 +
                                                            Number((inputData?.feedback?.['1e'] * 2) * 10)
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        formatter.format(
                                                            (inputData?.feedback?.['1e'] * 2) *
                                                            Number(inputData?.feedback?.['1g'])
                                                        )
                                                    }
                                                </td> */}
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white">Reviews</td>
                                                    <td className="text-white">Cost</td>
                                                    <td className="text-white">Value</td>
                                                </tr>
                                                <tr>
                                                    <td>How do you measure and adjust the effectiveness of your  conversion efforts?  (Hours spent)</td>
                                                    <td>{inputData?.analysis_assesment?.['1a']}</td>
                                                    <td>{formatter.format(Number(inputData?.analysis_assesment?.['1c']))}</td>
                                                    <td>{formatter.format(Number(asd1))}</td>
                                                    {/* <td>$100</td>
                                                <td>{formatter.format(Number(asd1) - 100)}</td> */}
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white">Management Cost</td>
                                                    <td className="text-white">{inputData?.analysis_assesment?.['1a']}</td>
                                                    <td className="text-white">{formatter.format(Number(inputData?.analysis_assesment?.['1c']))}</td>
                                                    <td className="text-white">{formatter.format(Number(asd1))}</td>
                                                    {/* <td>$100</td>
                                                <td>{formatter.format(Number(asd1) - 100)}</td> */}
                                                </tr>
                                                <tr>
                                                    <td rowSpan={2} className="">Total Hours | Cost | Value</td>
                                                    <td>
                                                        {
                                                            Number(inputData?.effort_engage?.['1b']) +
                                                            Number(inputData?.effort_return_visit?.['1a']) +
                                                            Number(inputData?.feedback?.['1c']) +
                                                            Number(inputData?.analysis_assesment?.['1a'])
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            formatter.format(
                                                                Number(inputData?.marketing_assesment?.['1b']) +
                                                                Number(eed1) +
                                                                Number(inputData?.patient_engagement_tool?.['1b']) +
                                                                Number(Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a']) +
                                                                Number(rmd1) +
                                                                Number((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) +
                                                                Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])) +
                                                                Number(asd1)
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            formatter.format(
                                                                Number(Number(npb1) * Number(inputData?.new_patient?.['1d'])) +
                                                                Number((
                                                                    Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                                ))
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="bg-secondary">
                                                    <td className="text-white">Hours</td>
                                                    <td className="text-white">Cost</td>
                                                    <td className="text-white">Value</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>Current ROI</td>
                                                    <td>{
                                                        (((
                                                            Number(Number(npb1) * Number(inputData?.new_patient?.['1d'])) +
                                                            Number((
                                                                Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                            ))
                                                        ) / (
                                                                Number(inputData?.marketing_assesment?.['1b']) +
                                                                Number(eed1) +
                                                                Number(inputData?.patient_engagement_tool?.['1b']) +
                                                                Number(Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a']) +
                                                                Number(rmd1) +
                                                                Number((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) +
                                                                Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])) +
                                                                Number(asd1)
                                                            )) * 100).toFixed(2)
                                                    }%</td>
                                                    {/* <td>
                                                    {
                                                        (((
                                                            (((inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 2) * 650
                                                            +
                                                            (Number(eed1) - 100)) +
                                                            ((Number(inputData?.patient_engagement_tool?.['1b']) - 350) +
                                                            (Number(inputData?.effort_return_visit?.['1c']) - 300) +
                                                            (Number(rmd1) - 200)) +
                                                            (
                                                                (inputData?.feedback?.['1e'] * 2) * Number(cprf1)
                                                                +
                                                                ((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(formatter.format(inputData?.feedback?.['1b'])) - 200)
                                                                +
                                                                ((Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))) - ((inputData?.feedback?.['1e'] * 2) * 10))
                                                            ) +
                                                            (
                                                                (Number(asd1) - 100)
                                                            )
                                                        ) / (
                                                            1100 + 
                                                            (((inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 2) * 300) +
                                                            (inputData?.feedback?.['1e'] * 2) * 10
                                                        )) * 100).toFixed(2)
                                                    }%
                                                </td>
                                                <td>
                                                    {
                                                        formatter.format(
                                                            (
                                                                (((inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 2) * 650
                                                                +
                                                                (Number(eed1) - 100)) +
                                                                ((Number(inputData?.patient_engagement_tool?.['1b']) - 350) +
                                                                (Number(inputData?.effort_return_visit?.['1c']) - 300) +
                                                                (Number(rmd1) - 200)) +
                                                                (
                                                                    (inputData?.feedback?.['1e'] * 2) * Number(cprf1)
                                                                    +
                                                                    ((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(formatter.format(inputData?.feedback?.['1b'])) - 200)
                                                                    +
                                                                    ((Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))) - ((inputData?.feedback?.['1e'] * 2) * 10))
                                                                ) +
                                                                (
                                                                    (Number(asd1) - 100)
                                                                )
                                                            ) - 
                                                            (
                                                                (Number(inputData?.marketing_assesment?.['1c'] * inputData?.new_patient?.['1a']) * 650) +
                                                                (Number(rrmg1))
                                                            )
                                                        )
                                                    }
                                                </td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h4>Obseravations:</h4>
                                        <p><b>Current weakness:</b></p>
                                        <ul>
                                            <li>
                                                1. Marketing Strategy: The clinic's marketing efforts may lack focus or fail to resonate with the target audience,
                                                resulting in suboptimal return on investment (ROI).
                                            </li>
                                            <li>
                                                2. Redundant Tasks: The clinic may be bogged down by inefficient processes and redundant tasks, leading to
                                                wasted time and decreased productivity.
                                            </li>
                                            <li>
                                                3. Data Analysis: There may be a deficiency in analyzing marketing data and performance metrics, hindering
                                                the clinic's ability to make informed decisions and optimize strategies.
                                            </li>
                                            <li>
                                                4. Automation: The clinic may rely too heavily on manual processes, resulting in inefficiencies and increased
                                                labor costs.
                                            </li>
                                            <li>
                                                5. Patient Engagement: There may be challenges in engaging patients effectively, resulting in decreased
                                                retention rates and missed opportunities for growth.
                                            </li>
                                        </ul>
                                        <p><b>Possible Threats:</b></p>
                                        <ul>
                                            <li>1. Technology Disruption: Failure to adapt to emerging technologies or digital trends may result in
                                                obsolescence and loss of competitive advantage.</li>
                                            <li>2. Negative Reputation: Poor patient experiences or negative online reviews can damage the clinic's reputation
                                                and erode patient trust, impacting future growth and success.</li>
                                            <li>3. Digital Marketing Optimization: Failure to optimize digital marketing strategies may result in decreased
                                                visibility and competitiveness, leading to potential loss of market share to competitors who effectively
                                                leverage online channels.</li>
                                            <li>4. Workflow Automation: Resistance to implementing automation solutions may perpetuate inefficiencies and
                                                increase operational costs, putting the clinic at a disadvantage compared to competitors with streamlined
                                                processes.</li>
                                            <li>5. Patient Engagement Platforms: Neglecting to invest in patient engagement platforms could lead to reduced
                                                patient satisfaction and loyalty, resulting in higher patient turnover and decreased revenue.</li>
                                            <li>6. Data Analysis and Insights: Inability to leverage data analysis tools and insights may hinder the clinic's ability
                                                to adapt to changing patient needs and market trends, making it difficult to remain competitive in the
                                                healthcare landscape.</li>
                                            <li>7. Process Optimization: Failing to optimize internal processes may lead to decreased efficiency, higher costs,
                                                and diminished patient satisfaction, ultimately posing a threat to the clinic's long-term viability and success.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                    {
                        step === 5 &&
                        <>
                            <section className="sync-leas-sec px-30 pt-5" ref={handleAddRef}>
                                <div className="container">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <h4 className="mb-3">{agency?.notes}</h4>
                                        <a href={agency?.appUrl} target="_blank">Book appointment</a>
                                    </div>
                                </div>
                            </section>
                        </>
                    }
                    
                    {/* {
                        step === 5 &&
                        <>
                            <section className="sync-leas-sec px-30 pt-5" ref={handleAddRef}>
                                <div className="container">
                                    <div className="d-flex gap-4 justify-content-between">
                                        <div><b>Clinic name:</b> {clinicData?.name}</div>
                                        <div><b>PMS:</b> {watch('pms')}</div>
                                    </div>
                                    <div className="d-flex gap-4 justify-content-between">
                                        <div><b>City:</b> {clinicData?.city}</div>
                                        <div><b>Email:</b> {clinicData?.email}</div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header flex justify-content-between">
                                            <h5>Calculator</h5>
                                            <div><b>Date:</b> {moment().format('YYYY MMM, DD')}</div>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <tbody className="text-center">
                                                    <tr>
                                                        <td className="font-bold">Life time value <OverlayTrigger rootClose trigger="click" placement="auto" overlay={(
                                                            <Popover>
                                                                <>{`Patient LTV=Average Revenue per Visit×Number of Visits per Year×Patient Retention Period`}</>
                                                            </Popover>
                                                        )}><i className="bi bi-info-circle-fill"></i></OverlayTrigger></td>
                                                        <td>{inputData?.new_patient?.['1d']}</td>
                                                        <td className="font-bold">Branding <OverlayTrigger rootClose trigger="click" placement="auto" overlay={(
                                                            <Popover>
                                                                <>{`Value of each positive review = Total additional  revenue / Number of positive reviews`}</>
                                                            </Popover>
                                                        )}><i className="bi bi-info-circle-fill"></i></OverlayTrigger></td>
                                                        <td>{inputData?.feedback?.['1g']}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="table table-bordered" style={{
                                                border: '2px solid gray'
                                            }}>
                                                <thead className="bg-light text-center">
                                                    <tr>
                                                        <th rowSpan={2} className="">MANAGED MARKETING EVALUATION CALCULATOR</th>
                                                        <th colSpan={3}>Current Situation</th>
                                                        <th colSpan={3} style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>Managed Marketing</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Hours</th>
                                                        <th>Rate</th>
                                                        <th>Cost</th>
                                                        <th style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>Cost</th>
                                                        <th>Saving</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    <tr>
                                                        <td>Can you describe your digital marketing strategies and channels used to promote your dental services along with Budgets?  (Leads & Monthly $ Ad Spend)</td>
                                                        <td>{inputData?.marketing_assesment?.['1c']}</td>
                                                        <td>{formatter.format(Number(mad1))}</td>
                                                        <td>{formatter.format(inputData?.marketing_assesment?.['1b'])}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>{formatter.format(inputData?.marketing_assesment?.['1b'])}</td>
                                                        <td>{0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>How many hours are spent to engage and converting leads? (Hours spent)</td>
                                                        <td>{inputData?.effort_engage?.['1b']}</td>
                                                        <td>{formatter.format(inputData?.effort_engage?.['1c'])}</td>
                                                        <td>{formatter.format(Number(eed1))}</td>
                                                        <td rowSpan={2} className="" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$300</td>
                                                        <td rowSpan={2} className="">{formatter.format(Number(eed1) - 300)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>How many new patients does your practice convert  from the leads you get?  (Conversion Rate)</td>
                                                        <td>{Number(npb1)}</td>
                                                        <td>{inputData?.new_patient?.['1a']}%</td>
                                                        <td>{formatter.format(Number(inputData?.marketing_assesment?.['1b']) + Number(eed1))}</td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white " rowSpan={2}>New patient | Acquisition Cost | Value</td>
                                                        <td className="text-white">{Number(npb1)}</td>
                                                        <td className="text-white">{formatter.format((Number(Number(inputData?.marketing_assesment?.['1b']) + Number(eed1))) / Number(npb1))}</td>
                                                        <td className="text-white">{formatter.format(Number(npb1) * Number(inputData?.new_patient?.['1d']))}</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>{Number(npb1) * 2}</td>
                                                        <td className="text-white">{formatter.format(
                                                            (Number(npb1) * 2) * Number(inputData?.new_patient?.['1d'])
                                                            +
                                                            (Number(eed1) - 300)
                                                        )}
                                                        </td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white">Patients</td>
                                                        <td className="text-white">Cost</td>
                                                        <td className="text-white">Value</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>Patients</td>
                                                        <td className="text-white">Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Are you using any specific patient engagement software’s or programs at your practice? (Cost )</td>
                                                        <td>1</td>
                                                        <td>{formatter.format(Number(inputData?.patient_engagement_tool?.['1b']))}</td>
                                                        <td>{formatter.format(Number(inputData?.patient_engagement_tool?.['1b']))}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$350</td>
                                                        <td>{formatter.format((Number(inputData?.patient_engagement_tool?.['1b']) - 350))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can you provide insights into your efforts ( Hours spent) to encourage return visits including follow-ups, confirmations , recall and rescheduling cancelled and missed appointments? </td>
                                                        <td>{inputData?.effort_return_visit?.['1a']}</td>
                                                        <td>{formatter.format(Number(inputData?.effort_return_visit?.['1b']))}</td>
                                                        <td>{formatter.format(Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a'])}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$300</td>
                                                        <td>{formatter.format((Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a']) - 300)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can you provide an overview of the staff members responsible for managing marketing efforts and patient engagement initiatives like email marketing promoting restorative services?  (Hours) spent)</td>
                                                        <td>{inputData?.responsibility_marketing?.['1b']}</td>
                                                        <td>{formatter.format(Number(inputData?.responsibility_marketing?.['1c']))}</td>
                                                        <td>{formatter.format(Number(rmd1))}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$200</td>
                                                        <td>{formatter.format((Number(rmd1) - 200))}</td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white " rowSpan={2}>Engagement Hours | Rate | Cost</td>
                                                        <td className="text-white">{Number(inputData?.effort_return_visit?.['1a']) + Number(inputData?.responsibility_marketing?.['1b'])}</td>
                                                        <td className="text-white">{formatter.format(
                                                            (
                                                                Number(Number(inputData?.patient_engagement_tool?.['1b'])) +
                                                                (Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) +
                                                                Number(Number(rmd1))
                                                            )
                                                            /
                                                            (
                                                                Number(inputData?.effort_return_visit?.['1a']) +
                                                                Number(inputData?.responsibility_marketing?.['1b'])
                                                            )
                                                        )}</td>
                                                        <td className="text-white">{formatter.format((
                                                            Number(Number(inputData?.patient_engagement_tool?.['1b'])) +
                                                            (Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) +
                                                            Number(Number(rmd1))
                                                        ))}</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$850</td>
                                                        <td className="text-white">
                                                            {
                                                                formatter.format((Number(inputData?.patient_engagement_tool?.['1b']) - 350) +
                                                                    ((Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) - 300) +
                                                                    (Number(rmd1) - 200))
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white">Hours</td>
                                                        <td className="text-white">Rate</td>
                                                        <td className="text-white">Cost</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>Cost</td>
                                                        <td className="text-white">Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Do you use any review software and what is its cost?</td>
                                                        <td>{inputData?.feedback?.['1a'] === 'software' ? '1' : '0'}</td>
                                                        <td>{formatter.format(inputData?.feedback?.['1b'])}</td>
                                                        <td>{formatter.format((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b']))}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$100</td>
                                                        <td>{formatter.format(((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) - 100)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>How do you handle online reviews and feedback from patients? (Time Spent)</td>
                                                        <td>{inputData?.feedback?.['1c']}</td>
                                                        <td>{formatter.format(inputData?.feedback?.['1d'])}</td>
                                                        <td>{formatter.format(Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])))}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>{formatter.format((inputData?.feedback?.['1e'] * 2) * 10)}</td>
                                                        <td>{formatter.format(((Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))) - ((inputData?.feedback?.['1e'] * 2) * 10)))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>How many New Reviews do you generate monthly?</td>
                                                        <td>{inputData?.feedback?.['1e']}</td>
                                                        <td>{formatter.format(Number(rrmg1))}</td>
                                                        <td>{formatter.format(Number(cprf1))}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>{inputData?.feedback?.['1e'] * 2}</td>
                                                        <td>
                                                            {
                                                                formatter.format(
                                                                    (inputData?.feedback?.['1e'] * 2) * Number(rrmg1)
                                                                    +
                                                                    ((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b']) - 100)
                                                                    +
                                                                    ((Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))) - ((inputData?.feedback?.['1e'] * 2) * 10))
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white " rowSpan={2}>Reviews | Cost | Value</td>
                                                        <td className="text-white">{inputData?.feedback?.['1e']}</td>
                                                        <td className="text-white">{formatter.format(
                                                            (Number(inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) +
                                                            Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d']))
                                                        )}</td>
                                                        <td className="text-white">{formatter.format((
                                                            Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                        ))}</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>
                                                            {
                                                                formatter.format(
                                                                    100 +
                                                                    Number((inputData?.feedback?.['1e'] * 2) * 10)
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-white">
                                                            {
                                                                formatter.format(
                                                                    (inputData?.feedback?.['1e'] * 2) *
                                                                    Number(inputData?.feedback?.['1g'])
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white">Reviews</td>
                                                        <td className="text-white">Cost</td>
                                                        <td className="text-white">Value</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>Cost</td>
                                                        <td className="text-white">Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td>How do you measure and adjust the effectiveness of your  conversion efforts?  (Hours spent)</td>
                                                        <td>{inputData?.analysis_assesment?.['1a']}</td>
                                                        <td>{formatter.format(Number(inputData?.analysis_assesment?.['1c']))}</td>
                                                        <td>{formatter.format(Number(asd1))}</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$100</td>
                                                        <td>{formatter.format(Number(asd1) - 100)}</td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white">Management Cost</td>
                                                        <td className="text-white">{inputData?.analysis_assesment?.['1a']}</td>
                                                        <td className="text-white">{formatter.format(Number(inputData?.analysis_assesment?.['1c']))}</td>
                                                        <td className="text-white">{formatter.format(Number(asd1))}</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>$100</td>
                                                        <td className="text-white">{formatter.format(Number(asd1) - 100)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td rowSpan={2} className="">Total Hours | Cost | Value</td>
                                                        <td>
                                                            {
                                                                Number(inputData?.effort_engage?.['1b']) +
                                                                Number(inputData?.effort_return_visit?.['1a']) +
                                                                Number(inputData?.feedback?.['1c']) +
                                                                Number(inputData?.analysis_assesment?.['1a'])
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                formatter.format(
                                                                    Number(inputData?.marketing_assesment?.['1b']) +
                                                                    Number(eed1) +
                                                                    Number(inputData?.patient_engagement_tool?.['1b']) +
                                                                    Number(Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a']) +
                                                                    Number(rmd1) +
                                                                    Number((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) +
                                                                    Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])) +
                                                                    Number(asd1)
                                                                )
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                formatter.format(
                                                                    Number(Number(npb1) * Number(inputData?.new_patient?.['1d'])) +
                                                                    Number((
                                                                        Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                                    ))
                                                                )
                                                            }
                                                        </td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>
                                                            {
                                                                formatter.format(
                                                                    Number(inputData?.marketing_assesment?.['1b']) +
                                                                    850 +
                                                                    Number(100 + Number((inputData?.feedback?.['1e'] * 2) * 10)) +
                                                                    100
                                                                )
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                formatter.format(
                                                                    Number((
                                                                        (Number(npb1) * 2) * Number(inputData?.new_patient?.['1d'])
                                                                        +
                                                                        (Number(eed1) - 300)
                                                                    )) +
                                                                    Number(((Number(inputData?.patient_engagement_tool?.['1b']) - 350) +
                                                                        ((Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) - 300) +
                                                                        (Number(rmd1) - 200))) +
                                                                    Number((
                                                                        (inputData?.feedback?.['1e'] * 2) *
                                                                        Number(inputData?.feedback?.['1g'])
                                                                    )) +
                                                                    Number((Number(asd1) - 100))
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr className="bg-secondary">
                                                        <td className="text-white">Hours</td>
                                                        <td className="text-white">Cost</td>
                                                        <td className="text-white">Value</td>
                                                        <td className="text-white" style={{
                                                            borderLeft: '2px solid gray'
                                                        }}>Cost</td>
                                                        <td className="text-white">Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}><b>ROI Increase & Value Addition</b></td>
                                                        <td>{
                                                            (((
                                                                Number(Number(npb1) * Number(inputData?.new_patient?.['1d'])) +
                                                                Number((
                                                                    Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                                ))
                                                            ) / (
                                                                    Number(inputData?.marketing_assesment?.['1b']) +
                                                                    Number(eed1) +
                                                                    Number(inputData?.patient_engagement_tool?.['1b']) +
                                                                    Number(Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a']) +
                                                                    Number(rmd1) +
                                                                    Number((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) +
                                                                    Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])) +
                                                                    Number(asd1)
                                                                )) * 100).toFixed(2)
                                                        }%</td>
                                                        <td style={{
                                                            borderLeft: '2px solid gray'
                                                        }}></td>
                                                        <td>
                                                            {
                                                                ((
                                                                    (
                                                                        Number((
                                                                            (Number(npb1) * 2) * Number(inputData?.new_patient?.['1d'])
                                                                            +
                                                                            (Number(eed1) - 300)
                                                                        )) +
                                                                        Number(((Number(inputData?.patient_engagement_tool?.['1b']) - 350) +
                                                                            ((Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) - 300) +
                                                                            (Number(rmd1) - 200))) +
                                                                        Number((
                                                                            (inputData?.feedback?.['1e'] * 2) *
                                                                            Number(inputData?.feedback?.['1g'])
                                                                        )) +
                                                                        Number((Number(asd1) - 100))
                                                                    )
                                                                    /
                                                                    (
                                                                        Number(inputData?.marketing_assesment?.['1b']) +
                                                                        850 +
                                                                        Number(100 + Number((inputData?.feedback?.['1e'] * 2) * 10)) +
                                                                        100
                                                                    )
                                                                ) * 100).toFixed(2)
                                                            }%
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="table table-bordered text-center">
                                                <thead className="bg-light text-center">
                                                    <tr>
                                                        <th colSpan={3}>Summary of Benefits</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{
                                                            width: '70%'
                                                        }} className="text-left">Budget</td>
                                                        <td className="text-right">
                                                            {
                                                                formatter.format(
                                                                    (
                                                                        Number(Number(npb1) * Number(inputData?.new_patient?.['1d'])) +
                                                                        Number((
                                                                            Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                                        ))
                                                                    ) -
                                                                    (
                                                                        Number(inputData?.marketing_assesment?.['1b']) +
                                                                        850 +
                                                                        Number(100 + Number((inputData?.feedback?.['1e'] * 2) * 10)) +
                                                                        100
                                                                    )
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-right">Savings</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{
                                                            width: '70%'
                                                        }} className="text-left">Time Saved</td>
                                                        <td className="text-right">
                                                            {
                                                                Number(inputData?.effort_engage?.['1b']) +
                                                                Number(inputData?.effort_return_visit?.['1a']) +
                                                                Number(inputData?.feedback?.['1c']) +
                                                                Number(inputData?.analysis_assesment?.['1a'])
                                                            }
                                                        </td>
                                                        <td className="text-right">Hours</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{
                                                            width: '70%'
                                                        }} className="text-left">Resource Cost</td>
                                                        <td className="text-right">
                                                            {
                                                                formatter.format(Number(eed1) +
                                                                    Number((Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a'])) +
                                                                    Number((Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])))) +
                                                                    Number(asd1))
                                                            }
                                                        </td>
                                                        <td className="text-right">Savings</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{
                                                            width: '70%'
                                                        }} className="text-left">Increased Reputation</td>
                                                        <td className="text-right">
                                                            {
                                                                formatter.format((
                                                                    (inputData?.feedback?.['1e'] * 2) *
                                                                    Number(inputData?.feedback?.['1g'])
                                                                ) -
                                                                    ((
                                                                        Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                                    )))
                                                            }
                                                        </td>
                                                        <td className="text-right">Value</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{
                                                            width: '70%'
                                                        }} className="text-left">Increase in ROI</td>
                                                        <td className="text-right">
                                                            {
                                                                (-((((
                                                                    Number(Number(npb1) * Number(inputData?.new_patient?.['1d'])) +
                                                                    Number((
                                                                        Number(inputData?.feedback?.['1e']) * Number(inputData?.feedback?.['1g'])
                                                                    ))
                                                                ) / (
                                                                        Number(inputData?.marketing_assesment?.['1b']) +
                                                                        Number(eed1) +
                                                                        Number(inputData?.patient_engagement_tool?.['1b']) +
                                                                        Number(Number(inputData?.effort_return_visit?.['1b']) * inputData?.effort_return_visit?.['1a']) +
                                                                        Number(rmd1) +
                                                                        Number((inputData?.feedback?.['1a'] === 'software' ? 1 : 0) * Number(inputData?.feedback?.['1b'])) +
                                                                        Number(inputData?.feedback?.['1c'] * Number(inputData?.feedback?.['1d'])) +
                                                                        Number(asd1)
                                                                    )) * 100)) + ((
                                                                        (
                                                                            Number((
                                                                                (Number(npb1) * 2) * Number(inputData?.new_patient?.['1d'])
                                                                                +
                                                                                (Number(eed1) - 300)
                                                                            )) +
                                                                            Number(((Number(inputData?.patient_engagement_tool?.['1b']) - 350) +
                                                                                ((Number(inputData?.effort_return_visit?.['1b']) * Number(inputData?.effort_return_visit?.['1a'])) - 300) +
                                                                                (Number(rmd1) - 200))) +
                                                                            Number((
                                                                                (inputData?.feedback?.['1e'] * 2) *
                                                                                Number(inputData?.feedback?.['1g'])
                                                                            )) +
                                                                            Number((Number(asd1) - 100))
                                                                        )
                                                                        /
                                                                        (
                                                                            Number(inputData?.marketing_assesment?.['1b']) +
                                                                            850 +
                                                                            Number(100 + Number((inputData?.feedback?.['1e'] * 2) * 10)) +
                                                                            100
                                                                        )
                                                                    ) * 100)).toFixed(2)
                                                            }%
                                                        </td>
                                                        <td className="text-right">% Increase</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="sync-leas-sec px-30 pt-5" ref={handleAddRef}>
                                <div className="container">
                                    <p className="fs-5"><b>Benefits for the Practice:</b></p>
                                    <ul className="fs-6">
                                        <li>1. Improved Efficiency: By evaluating the costs and effectiveness you can optimize resource allocation and
                                            increase operational efficiency and improved patient care and relationship.</li>
                                        <li>2. Enhanced Patient Acquisition: With better conversion rates and reduced effort involved in lead engagement
                                            enables you to improve response rates resulting in a higher influx of new patients and improved revenue
                                            generation.</li>
                                        <li>3. Better Patient Engagement: By reducing time and resources in patient engagement initiatives, such as
                                            follow-ups and online reviews management, you can foster stronger relationships with existing patients,
                                            leading to higher retention rates and increased patient satisfaction.</li>
                                        <li>
                                            4. Maximized Return on Investment (ROI): Through the ROI evaluation calculator, you can quantify the return
                                            on investment for each aspect of the practice's operations, enabling you to prioritize and allocate resources
                                            effectively for maximum profitability.
                                        </li>
                                    </ul>
                                    <p className="fs-5"><b>Benefits for the Patients:</b></p>
                                    <ul className="pb-2 fs-6">
                                        <li>1. Enhanced Experience: Patients benefit from streamlined processes and improved communication resulting
                                            from the practice's investment in efficient patient engagement tools, leading to a more seamless and
                                            satisfying experience.</li>
                                        <li>2. More Accessible Services: Effective digital marketing strategies ensure that patients are aware of the
                                            practice's services and can easily access information about treatments and appointments, facilitating better
                                            decision-making regarding their oral health.</li>
                                        <li>3. Personalized Engagement: Patients receive personalized follow-ups, reminders, and communications,
                                            demonstrating the practice's commitment to their well-being and fostering a sense of care and trust
                                            between patients and staff.</li>
                                        <li>4. Quality Care: By actively managing online reviews and feedback, the practice ensures that patient concerns
                                            are addressed promptly, leading to continuous improvement in service quality and patient satisfaction.</li>
                                        <li>5. Patient Loyalty: Our loyalty program motivates patients to pro-actively engage with your practice eg.
                                            confirming appointments, staying active and maximizing their insurance.</li>
                                    </ul>
                                </div>
                            </section>
                        </>
                    } */}
                    {
                        step !== 5 &&
                        <section className="sync-leas-sec px-30">
                            <div className={"buttons d-flex " + (step === 0 ? 'justify-content-end' : 'justify-content-between')}>
                                {
                                    step !== 0 &&
                                    <button className="btn btn-secondary" onClick={() => {
                                        setStep(step - 1);
                                    }}>Previous</button>
                                }
                                {
                                    <button className="btn btn-primary" onClick={async() => {
                                        if (step === 0) {
                                            if (inputData?.marketing_assesment?.['1b'] === '' || !inputData?.marketing_assesment?.['1b']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.effort_engage?.['1a'] === '' || !inputData?.effort_engage?.['1a']) {
                                                toast.error('Please input required fields', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.effort_engage?.['1b'] === '' || !inputData?.effort_engage?.['1b']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.effort_engage?.['1c'] === '' || !inputData?.effort_engage?.['1c']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.new_patient?.['1d'] === '' || !inputData?.new_patient?.['1d']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            await roiCalcAdd({
                                                roiId: clinicData?.roiId,
                                                [`step${step + 1}`]: JSON.stringify({marketing_assesment: inputData?.marketing_assesment, effort_engage: inputData?.effort_engage, new_patient: inputData?.new_patient }),
                                                step: String(step + 1)
                                            });
                                        }
                                        if (step === 1) {
                                            if (inputData?.effort_return_visit?.['1a'] === '' || !inputData?.effort_return_visit?.['1a']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.effort_return_visit?.['1b'] === '' || !inputData?.effort_return_visit?.['1b']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.responsibility_marketing?.['1a'] === '' || !inputData?.responsibility_marketing?.['1a']) {
                                                toast.error('Please input required fields.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.responsibility_marketing?.['1b'] === '' || !inputData?.responsibility_marketing?.['1b']) {
                                                toast.error('Please input required fields.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.responsibility_marketing?.['1c'] === '' || !inputData?.responsibility_marketing?.['1c']) {
                                                toast.error('Please input required fields.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            await roiCalcAdd({
                                                roiId: clinicData?.roiId,
                                                [`step${step + 1}`]: JSON.stringify({ patient_engagement_tool: inputData?.patient_engagement_tool, effort_return_visit: inputData?.effort_return_visit, responsibility_marketing: inputData?.responsibility_marketing }),
                                                step: String(step + 1)
                                            });
                                        }
                                        if (step === 2) {
                                            if (inputData?.feedback?.['1c'] === '' || !inputData?.feedback?.['1c']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.feedback?.['1e'] === '' || !inputData?.feedback?.['1e']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            if (inputData?.feedback?.['1g'] === '' || !inputData?.feedback?.['1g']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            await roiCalcAdd({
                                                roiId: clinicData?.roiId,
                                                [`step${step + 1}`]: JSON.stringify({ feedback: inputData?.feedback }),
                                                step: String(step + 1)
                                            });
                                        }
                                        if (step === 3) {
                                            if (inputData?.analysis_assesment?.['1a'] === '' || !inputData?.analysis_assesment?.['1a']) {
                                                toast.error('Please input required fields, if not applicable add 0.', {
                                                    autoClose: false
                                                });
                                                return;
                                            }
                                            await roiCalcAdd({
                                                roiId: clinicData?.roiId,
                                                [`step${step + 1}`]: JSON.stringify({ analysis_assesment: inputData?.analysis_assesment}),
                                                step: String(step + 1)
                                            });
                                        }
                                        setStep(step + 1);
                                    }}>{step === 3 ? 'Calculate current ROI' : step === 4 ? 'Submit to improve your ROI with Managed Marketing' : 'Next'}</button>
                                }
                                {/* {
                                    step === 5 &&
                                    <button className="btn btn-secondary" onClick={() => {
                                        navigate('/')
                                    }}>Close</button>
                                } */}
                                {/* {
                                    step === 5 &&
                                    <button disabled={loadingPdf} className="btn btn-primary" onClick={async () => {
                                        setLoadingPdf(true);
                                        const pdf = new jsPDF('p', 'mm', 'a4');
                                        const options = {
                                            scale: 2
                                        };
                                        for (let i = 0; i < divRefs.current.length; i++) {
                                            const canvas = await html2canvas(divRefs.current[i], options);
                                            const imgData = canvas.toDataURL('image/png');
                                            const imgProps = pdf.getImageProperties(imgData);
                                            const pdfWidth = pdf.internal.pageSize.getWidth();
                                            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                                            pdf.setFontSize(10);
                                            if (i > 0) {
                                                pdf.addPage();
                                            }
                                            if (user?.agency?.base64logo) {
                                                var img = new Image()
                                                const boxWidth = 50;
                                                const boxHeight = 50;

                                                img.src = `data:image/png;base64,${user?.agency?.base64logo}`;
                                                img.onload = function () {
                                                    let width = img.width;
                                                    let height = img.height;

                                                    const aspectRatio = width / height;
                                                    const boxAspectRatio = boxWidth / boxHeight;

                                                    if (aspectRatio > boxAspectRatio) {
                                                        // image is wider relative to the box
                                                        width = boxWidth;
                                                        height = boxWidth / aspectRatio;
                                                    } else {
                                                        height = boxHeight;
                                                        width = boxHeight * aspectRatio;
                                                    }

                                                    pdf.addImage(img, 'png', 10, 5, width, height);
                                                }
                                            }
                                            pdf.addImage(imgData, 'PNG', 0, 15, pdfWidth, pdfHeight);
                                            pdf.text('Copyright @ Data-Driven.io ' + new Date().getFullYear(), (pdfWidth / 2) - 25, pdfHeight + 40);
                                        }
                                        setTimeout(() => {
                                            pdf.save(`roicalc_${moment().format("DD-MM-YYYY")}.pdf`);
                                            setLoadingPdf(false);
                                        }, 2000);
                                        return;
                                        toPDF({
                                            method: 'open',
                                            resolution: Resolution.HIGH,
                                            page: {
                                                margin: Margin.LARGE,
                                                format: 'a4',
                                                orientation: 'portrait',
                                            },
                                            canvas: {
                                                mimeType: 'image/png',
                                                qualityRatio: 1
                                            },
                                            overrides: {
                                                pdf: {
                                                    compress: true
                                                },
                                                canvas: {
                                                    useCORS: false,
                                                    height: 1000
                                                },
                                            },
                                        });
                                    }}>{loadingPdf ? 'Processing...' : 'Print'}</button>
                                } */}
                            </div>
                        </section>
                    }
                </>
            }
        </>
    )
}