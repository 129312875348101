import { useEffect, useMemo, useState } from "react"
import { getReviewCountSource, getReviewList, getReviewRatingDashboard, getSentimentListTable, getSentiments } from "../../services/newdashboard";
import { useAppSelector } from "../../store/hook";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Table } from "antd";
import { uniqBy } from "lodash";
import { numFormatter } from "../../helpers/currency";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const SentimentDetailDashboard = () => {
  const [searchParams] = useSearchParams();
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [selectedClinic, setSelectedClinic] = useState(searchParams.get('clinic') ? searchParams.get('clinic') : sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic')!== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const clinicData = clinics?.find((item: any) => item.uniqueId === selectedClinic);
  const [list, setList] = useState([]);
  const [sentimentsData, setSentimentsData] = useState<any>();
  const navigate = useNavigate();
  const month = useMemo(() => {
    let retLabel = 0;
    switch (searchParams.get('filter')) {
      case 'thismonths':
        retLabel = 0;
        break;
      case '1months':
        retLabel = 1;
        break;
      case '3months':
        retLabel = 3;
        break;
      case '6months':
        retLabel = 6;
        break;
      case '9months':
        retLabel = 9;
        break;
      case '12months':
        retLabel = 12;
        break;
    }
    return retLabel;
  }, [searchParams]);
  const [dateFilter, setDateFilter] = useState({
    startDate: month ? moment().subtract(month, 'months').startOf("month").format("YYYY-MM-DD") : moment().startOf("month").format("YYYY-MM-DD"),
    endDate: month ? moment().subtract(1, 'month').endOf("month").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
  });
  const [selectedQuater, setSelectedQuater] = useState(searchParams.get('filter') ? (searchParams.get('filter') ?? 'thismonths') : 'thismonths');
  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }
  useEffect(() => {
    if (selectedClinic !== undefined) {
      getData();
    }
  }, [selectedClinic, dateFilter]);
  const getData = () => {
    getSentiments(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`).then((success) => {
      setSentimentsData(success);
    }).catch((error) => {
      console.log(error);
    });
    getSentimentListTable(selectedClinic, `?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`).then((success) => {
      setList(success?.data);
    });
  };

  const filterLabel = useMemo(() => {
    let retLabel = '';
    switch (selectedQuater) {
      case 'thismonths':
        retLabel = 'This month';
        break;
      case '1months':
        retLabel = 'Last month';
        break;
      case '3months':
        retLabel = 'Last 3 months';
        break;
      case '6months':
        retLabel = 'Last 6 months';
        break;
      case '9months':
        retLabel = 'Last 9 months';
        break;
      case '12months':
        retLabel = 'Last 12 months';
        break;
    }
    return retLabel;
  }, [selectedQuater]);

  const sourceFilters = uniqBy(list?.map((item: any) => ({ text: item.source, value: item.source })), 'value');
  const categoryFilters = uniqBy(list?.map((item: any) => ({ text: item.category, value: item.category })), 'value');
  const scoreFilters = uniqBy(list?.map((item: any) => ({ text: item.scoreSentiment, value: item.scoreSentiment })), 'value');

  const sourceIcon = (text: any) => {
    if (text === 'google') {
      return (<i className="bi bi-google"></i>);
    } else if (text === 'facebook') {
      return (<i className="bi bi-facebook"></i>);
    } else if (text === 'instagram') {
      return (<i className="bi bi-instagram"></i>);
    } else if (text?.toLowerCase() === 'xcare') {
      return (<img src={require('../../assets/images/xcare-api-icon.png')} width={14} height={14}></img>);
    }
    return ( <p>{text}</p>)
  }

  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'date',
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value: string, record: any) => record.source.indexOf(value) === 0,
      sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
      // sortDirections: ['descend'],
    },
    {
      title: 'Source',
      dataIndex: 'source',
      filters: sourceFilters,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value: string, record: any) => record.source.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.source.length - b.source.length,
      // sortDirections: ['descend'],
      render: (text: any) => (
        <p>
          {sourceIcon(text)}
        </p>
      ),
    },
    {
      title: 'Sentiment',
      dataIndex: 'scoreSentiment',
      defaultSortOrder: 'descend',
      filters: scoreFilters,
      onFilter: (value: string, record: any) => record.scoreSentiment.indexOf(value) === 0,
      // sortDirections: ['descend'],
      sorter: (a: any, b: any) => a.scoreSentiment.localeCompare(b.scoreSentiment),
    },
    {
      title: 'Review Text',
      dataIndex: 'reviewText',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      filters: categoryFilters,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value: string, record: any) => record.category.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.category.length - b.category.length,
      // sortDirections: ['descend'],
    },
  ];

  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  return (
    <>
      <section className="middle-navbar py-4 px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <button className="me-2" onClick={() => {
                  let url = '/newdashboard?';
                  if (searchParams.get('clinic')) {
                    url += 'clinic=' + searchParams.get('clinic');
                  }
                  if (searchParams.get('filter')) {
                    url += '&filter=' + searchParams.get('filter');
                  }
                  navigate(url)
                }}><i className="bi bi-arrow-left"></i></button>
                <div className="nav-title-txt m-0">Customer Sentiments</div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="d-flex gap-3 align-items-center">
                <div className="dashb-filters">
                  <ReactDatePicker
                    className="form-control col-2"
                    selected={moment(dateFilter?.startDate).toDate()}
                    onChange={(date: any) => {
                      setDateFilter({
                        startDate: moment(date).startOf("month").format("YYYY-MM-DD"),
                        endDate: moment(date).endOf("month").format("YYYY-MM-DD")
                      });
                    }}
                    showMonthYearPicker
                    dateFormat="MMM/yyyy"
                  />
                  <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    setSelectedQuater(e.target.value);
                    switch (e.target.value) {
                      case 'thismonths':
                        setDateFilter({
                          startDate: moment().startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().format("YYYY-MM-DD")
                        });
                        break;
                      case '1months':
                        setDateFilter({
                          startDate: moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '3months':
                        setDateFilter({
                          startDate: moment().subtract(3, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '6months':
                        setDateFilter({
                          startDate: moment().subtract(6, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '9months':
                        setDateFilter({
                          startDate: moment().subtract(9, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                      case '12months':
                        setDateFilter({
                          startDate: moment().subtract(12, 'months').startOf("month").format("YYYY-MM-DD"),
                          endDate: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
                        });
                        break;
                    }
                  }} value={selectedQuater}>
                    <option value="thismonths">This Month</option>
                    <option value="1months">Last Month</option>
                    <option value="3months">Last 3 Months</option>
                    <option value="6months">Last 6 Months</option>
                    <option value="9months">Last 9 Months</option>
                    <option value="12months">Last 12 Months</option>
                  </select>
                </div>

                <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                  setSelectedClinic(e.target.value);
                  sessionStorage.setItem('selectedClinic', e.target.value);
                }}>
                  {
                    clinics?.map((item: any) => {
                      return (
                        <option value={item.uniqueId}>{item.name}</option>
                      )
                    })
                  }
                </select>

                {
                  user?.role !== "Read Only" &&
                  <div>
                    <button onClick={() => {
                      navigate('/api-connection')
                    }}><i className="bi bi-gear"></i></button>
                  </div>
                }
              </div>
              <small className="pt-1 text-success">From {dateFilter?.startDate} to {dateFilter?.endDate}</small>
            </div>
          </div>
        </div>
      </section>

      <section className="graphs px-30 pt-5 pb-5">
        <div className="container">
          <div className="row">

            <div className="col-12 mb-5">
              <div className="card sentiment-section mb-4">
                <div className="card-header d-flex justify-content-between align-items-center py-3">
                  <h6 className="m-0">Customer Sentiment</h6>
                  {/* <div>
                    <button type="button" className="btn btn-link pe-0">View full report</button>
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="row mb-3 d-flex justify-content-between">
                    <div className="col-4">
                      <div className="graph-layout" style={{ height: '250px' }} dangerouslySetInnerHTML={{ __html: sentimentsData?.positive }}>
                        {/* Graph here */}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="graph-matrix-grid row d-flex gap-0 justify-content-center">
                      <div className="col-md-4 pb-3 px-2">
                        <div className="graph-matrix-grid-item">
                          <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                            <div className="graph-matrix-grid-lbl"><b>Staff</b></div>
                          </div>
                          <div className="graph-matrix-grid-number">
                            {!isNaN(sentimentsData?.category?.Staff) ? numFormatter.format(sentimentsData?.category?.Staff) : '-'}
                          </div>
                        </div>
                        </div>

                        <div className="col-md-4 pb-3 px-2">
                        <div className="graph-matrix-grid-item">
                          <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                            <div className="graph-matrix-grid-lbl"><b>Provider</b></div>
                          </div>
                          <div className="graph-matrix-grid-number">
                            {!isNaN(sentimentsData?.category?.Provider) ? numFormatter.format(sentimentsData?.category?.Provider) : '-'}
                          </div>
                        </div>
                        </div>

                        <div className="col-md-4 pb-3 px-2">
                        <div className="graph-matrix-grid-item">
                          <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                            <div className="graph-matrix-grid-lbl"><b>Service</b></div>
                          </div>
                          <div className="graph-matrix-grid-number">
                            {!isNaN(sentimentsData?.category?.Service) ? numFormatter.format(sentimentsData?.category?.Service) : '-'}
                          </div>
                        </div>
                        </div>

                        <div className="col-md-4 pb-3 px-2">
                        <div className="graph-matrix-grid-item">
                          <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                            <div className="graph-matrix-grid-lbl"><b>Cost</b></div>
                          </div>
                          <div className="graph-matrix-grid-number">
                            {!isNaN(sentimentsData?.category?.Cost) ? numFormatter.format(sentimentsData?.category?.Cost) : '-'}
                          </div>
                        </div>
                        </div>

                        <div className="col-md-4 pb-3 px-2">
                        <div className="graph-matrix-grid-item">
                          <div className="graph-matrix-grid-header d-flex justify-content-between align-items-center">
                            <div className="graph-matrix-grid-lbl"><b>General</b></div>
                          </div>
                          <div className="graph-matrix-grid-number">
                            {!isNaN(sentimentsData?.category?.General) ? numFormatter.format(sentimentsData?.category?.General) : '-'}
                          </div>
                        </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-4">
                      <div className="graph-layout" style={{ height: '250px' }} dangerouslySetInnerHTML={{ __html: sentimentsData?.negative }}>
                        {/* Graph here */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="px-30 pb-5">
        <div className="container">
          <div className="row">

            <div className="col-12 mb-5">
              <div className="card reviews-section">
                <div className="card-header d-flex justify-content-between align-items-center py-3">
                  <h6 className="m-0">List</h6>
                </div>
                <div className="client-table-details-sec card-body">
                  <div className="row">
                    <Table className="p-0" columns={columns} dataSource={list} />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}