import { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addAccountUser, deleteAccountUser, fetchUserProfile, updateUserProfile } from "../../services/user";
import { useAppSelector } from "../../store/hook";
import { addUserProfile } from "../../store/user/userSlice";
import { phoneValidator } from "../../utils/validators";

export const Users = () => {
  const dispatch = useDispatch();
  const [showAddNewUser, setShowAddNewUser] = useState(false);
  const [userList, setUserList] = useState([]);
  const user = useAppSelector((state) => state.user.user);
  const profile = useAppSelector((state) => state.user.profile);
  useEffect(() => {
    setUserList(profile?.users);
  }, [profile]);
  const refetchList = () => {
    fetchUserProfile(user?.uniqueId).then((success) => {
      dispatch(addUserProfile(success));
    }).catch((error) => {
      setUserList([]);
    })
  }
  return (
    <>
      {
        !showAddNewUser &&
        <section className="profile-details-sec px-30 pt-4">
          <div className="container">

            <div className="row">

              <div className="d-flex mb-3 pb-3 border-bottom px-0 align-items-center justify-content-between">
                <h6 className="fs-5 m-0">Users</h6>
                {
                  (user?.role == "Account Owner" || user?.role == "Standard") &&
                  <div className="col-auto gap-2 d-flex">
                    <button className="btn btn-primary" type="submit" onClick={() => {
                      setShowAddNewUser(true);
                    }}>Add new user</button>
                  </div>
                }
              </div>

            </div>


            <div className="row">
              <table className="table user-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email address</th>
                    <th scope="col" className="col-2">Role</th>
                    {
                      user?.role == "Account Owner" || user?.role == "Standard" &&
                      <th scope="col" className="col-2 text-right">Delete?</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    userList?.map((userItem: any) => {
                      const handleDelete = () => {
                        deleteAccountUser(userItem?.uniqueId, user?.uniqueId).then((success: any) => {
                          toast.success(success?.status?.msg);
                          refetchList();
                        }).catch((error: any) => {
                          toast.error(error?.msg);
                        });
                      };
                      const handleRoleChange = (e: any) => {
                        updateUserProfile({
                          type: "role",
                          role: e.target.value,
                          id: userItem?.uniqueId
                        }, user?.uniqueId).then((success: any) => {
                          toast.success(success?.status?.msg);
                          refetchList();
                        }).catch((error: any) => {
                          toast.error(error?.msg);
                        });
                      }
                      if (user?.role === "Read Only" && !(userItem?.role === "Account Owner" || userItem?.uniqueId === user?.uniqueId)) {
                        return null;
                      }
                      return (
                        <tr>
                          <td scope="row">
                            <div className="d-flex align-items-center gap-2">
                              <span className="avatar flex-shrink-0 avatar-48 img-circle bg-dark">{userItem?.firstName?.charAt(0)}{userItem?.lastName?.charAt(0)}</span>
                              <div>{userItem?.firstName} {userItem?.lastName}</div>
                            </div>

                          </td>
                          <td>{userItem?.email}</td>
                          <td>
                            {
                              user?.role === "Read Only" ? userItem?.role :
                              <select disabled={userItem?.uniqueId === user?.uniqueId} className="form-select" value={userItem?.role} onChange={handleRoleChange}>
                                {
                                  (user.role === "Standard" || user.role === "Read Only" || user.role === "Account Owner") &&
                                  <option value="Read Only">Read-only</option>
                                }
                                {
                                  user.role === "Account Owner" &&
                                  <option value="Account Owner">Account owner</option>
                                }
                                {
                                  (user.role === "Standard" || user.role === "Account Owner") &&
                                  <option value="Standard">Standard user</option>
                                }
                              </select>
                              
                            }
                          </td>
                          {
                            userItem?.uniqueId !== user?.uniqueId && (
                              user?.role == "Account Owner" || (user.role === "Standard" && userItem?.role !== "Account Owner")
                            ) ?
                            <td className="text-right"><button className="btn btn-danger" onClick={handleDelete}>Delete</button></td> : <td></td>
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>

            </div>

          </div>
        </section>
      }

      {
        showAddNewUser &&
        <>
          {/* This html for add user page */}
          <AddUsers
            setShowAddNewUser={setShowAddNewUser}
            refetchList={refetchList}
          />
        </>
      }
    </>
  )
}

const AddUsers = ({
  setShowAddNewUser,
  refetchList
}: any) => {
  const { register, handleSubmit, watch, formState: { errors }, setError, setValue, getValues } = useForm();
  const phoneNumberField =  {...register('phoneNumber', {
    required: true,
    valueAsNumber: true,
    //@ts-ignore
    pattern:{
      value: /^(0|[1-9]\d*)(\.\d+)?$/,
      message: 'Please enter valid phone number'
    },
  })}
  const user = useAppSelector((state) => state.user.user);
  const onSubmit = (values: any) => {
    addAccountUser({
      "type": "add",
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "phone": values.phoneNumber,
      "role": values.role,
      "sendDetail": values.sendDetail
    }, user?.uniqueId).then((success) => {
      console.log(success);
      if (success?.status?.error === false) {
        toast.success(success?.status?.msg);
        setShowAddNewUser(false);
        refetchList();
      }
    }).catch((error) => {
      toast.success(error?.status?.msg);
    });
  }
  return (
    <section className="profile-details-sec px-30 pt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#" onClick={() => {
              setShowAddNewUser(false);
            }}>Users</a></li>
            <li className="breadcrumb-item active" aria-current="page">Add new user</li>
          </ol>
        </nav>

        <div className="container">


          <div className="row">

            <div className="d-flex mt-3 mb-4 pb-3 border-bottom px-0 align-items-center justify-content-between">
              <h6 className="fs-5 m-0">Add new user</h6>

            </div>

          </div>


          <div className="row">

            <div className="add-user-form px-0">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="firstName">First name</label>
                  <input type="text" className="form-control" id="firstName"
                    {...register('firstName', {
                      required: true,
                    })}
                  />
                  {
                    errors?.firstName?.type === 'required' &&
                    <p className="text-danger">
                      Valid first name is required.
                    </p>
                  }
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Last name</label>
                  <input type="text" className="form-control" id="lastName"
                    {...register('lastName', {
                      required: true,
                    })}
                  />
                  {
                    errors?.lastName?.type === 'required' &&
                    <p className="text-danger">
                      Valid last name is required.
                    </p>
                  }
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="email">Email address</label>
                  <input type="email" className="form-control" id="email" placeholder="you@example.com"
                    {...register('email', {
                      required: true,
                      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })}
                  />
                  {
                    errors?.email?.type === 'required' &&
                    <p className="text-danger">
                      Email is required.
                    </p>
                  }
                  {
                    errors?.email?.type === 'pattern' &&
                    <p className="text-danger">
                      Email is invalid.
                    </p>
                  }
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationDefaultUsername">Phone number</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="phoneprefix">+1</span>
                    </div>
                    <input type="text" className="form-control" id="validationDefaultUsername" placeholder="xxx xxx xxxx"
                      {...phoneNumberField}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^\d.-]+/g, '');
                        phoneNumberField.onChange(e);
                      }}
                    />
                  </div>
                  {
                      (errors && errors.phoneNumber && errors.phoneNumber.type === "validate") &&
                      <div className="text-danger">Allowed only digits.</div>
                    }
                    {
                      (errors && errors.phoneNumber && errors.phoneNumber.type === "pattern") &&
                      //@ts-ignore
                      <div className="text-danger">{errors?.phoneNumber?.message}</div>
                    }
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">

                  <label htmlFor="role">Role</label>
                  <select className="form-select" id="role" {...register('role', {
                    required: true,
                  })}>
                    <option value="">--Select Role--</option>
                    {
                      (user.role === "Standard" || user.role === "Read Only" || user.role === "Account Owner") &&
                      <option value="Read Only">Read-only</option>
                    }
                    {
                      user.role === "Account Owner" &&
                      <option value="Account Owner">Account owner</option>
                    }
                    {
                      (user.role === "Standard" || user.role === "Account Owner") &&
                      <option value="Standard">Standard user</option>
                    }
                  </select>

                </div>

              </div>

              <div className="form-check mb-4 mt-3">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" {...register('sendDetail', {
                  required: true,
                })} />
                <label className="form-check-label" htmlFor="exampleCheck1">Send account details to this users.</label>
              </div>

              <hr />

              <div className="buttons">
                <button className="btn btn-primary" type="submit">Create user</button>
              </div>

            </div>

          </div>

        </div>
      </form>
    </section>
  )
}