import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { clincPermissionToUser, getClinicPermissionListByClinic, removePermissionToUser } from "../../services/configurations";
import { useAppSelector } from "../../store/hook";
import { NavLink, useNavigate } from "react-router-dom";

export const UserAssign = () => {
  const user = useAppSelector((state) => state.user.user);
  const clinics = useAppSelector((state) => state.user.user.clinics);
  const [selectedClinic, setSelectedClinic] = useState(sessionStorage.getItem('selectedClinic') && sessionStorage.getItem('selectedClinic')!== '' ? sessionStorage.getItem('selectedClinic') : clinics?.[0]?.uniqueId);
  const [selectedConfigs, setSelectedConfigs] = useState<any>({});
  const navigate = useNavigate();
  if (!selectedClinic && clinics?.[0]?.uniqueId) {
    setSelectedClinic(clinics?.[0]?.uniqueId);
  }
  useEffect(() => {
    if (selectedClinic !== undefined) {
      _getConfigList();
    }
  }, [selectedClinic]);
  const _getConfigList = () => {
    getClinicPermissionListByClinic(selectedClinic).then((success) => {
      setSelectedConfigs(success);
    }).catch((error) => {
      console.log(error);
    })
  }
  

  if (clinics?.length === 0) {
    return (
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <p>You do not have any clinics assigned, please contact your admin</p>
        </div>
      </section>
    )
  }

  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex flex-row">
                <div className="nav-title-txt">Configuration</div>
              </div>
              <ul className="nav nav-tabs">
                {
                  user?.role !== "Read Only" &&
                  <li className="nav-item"><NavLink className="nav-link" to="/api-connection">Api integration</NavLink></li>
                }
                <li className="nav-item"><NavLink className="nav-link" to="/configure-widget">Configure Widgets</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/user-assign">Users</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/goal-input">Goals and Inputs</NavLink></li>
              </ul>
            </div>

            <div className="d-flex gap-3 align-items-center">

              <select className="form-select" aria-label="Default select example" value={selectedClinic} onChange={(e) => {
                setSelectedClinic(e.target.value);
                sessionStorage.setItem('selectedClinic', e.target.value);
              }}>
                {
                  clinics?.map((item: any) => {
                    return (
                      <option value={item.uniqueId}>{item.name}</option>
                    )
                  })
                }
              </select>
              {
                user?.role !== "Read Only" &&
                <div>
                  <button onClick={() => {
                    navigate('/api-connection')
                  }}><i className="bi bi-gear"></i></button>
                </div>
              }
            </div>
          </div>
        </div>
      </section>


      <section className="configration-details-sec px-30 pt-5">
        <div className="container">

          <div className="row mb-5">

            <div className="d-flex mb-3 px-0 align-items-center justify-content-between">
              <h6 className="fs-6 m-0">Users have access</h6>
            </div>
            {
              selectedConfigs?.assignedUsers?.map((item: any) => {
                const handleRemove = () => {
                  removePermissionToUser(selectedClinic, item.uniqueid).then((success) => {
                    if (success?.status?.error === false) {
                      toast.success(success?.status?.msg);
                      _getConfigList();
                    } else {
                      toast.error(success?.status?.msg);
                    }
                  }).catch((error) => {
                    toast.error("Failed to update");
                  })
                }
                if (user?.role === "Read Only" && !(item?.role === "Account Owner" || item?.uniqueid === user?.uniqueId)) {
                  return null;
                }
                return (
                  <div className="card p-3 px-4 mb-3">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="d-flex align-items-center gap-2">
                          <span className="avatar flex-shrink-0 avatar-48 img-circle bg-dark">{item?.fname?.charAt(0)}{item?.lname?.charAt(0)}</span>
                          <div>{item?.fname} {item?.lname}</div>
                        </div>
                      </div>
                      <div className="col-4">
                        {item?.email}
                      </div>
                      <div className="col-2">
                        {item?.role}
                      </div>
                      <div className="col-2 text-end">
                        {
                          item?.uniqueid !== user?.uniqueId && (user?.role == "Account Owner" || 
                          (user?.role === "Standard" && item?.role !== "Account Owner")) &&
                          <button disabled={user.role === "Read Only"} className="btn btn-danger" onClick={handleRemove}>Remove</button>
                        }
                      </div>
                    </div>

                  </div>
                )
              })
            }
          </div>

          {
            user?.role !== "Read Only" &&
            <div className="row">

              <div className="d-flex mb-3 px-0 align-items-center justify-content-between">
                <h6 className="fs-6 m-0">Assign user</h6>
              </div>

              {
                selectedConfigs?.remainingUsers?.map((item: any) => {
                  const handleAssign = () => {
                    clincPermissionToUser(selectedClinic, item.uniqueid).then((success) => {
                      if (success?.status?.error === false) {
                        toast.success(success?.status?.msg);
                        _getConfigList();
                      } else {
                        toast.error(success?.status?.msg);
                      }
                    }).catch((error) => {
                      toast.error("Failed to update");
                    })
                  }
                  return (
                    <div className="card p-3 px-4 mb-3">
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="d-flex align-items-center gap-2">
                            <span className="avatar flex-shrink-0 avatar-48 img-circle bg-dark">{item?.fname?.charAt(0)}{item?.lname?.charAt(0)}</span>
                            <div>{item?.fname} {item?.lname}</div>
                          </div>
                        </div>
                        <div className="col-4">
                          {item?.email}
                        </div>
                        <div className="col-2">
                          {item?.role}
                        </div>
                        <div className="col-2 text-end ">
                          <button disabled={user.role === "Read Only"} className="btn btn-primary" onClick={handleAssign}>Assign</button>
                        </div>
                      </div>

                    </div>
                  )
                })
              }
            </div>
          }

        </div>
      </section>
    </>
  )
}